import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prevRoute: '',
  currentRoute: '',
};

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setRoute: (state, action) => {
      return {
        prevRoute: state.currentRoute,
        currentRoute: action.payload,
      };
    },
  },
});

export const { setRoute } = routeSlice.actions;

export default routeSlice.reducer;
