import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

export const CustomSwitch = styled(Switch)(() => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      left: 12,
    },
    '&:after': {
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const RHFSwitch = ({
  name,
  labelPlacement = 'end',
  disabled = false,
  onChange,
  ...other
}) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <CustomSwitch
              disabled={disabled}
              {...field}
              checked={field.value ? field.value : false}
              {...(onChange && { onChange })}
            />
          )}
        />
      }
      disabled={disabled}
      labelPlacement={labelPlacement}
      {...other}
    />
  );
};

RHFSwitch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  labelPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RHFSwitch;
