import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../components/RouteLazyLoading';
import { ADMIN } from '../../routes/paths';
import PartnerForm from './partners/PartnerForm';
import Partners from './partners/Partners';

const Links = Loadable(lazy(() => import('./links/Links')));
const ClientRoute = Loadable(lazy(() => import('./clients/clientRoute')));
const Dashboard = Loadable(lazy(() => import('./dashboard/AdminDashboard')));
const Companies = Loadable(lazy(() => import('./companies/companies')));
const CompaniesInformation = Loadable(
  lazy(() => import('./companies/CompaniesInformation'))
);

const ReportsEcwid = Loadable(lazy(() => import('./reports/EcwidPage')));
const AdminDashboard = Loadable(lazy(() => import('./admin/index')));
const PlanForm = Loadable(lazy(() => import('./admin/plans/PlanForm')));
const PerkServicesRouter = Loadable(
  lazy(() => import('./perks/PerkServicesRouter'))
);
export default function Router() {
  return useRoutes([
    {
      index: true,
      element: <Dashboard />,
    },
    {
      index: ADMIN.root,
      element: <Dashboard />,
    },
    {
      path: ADMIN.COMPANIES.path,
      element: <Companies />,
    },
    {
      path: ADMIN.COMPANY_INFO.path,
      element: <CompaniesInformation />,
    },
    {
      path: ADMIN.DASHBOARD.path,
      element: <AdminDashboard />,
    },
    {
      path: ADMIN.ADD_PLAN.path,
      element: <PlanForm />,
    },
    {
      path: ADMIN.EDIT_PLAN.path,
      element: <PlanForm />,
    },
    {
      path: ADMIN.PARTNERS.path,
      element: <Partners />,
    },
    {
      path: ADMIN.PARTNERS.ADD.path,
      element: <PartnerForm />,
    },
    {
      path: ADMIN.PARTNERS.EDIT.path,
      element: <PartnerForm />,
    },
    {
      path: ADMIN.REPORTS.ECWID.path,
      element: <ReportsEcwid />,
    },
    {
      path: ADMIN.USERS.path + '/*',
      element: <ClientRoute />,
    },
    {
      path: ADMIN.SUPPORT.SUPPORT_FEEDBACK.path,
      element: <Links />,
    },
    {
      path: ADMIN.PERKS.path + '/*',
      element: <PerkServicesRouter />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
