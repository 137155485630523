import { fShortenNumber } from './formatNumber';

export const getCurrency = ({
  currency,
  amount,
  isStripeAmount = true,
  status,
  withCurrencyCode = true,
}) => {
  const amountValue = fShortenNumber(
    isStripeAmount ? amount / 100 : amount,
    true
  );

  const formattedValue = amountValue?.split(',').join('');

  let CAD = withCurrencyCode ? 'CAD' : '';
  let EUR = withCurrencyCode ? 'EUR' : '';
  let USD = withCurrencyCode ? 'USD' : '';
  switch (currency?.toLowerCase()) {
    case 'cad':
      return amount < 0 || (status && status !== 'paid')
        ? `($${Math.abs(formattedValue).toFixed(2)}) ${CAD}`
        : `$${formattedValue} ${CAD}`;
    case 'eur':
      return amount < 0 || (status && status !== 'paid')
        ? `(€${Math.abs(formattedValue).toFixed(2)}) ${EUR}`
        : `€${formattedValue} ${EUR}`;
    case 'none':
      return amount < 0 || (status && status !== 'paid')
        ? `($${Math.abs(formattedValue).toFixed(2)})`
        : `$${formattedValue}`;
    case 'usd':
    default:
      return amount < 0 || (status && status !== 'paid')
        ? `($${Math.abs(formattedValue).toFixed(2)}) ${USD}`
        : `$${formattedValue} ${USD}`;
  }
};
