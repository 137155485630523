import { useEffect, useMemo, useState } from 'react';

import { Divider, Typography, Stack, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const useDisableStyles = makeStyles({
  disabled: {
    pointerEvents: 'none !important',
    cursor: 'not-allowed !important',
    opacity: '0.5 !important',
  },
});
const PaymentRequestForm = ({
  payInvoice,
  handleResponse,
  isDisabled,
  isCardCheckout,
  formData,
  isLoading,
}) => {
  // React hooks
  const { t } = useTranslation(['common']);

  // Local state
  const [checkingAvailability, setCheckingAvailability] = useState(false);

  const useOptions = (paymentRequest) => {
    const options = useMemo(
      () => ({
        paymentRequest,
      }),
      [paymentRequest]
    );

    return options;
  };
  const classes = useDisableStyles();
  const usePaymentRequest = ({ options, onPaymentMethod, checkIsLoading }) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [canMakePayment, setCanMakePayment] = useState(false);
    const [checkingAvailability, setCheckingAvailability] = useState(false);

    useEffect(() => {
      if (stripe && !paymentRequest) {
        options.country = 'US';
        const pr = stripe.paymentRequest(options);
        setPaymentRequest(pr);
      }
    }, [stripe, options, paymentRequest]);

    useEffect(() => {
      checkIsLoading(checkingAvailability);
    }, [checkingAvailability, checkIsLoading]);

    useEffect(() => {
      if (paymentRequest) {
        paymentRequest.update({
          total: options?.total,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payInvoice]);

    useEffect(() => {
      let subscribed = true;
      setCheckingAvailability(true);
      if (paymentRequest) {
        paymentRequest
          .canMakePayment()
          .then((res) => {
            setCheckingAvailability(false);
            if (res && subscribed) {
              setCanMakePayment(true);
            }
          })
          .catch(() => {
            setCheckingAvailability(false);
          });
      }

      return () => {
        subscribed = false;
      };
    }, [paymentRequest]);

    useEffect(() => {
      if (paymentRequest) {
        paymentRequest.on('paymentmethod', onPaymentMethod);
      }
      return () => {
        if (paymentRequest) {
          paymentRequest.off('paymentmethod', onPaymentMethod);
        }
      };
    }, [paymentRequest, onPaymentMethod]);

    return canMakePayment && paymentRequest;
  };

  const availableCountryCurrencies = useMemo(() => {
    const obj = {};
    const defCountries = ['US', 'CA'];
    const defCurrencies = ['usd', 'cad'];
    const country =
      payInvoice?.invoice?.account_country || payInvoice?.account_country;
    let currency = payInvoice?.invoice?.currency || payInvoice?.currency;

    if (defCountries.includes(country)) {
      obj.country = country;
    } else {
      obj.country = 'CA';
    }
    if (defCurrencies.includes(currency)) {
      obj.currency = currency?.toLowerCase();
    } else {
      obj.currency = 'cad';
    }
    return obj;
  }, [
    payInvoice?.account_country,
    payInvoice?.currency,
    payInvoice?.invoice?.account_country,
    payInvoice?.invoice?.currency,
  ]);

  const paymentRequest = usePaymentRequest({
    options: {
      ...availableCountryCurrencies,
      total: {
        label: 'total',
        amount: payInvoice?.invoice?.amount_due || payInvoice?.amount_due || 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    },
    onPaymentMethod: (res) => {
      const { complete } = res; // return form google/apple pay
      if (handleResponse) {
        handleResponse({ result: res, values: formData }, complete('success'));
      }
    },
    checkIsLoading: (value) => {
      setCheckingAvailability(false);
    },
  });

  const options = useOptions(paymentRequest);

  if (checkingAvailability && !isDisabled) {
    return <Skeleton animation="wave" sx={{ p: 3, w: 50 }} />;
  }

  if (!paymentRequest) {
    return null;
  }
  return (
    <>
      <Stack sx={{ width: { xs: '100%', sm: '50%' }, mx: 'auto' }}>
        <PaymentRequestButtonElement
          options={options}
          className={
            isDisabled
              ? classes.disabled
              : !isLoading
              ? classes.buttonStyle
              : null
          }
        />
      </Stack>
      <Divider orientation="horizontal">
        <Stack direction={'row'} justifyContent={'center'}>
          {!isCardCheckout ? (
            <>
              <Typography
                sx={{ my: 3 }}
                color={(theme) => theme.palette.grey[600]}
                variant="subtitle2"
                textTransform={'uppercase'}
              >
                {t('or')}
              </Typography>
            </>
          ) : (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography>Or pay with card</Typography>
            </>
          )}
        </Stack>
      </Divider>
    </>
  );
};

PaymentRequestForm.propTypes = {
  formData: PropTypes.any,
  handleResponse: PropTypes.func,
  isCardCheckout: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.any,
  options: PropTypes.any,
  payInvoice: PropTypes.shape({
    account_country: PropTypes.string,
    amount_due: PropTypes.number,
    currency: PropTypes.string,
    invoice: PropTypes.shape({
      account_country: PropTypes.any,
      amount_due: PropTypes.number,
      currency: PropTypes.any,
    }),
  }),
};

export default PaymentRequestForm;
