import { store } from '../../redux/store';
import { getCurrencySign } from '../../utils/getCurrencySign';
export function updateCacheData({ state, method, dispatch, extendedApi }, cb) {
  let query;
  for (const key in state.api.queries) {
    if (state.api.queries[key].status === 'fulfilled') {
      query = state.api.queries[key].originalArgs;
      dispatch(extendedApi.util.updateQueryData(method, query, cb));
    }
  }
}

let timeout;
export const debounce = (callback, delay = 300) => {
  clearTimeout(timeout);
  timeout = setTimeout(callback, delay);
};

export const setPerPage = (queryParams) => {
  if ((queryParams?.page && queryParams?.page === 0) || queryParams?.page === 1)
    delete queryParams?.page;
  if (queryParams?.perPage && queryParams?.perPage === 20)
    delete queryParams?.perPage;
  if (
    (queryParams?.perPage <= 0 && queryParams?.page <= 0) ||
    queryParams?.perPage <= 0 ||
    queryParams?.page <= 0
  ) {
    delete queryParams?.perPage;
    delete queryParams?.page;
  }

  return queryParams;
};

export const truncateName = (name, maxLength = 12) => {
  return name?.length > maxLength ? name.slice(0, maxLength) + '...' : name;
};

export const checkPageAvailability = (total, rowsPerPage, queryParams) => {
  if (Math.ceil(total / rowsPerPage) < queryParams.page) {
    queryParams.page = 1;
  }
  return queryParams;
};

export const getTaxesObj = (subTotal, taxesDetails) => {
  const taxes = JSON.parse(JSON.stringify(taxesDetails));
  const inclusiveTaxes = taxes.filter((s) => s.inclusive);
  const totalInclusiveTax = inclusiveTaxes.reduce(
    (percentage, obj) => percentage + Number(obj.percentage),
    0
  );

  const totalExcludingTax = Number(
    subTotal / (1 + totalInclusiveTax / 100)
  ).toFixed(2);

  const calculateTaxesList = taxes.map((taxAmount) => {
    const tax = JSON.parse(JSON.stringify(taxAmount));

    tax.percentCalculated = Number(
      (Number(totalExcludingTax) * Number(tax.percentage)) / 100
    ).toFixed(2);
    return tax;
  });

  const exclusiveTaxes = calculateTaxesList.filter((s) => !s.inclusive);

  const totalExclusiveTax = exclusiveTaxes?.reduce((total, tax) => {
    return Number(total) + Number(tax?.percentCalculated);
  }, 0);

  return {
    taxesArray: calculateTaxesList,
    totalExcludingTax,
    totalExclusiveTax,
  };
};

export const getFeesObj = (chargeAmount, serviceFeeDetails) => {
  const transactionDetails =
    store.getState()?.state?.paymentsGateway.allGatewayCheckoutTransactions
      .checkoutTransactions;
  const feesArray = serviceFeeDetails?.length
    ? JSON.parse(JSON.stringify(serviceFeeDetails))
    : [];

  feesArray?.map((d) => {
    d.title = d?.title
      ? d?.title
      : d?.serviceName
      ? `${d.serviceName} (${d.percentage}${
          d?.isRateInAmount
            ? getCurrencySign(transactionDetails?.currency)
            : '%'
        })`
      : d?.description;
    d._id = `${d.id}`;
    return d;
  });
  feesArray?.map((t) => {
    t.percentCalculated = t.isRateInAmount
      ? t.percentage
      : ((t.percentage / 100) * chargeAmount)?.toFixed(2);
    return t;
  });
  let totalFeeApplicable = 0;
  if (feesArray?.length) {
    feesArray?.filter((d) => typeof d !== 'undefined' && d);
    const reducedPercentage = feesArray.map((o) => {
      const inclusive = o?.inclusive || o?.metadata?.inclusive;
      return (
        (inclusive === 'false' || !inclusive) && Number(o.percentCalculated)
      );
    });
    totalFeeApplicable = reducedPercentage.reduce((a, b) => a + b, 0);
  }
  return { feesArray, totalFeeApplicable };
};

const getColorFromIndex = (index) => {
  const colors = [
    '#2D99FF',
    '#83CFFF',
    '#36B37E',
    '#FFAB00',
    '#FF6C40',
    '#FF8F6D',
    '#FFE700',
    '#FFEF5A',
  ];
  return colors[index % colors?.length];
};

export const generateColors = (count) => {
  return Array.from({ length: count }, (_, index) => getColorFromIndex(index));
};

const hexToRgb = (hex) => {
  let r = 0,
    g = 0,
    b = 0;

  if (hex?.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex?.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return [r, g, b];
};

const rgbToHex = (r, g, b) => {
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b)
      ?.toString(16)
      ?.slice(1)
      ?.toUpperCase()
  );
};

const darkenColor = (hex, percent) => {
  const [r, g, b] = hexToRgb(hex);

  const factor = 1 - percent / 100;
  const newR = Math?.max(0, Math.floor(r * factor));
  const newG = Math?.max(0, Math.floor(g * factor));
  const newB = Math?.max(0, Math.floor(b * factor));

  return rgbToHex(newR, newG, newB);
};

export const darkenColorsArray = (colors, percent) => {
  return colors.map((color) => darkenColor(color, percent));
};
