import { api } from '../../apiInterceptor';
import apiRoutes from '../../apiRoutesConstants';

api.enhanceEndpoints({
  addTagTypes: ['payouts'],
});

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStripePayout: build.query({
      query: (body) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getStripePayout.url,
        method: apiRoutes.PAYMENTS.endpoints.getStripePayout.method,
        params: body,
      }),
      providesTags: ['payouts'],
      transformResponse: (res) => {
        return res;
      },
    }),
    getStripeSinglePayout: build.query({
      query: (body) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getStripeSinglePayout.url,
        method: apiRoutes.PAYMENTS.endpoints.getStripeSinglePayout.method,
        params: body,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
    getStripePayoutTransactions: build.query({
      query: (body) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getStripePayoutTransactions.url,
        method: apiRoutes.PAYMENTS.endpoints.getStripePayoutTransactions.method,
        params: body,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetStripePayoutQuery,
  useGetStripeSinglePayoutQuery,
  useGetStripePayoutTransactionsQuery,
} = extendedApi;
