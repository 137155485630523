// Guards
import { lazy, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import { getCurrentPageName } from '../components/getCurrentPathName';
import { Loadable } from '../components/RouteLazyLoading';
import {
  _MEMBER_ADMIN_ROLE,
  _MEMBER_ROLES,
  _SYS_ADMIN_ROLES,
} from '../constants/roles';
import AdminRouter from '../pages/admin/AdminRoutes';
import PaymentRouter from '../pages/client/payments/paymentRoutes';
import RailzConnect from '../pages/client/payments/railz-connect/RailzConnect';
import ShippingRouter from '../pages/client/shipping/shippingRoutes';
import WebRouter from '../pages/client/web/webRoutes';
import TeamRouter from '../pages/paid/teams/teamRoutes';
import { setRoute } from '../redux/routeSlice';
import AuthGuard from './guards/AuthGuard';
import RoleBasedGuard from './guards/RoleBasedGuard';
import UnAuthGuard from './guards/UnAuthGuard';
// Route path
import {
  ADMIN,
  CLIENT,
  ONBOARDING,
  PAID_USER,
  PAYMENTS,
  ROOTS_PATH,
  SHIPPING,
  SHIPTIME_USER_AUTHENTICATING,
  UNAUTHORIZE_PATH,
  WEB,
} from './paths';

// Authentication
const MainLayout = Loadable(lazy(() => import('../layouts/main/index')));
const WithoutCredentials = Loadable(
  lazy(() => import('../layouts/without-credentials/Index'))
);
const LogoOnlyLayout = Loadable(
  lazy(() => import('../layouts/LogoOnlyLayout'))
);
const AuthLayout = Loadable(lazy(() => import('../layouts/auth/AuthLayout')));

const Login = Loadable(lazy(() => import('../pages/auth/SignInPage')));
const ShiptimeUserAuth = Loadable(
  lazy(() => import('../pages/auth/ShiptimeUserAuth'))
);
const Register = Loadable(lazy(() => import('../pages/auth/SignUpPage')));
const VerifyAccount = Loadable(
  lazy(() => import('../pages/auth/VerifyAccount'))
);
const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/ForgotPasswordPage'))
);
const SetAndResetPassword = Loadable(
  lazy(() => import('../pages/auth/SetAndResetPasswordPage'))
);
const NotFound = Loadable(lazy(() => import('../pages/errors/Page404')));
const SingUpSuccessPage = Loadable(
  lazy(() => import('../pages/success/SignUpSuccessPage'))
);

// Client route
const Services = Loadable(
  lazy(() => import('../pages/client/dashboard/Services'))
);
const ManagePlan = Loadable(
  lazy(() => import('../pages/paid/onboarding/ManagePlan'))
);
const MyProfile = Loadable(lazy(() => import('../pages/client/profile/index')));
const SubscriptionAndBilling = Loadable(
  lazy(() => import('../pages/client/subscription-and-billing/index'))
);
const RedirectingPage = Loadable(
  lazy(() => import('../pages/auth/RedirectingPage'))
);

export default function Router() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentPageName(location?.pathname);
    dispatch(setRoute(location?.pathname + window.location.search));
  }, [dispatch, location?.pathname]);

  // TODO: need to remove after maximum dependency issue fixed
  return useRoutes([
    {
      path: ROOTS_PATH,
      element: (
        <UnAuthGuard>
          <AuthLayout />
        </UnAuthGuard>
      ),
      children: [
        {
          path: UNAUTHORIZE_PATH.SIGN_IN.path,
          element: (
            <WithoutCredentials>
              <Login />
            </WithoutCredentials>
          ),
        },
        {
          path: SHIPTIME_USER_AUTHENTICATING.path,
          element: <ShiptimeUserAuth />,
        },
        {
          path: UNAUTHORIZE_PATH.SIGN_UP.path,
          element: (
            <WithoutCredentials>
              <Register />
            </WithoutCredentials>
          ),
        },
        {
          path: UNAUTHORIZE_PATH.FORGOT_PASSWORD.path,
          element: (
            <LogoOnlyLayout>
              <ForgotPassword />
            </LogoOnlyLayout>
          ),
        },
        {
          path: UNAUTHORIZE_PATH.VERIFY_ACCOUNT.path,
          element: (
            <LogoOnlyLayout>
              <VerifyAccount />
            </LogoOnlyLayout>
          ),
        },
        {
          path: UNAUTHORIZE_PATH.VERIFY_CODE.path,
          element: (
            <LogoOnlyLayout>
              <VerifyAccount />
            </LogoOnlyLayout>
          ),
        },
        {
          path: UNAUTHORIZE_PATH.RESET_PASSWORD.path,
          element: (
            <LogoOnlyLayout>
              <SetAndResetPassword />
            </LogoOnlyLayout>
          ),
        },
        {
          path: UNAUTHORIZE_PATH.SET_PASSWORD.path,
          element: (
            <LogoOnlyLayout>
              <SetAndResetPassword />
            </LogoOnlyLayout>
          ),
        },
        {
          path: '',
          element: <Navigate to={UNAUTHORIZE_PATH.SIGN_IN.path} replace />,
        },
      ],
    },
    {
      path: ROOTS_PATH,
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ONBOARDING.MANAGE_PLAN.path,
          element: (
            <RoleBasedGuard accessibleRoles={_MEMBER_ADMIN_ROLE}>
              <ManagePlan />
            </RoleBasedGuard>
          ),
        },
        {
          path: PAID_USER.path,
          element: (
            <RoleBasedGuard accessibleRoles={_MEMBER_ROLES}>
              <Services />
            </RoleBasedGuard>
          ),
        },
        {
          path: CLIENT.MY_PROFILE.path,
          element: (
            <RoleBasedGuard
              accessibleRoles={[..._MEMBER_ROLES, ..._SYS_ADMIN_ROLES]}
            >
              <MyProfile />
            </RoleBasedGuard>
          ),
        },
        {
          path: CLIENT.SUBSCRIPTION_BILLING.path,
          element: (
            <RoleBasedGuard accessibleRoles={_MEMBER_ROLES}>
              <SubscriptionAndBilling />,
            </RoleBasedGuard>
          ),
        },
        {
          path: CLIENT.TEAM_USERS.path + '/*',
          element: (
            <RoleBasedGuard
              accessibleRoles={[..._MEMBER_ROLES, ..._SYS_ADMIN_ROLES]}
            >
              <TeamRouter />
            </RoleBasedGuard>
          ),
        },
        {
          path: PAYMENTS.root + '/*',
          element: (
            <RoleBasedGuard type="payments" accessibleRoles={_MEMBER_ROLES}>
              <PaymentRouter />
            </RoleBasedGuard>
          ),
        },
        {
          path: WEB.root + '/*',
          element: (
            <RoleBasedGuard type="web" accessibleRoles={_MEMBER_ROLES}>
              <WebRouter />
            </RoleBasedGuard>
          ),
        },
        {
          path: ADMIN.root + '/*',
          element: (
            <RoleBasedGuard type="admin" accessibleRoles={_SYS_ADMIN_ROLES}>
              <AdminRouter />
            </RoleBasedGuard>
          ),
        },
        {
          path: SHIPPING.dashboard.path + '/*',
          element: (
            <RoleBasedGuard accessibleRoles={_MEMBER_ROLES}>
              <ShippingRouter />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: '/redirecting',
      element: <RedirectingPage />,
    },

    {
      path: `${PAYMENTS.root}/accounting/connect`,
      element: <RailzConnect />,
    },
    {
      path: '*',
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: 'signup-success-page',
      element: (
        <LogoOnlyLayout>
          <SingUpSuccessPage />
        </LogoOnlyLayout>
      ),
    },
  ]);
}
