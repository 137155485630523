import { memo, useEffect, useState } from 'react';

import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RHFButton from '../../../components/button/RHFButton';
import customDayjs from '../../../components/customDayjs';
import Iconify from '../../../components/Iconify';
import { PARTNER_LINK } from '../../../constants/ExternalLinks';
import { _SYS_EMPLOYEE, _SYS_MANAGER } from '../../../constants/roles';
import * as routes from '../../../routes/paths';

const PartnersHeader = ({ partnersList, isLoading }) => {
  // React hooks
  const navigate = useNavigate();

  // Global state
  const auth = useSelector((state) => state?.auth);

  // Local state
  const [exportData, setExportData] = useState();

  useEffect(() => {
    const data = partnersList?.partners?.map((partner) => {
      const arr = [];
      partner?.productsAvailable?.forEach((obj) => arr?.push(obj?.title));

      return {
        'Partner Name': partner?.name,
        URL: `${PARTNER_LINK}${partner?.url.toLowerCase().replace(/\s+/g, '')}`,
        'Selected Product': arr.join(','),
        Intercept: partner?.intercept ? 'ACTIVE' : 'INACTIVE',
        '# Of Clients': partner?.usersCount,
        Status: partner?.status ? 'ACTIVE' : 'INACTIVE',
        'Creation Date': partner?.created
          ? customDayjs(partner?.created).format('DD, MMM YYYY')
          : '-',
      };
    });
    setExportData(data);
  }, [partnersList?.partners]);

  return (
    <Grid
      container
      justifyContent={'space-between'}
      flexDirection="row"
      alignItems={'center'}
    >
      <Grid item flex={1}>
        <Grid container>
          <Typography variant="h5">Partners</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems={'center'}
          gap={1.5}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={150} height={40} />
          ) : Number(partnersList?.total) > 0 ? (
            <RHFButton
              variant="contained"
              sx={{
                cursor: 'pointer',
              }}
              title={
                <CSVLink
                  data={exportData ? exportData : ''}
                  target="_blank"
                  rel="noopener noreferrer"
                  filename="partners-list.csv"
                  style={{
                    color: 'white',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Iconify
                    sx={{
                      height: '20px',
                      width: '20px',
                      mr: 1,
                    }}
                  />
                  Export
                </CSVLink>
              }
            />
          ) : null}
          {auth && auth.role !== _SYS_EMPLOYEE && auth.role !== _SYS_MANAGER ? (
            isLoading ? (
              <Skeleton variant="rectangular" width={150} height={40} />
            ) : (
              <RHFButton
                variant="contained"
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => navigate(routes.ADMIN.PARTNERS.ADD.fullPath)}
                title=" Add Partner"
              />
            )
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  );
};

PartnersHeader.propTypes = {
  isLoading: PropTypes.bool,
  partnersList: PropTypes.object,
};

export default memo(PartnersHeader);
