import { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Card, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import errorImage from '../../../assets/error-image.svg';
import noImage from '../../../assets/no-image.svg';
import CopyToClipBoard from '../../../components/CopyToClipBoard';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
import CustomTablePagination from '../../../components/table-wrapper/CustomTablePagination';
import TableWrapper from '../../../components/table-wrapper/TableWrapper';
import { cartTypes, rowsPerPageOptions } from '../../../constants';
import { PARTNER_LINK } from '../../../constants/ExternalLinks';
import Images from '../../../constants/images';
import { useGetPartnersQuery } from '../../../redux/partners/partnersAction';
import * as routes from '../../../routes/paths';
import Label from './../../../components/Label';
import { getStatusBadge } from './../../../utils/getStatusText';
import PartnersFilter from './PartnersFilter';
import PartnersHeader from './PartnersHeader';

const PartnersList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [imgError, setImgError] = useState({});

  const navigate = useNavigate();

  // API calls
  const filters = useMemo(() => {
    const rowFilters = {
      limit: searchParams.get('perPage') || rowsPerPageOptions[0],
      start:
        ((searchParams.get('page') > 1 ? searchParams.get('page') : 1) - 1) *
        (searchParams.get('perPage') || rowsPerPageOptions[0]),
      filter_partner_name: decodeURIComponent(
        searchParams.get('filter_partner_name')?.toLocaleLowerCase()?.trim() ||
          ''
      ),
      sort: searchParams.get('sort') || 'created',
      order: searchParams.get('order') || 'DESC',
      filter_url: '',
    };
    Object.keys(rowFilters).forEach((key) => {
      if (!rowFilters[key] && rowFilters[key] !== 0) {
        delete rowFilters[key];
      }
    });

    return rowFilters;
  }, [searchParams]);

  const { data, isLoading, isFetching } = useGetPartnersQuery(filters);

  const onImgError = useCallback(
    (e, id) => {
      const isImgIdExists = Object.keys(imgError).find((key) => key === id);
      if (!isImgIdExists || !imgError[id]) {
        imgError[id] = true;
        setImgError({ ...imgError });
        e.target.src = errorImage;
      }
    },
    [imgError]
  );

  const getIconClass = useCallback((value) => {
    if (value) {
      switch (value.toLowerCase()) {
        case 'cart':
          return 'PaidCart';
        case 'payments':
          return 'PaidPayments';
        case 'shipping':
          return 'PaidShipping';
        case 'web':
          return 'PaidWeb';
        default:
          return '';
      }
    }
  }, []);

  const handleSort = useCallback((arr) => {
    if (arr) {
      return arr.map((ele) => {
        const obj = { ...ele };
        switch (obj?.value?.toLowerCase()) {
          case 'cart':
          case 'web':
          case 'web+cart':
            obj.position = 1;
            break;
          case 'payments':
            obj.position = 2;
            break;
          case 'shipping':
            obj.position = 3;
            break;
          default:
            break;
        }
        return obj;
      });
    }
    return [];
  }, []);

  const renderProductsAvailable = useCallback(
    (row) => {
      let products = row?.productsAvailable;
      products = handleSort(products);
      const hasWebPlusCart = products?.some(
        (product) => product?.value === 'web+cart'
      );

      products = products?.filter((item) => {
        if (hasWebPlusCart)
          return item.value !== 'web' && item.value !== 'cart';
        return item.value !== 'cart';
      });

      if (products?.length) {
        return products
          .sort((a, b) => a.position - b.position)
          .map((item) => {
            const isWeb = item?.value?.toString()?.includes('web');
            return (
              item.value && (
                <Tooltip
                  arrow
                  key={item?.value}
                  title={isWeb ? 'Web + Cart' : item?.title}
                  placement="top"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {isWeb ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Image
                          src={Images['PaidWeb']?.filename}
                          id={row._id}
                          sx={{
                            width: item?.value === 'shipping' ? 40 : 32,
                            height: item?.value === 'shipping' ? 32 : 32,
                          }}
                        />
                        <AddIcon color="secondary" fontSize="small" />
                        <Image
                          src={Images['PaidCart']?.filename}
                          id={row._id}
                          sx={{
                            width: item?.value === 'shipping' ? 40 : 32,
                            height: item?.value === 'shipping' ? 32 : 32,
                          }}
                        />
                      </Stack>
                    ) : (
                      <Image
                        src={Images[`${getIconClass(item.value)}`]?.filename}
                        id={row._id}
                        sx={{
                          width: item?.value === 'shipping' ? 40 : 32,
                          height: item?.value === 'shipping' ? 32 : 32,
                        }}
                      />
                    )}
                    {item.value === 'web' &&
                    cartTypes?.includes(row?.cartType) ? (
                      <>
                        <AddIcon />
                        <Image
                          src={Images['PaidCart'].filename}
                          id={row._id}
                          sx={{
                            width: 32,
                            height: 32,
                          }}
                        />
                      </>
                    ) : null}
                  </Stack>
                </Tooltip>
              )
            );
          });
      } else {
        return '-';
      }
    },
    [getIconClass, handleSort]
  );

  useEffect(() => {
    if (['0', '1'].includes(searchParams.get('page'))) {
      searchParams.delete('page');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
      });
    }
  }, [searchParams, setSearchParams]);

  const tHeadArray = [
    {
      field: 'Partner Name',
      headerName: 'name',
      sort: true,
      render: (row) => (
        <Stack key={row?._id} gap={2} direction="row" alignItems="center">
          <Box
            onError={(e) => {
              onImgError(e, row._id);
            }}
          >
            <Image
              sx={{
                width: 40,
                height: 40,
              }}
              src={row?.partnerLogo || noImage}
            />
          </Box>
          <Typography variant="body2">{row?.name}</Typography>
        </Stack>
      ),
    },
    {
      field: 'URL',
      headerName: 'url',
      sort: true,
      render: (row) => {
        return (
          <CopyToClipBoard
            textVariant="body2"
            copyMeText={`${PARTNER_LINK}${row?.url
              .toLowerCase()
              .replace(/\s+/g, '')}`}
            fieldName={'url'}
            field={'url'}
          />
        );
      },
    },
    {
      field: 'Selected Products',
      headerName: 'selected-products',
      render: (row) => (
        <Stack direction={'row'} key={row?._id} gap={2} alignItems={'center'}>
          {renderProductsAvailable(row)}
        </Stack>
      ),
    },
    {
      field: '# Of Clients',
      headerName: '#-of-clients',
      sx: {
        justifyContent: 'center',
      },
      render: (row) => (
        <Stack key={row?._id} gap={2} alignItems={'center'}>
          {row?.usersCount ? row?.usersCount : '-'}
        </Stack>
      ),
    },
    {
      field: 'Status',
      headerName: 'status',
      sx: {
        justifyContent: 'center',
      },
      render: (row) => {
        const statusBadge = getStatusBadge(row?.status ? 'ACTIVE' : 'INACTIVE');
        return (
          <Stack
            key={row?._id}
            gap={2}
            direction={'row'}
            justifyContent={'center'}
          >
            <Label
              variant={'ghost'}
              color={statusBadge.bgColor}
              sx={{
                backgroundColor: statusBadge.bgColor,
              }}
            >
              <Typography variant="overline">
                {statusBadge?.statusText}
              </Typography>
            </Label>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack gap={3}>
      <PartnersHeader partnersList={data} isLoading={isLoading} />
      <Card>
        {isLoading ? (
          <Stack gap={3} p={3} flexDirection={'row'}>
            <Skeleton variant="rectangular" width={'50%'} height={55} />
          </Stack>
        ) : (
          <PartnersFilter
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )}
        <Stack flexGrow={1}>
          <Scrollbar>
            <TableWrapper
              onRowClick={(row) =>
                navigate(`${routes.ADMIN.PARTNERS.EDIT.fullPath}/${row._id}`)
              }
              isLoading={isLoading || isFetching}
              columns={tHeadArray}
              rowsData={data?.partners?.slice(0, Number(filters?.limit))}
              rowKey={'_id'}
              orderBy={searchParams.get('sort') || 'created'}
              order={searchParams.get('order') || 'desc'}
              onSort={(field) =>
                setSearchParams({
                  ...Object.fromEntries([...searchParams]),
                  sort: field,
                  order:
                    searchParams.get('sort') === field &&
                    searchParams.get('order') === 'asc'
                      ? 'desc'
                      : 'asc',
                })
              }
            />
          </Scrollbar>
          <CustomTablePagination
            isLoading={isLoading || isFetching}
            count={Number(data?.total) || 0}
            rowsPerPage={Number(filters?.limit)}
            page={
              searchParams.get('page') > 0 ? searchParams.get('page') - 1 : 0
            }
            onRowsPerPageChange={(event) => {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                page: 1,
                perPage: event.target.value,
              });
            }}
            onPageChange={(event, newPage) => {
              setSearchParams({
                ...Object.fromEntries([...searchParams]),
                perPage: searchParams.get('perPage') || rowsPerPageOptions[0],
                page: newPage + 1,
              });
            }}
          />
        </Stack>
      </Card>
    </Stack>
  );
};

export default PartnersList;
