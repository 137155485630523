export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}
export function removeEmptyData(obj, parentObj) {
  for (let x in obj) {
    if (
      !obj[x] ||
      (Array.isArray(obj[x]) && obj[x]?.length === 0) ||
      isEmpty(obj[x])
    ) {
      delete obj[x];
      removeEmptyData(parentObj, {});
    }
    if (typeof obj[x] === 'object') {
      removeEmptyData(obj[x], obj);
    }
  }
  return obj;
}
