import { memo } from 'react';

import { TableRow, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmptyContent from '../EmptyContent';

const NoTableData = ({ noDataDescription, noDataTitle }) => {
  // React hook
  const { t } = useTranslation(['common']);

  return (
    <TableRow>
      <TableCell colSpan={9}>
        <EmptyContent
          title={noDataTitle || t('noData')}
          sx={{
            '& span.MuiBox-root': { height: 160 },
          }}
          description={noDataDescription}
        />
      </TableCell>
    </TableRow>
  );
};

NoTableData.propTypes = {
  noDataDescription: PropTypes.any,
  noDataTitle: PropTypes.any,
};

export default memo(NoTableData);
