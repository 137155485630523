import { createContext, useCallback, useEffect, useMemo } from 'react';

import bcrypt from 'bcryptjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import customDayjs from '../components/customDayjs';
import { connectSocket } from '../components/socket/Socket';
import { _MEMBER_ROLES, _SYS_ADMIN_ROLES } from '../constants/roles';
import {
  useForgotPasswordMutation,
  useGetIpInfoQuery,
  useGetPrimaryServiceKeyMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useShiptimeAuthenticateMutation,
  useSignInMutation,
  useSignUpMutation,
  useVerifyMutation,
} from '../redux/auth/authAction';
import { setAuthLoader } from '../redux/auth/authLoadingSlice';
import { setRoleSliceFn } from '../redux/auth/authSlice';
import { initialize, setRedirectPath } from '../redux/other/authContextSlice';
import { useEditProfileSettingMutation } from '../redux/payments/profileAction';
import { useLazyGetStripeSubscriptionQuery } from '../redux/plan/planAction';
import { useSetupMutation } from '../redux/setup/setupAction';
import { socketPaymentSetupState } from '../redux/setup/setupSlice';
import {
  ADMIN,
  ONBOARDING,
  PAID_USER,
  PAYMENTS,
  SHIPPING,
  WEB,
  REDIRECTING,
  UNAUTHORIZE_PATH,
} from '../routes/paths';
import { errorHandler } from '../utils/errorHandler';

const AuthContext = createContext({
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  verifyAccount: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setPassword: () => Promise.resolve(),
  getSetup: () => Promise.resolve(),
  getPrimaryServiceKey: () => Promise.resolve(),
  shipTimeLogin: () => Promise.resolve(),
});

function AuthProvider({ children, loginFunction, loginWithToken, referral }) {
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const role = useSelector((state) => state?.auth?.role);
  // API Call
  const [signIn, { isError: isSignInError }] = useSignInMutation();
  const [ShiptimeUserAuth] = useShiptimeAuthenticateMutation();
  const { data: ipDetails = {} } = useGetIpInfoQuery();

  const [getPrimaryServiceKey, { isError: primaryServiceKeyError }] =
    useGetPrimaryServiceKeyMutation();
  const [setup, { isError: setupError }] = useSetupMutation();
  const [
    getStripeSubscription,
    { isError: stripeSubscriptionError, isSuccess: stripeSubscriptionSuccess },
  ] = useLazyGetStripeSubscriptionQuery();
  const [signUp, { isError: isSignUpError, isSuccess: signUpSuccess }] =
    useSignUpMutation();
  const [forgotPasswordCall] = useForgotPasswordMutation();
  const [verifyCall] = useVerifyMutation();
  const [reSetPasswordCall] = useResetPasswordMutation();
  const [setPasswordCall] = useSetPasswordMutation();
  const [editProfileSetting] = useEditProfileSettingMutation();

  const authContextState = useSelector((state) => state.authContext);

  // Functions
  const redirectPathFun = useCallback(
    (authData) => {
      const currentRole = authData?.AuthenticationResponse?.role;
      const isMemberRole = _MEMBER_ROLES.some((val) => val === currentRole);
      const isAdminRole = _SYS_ADMIN_ROLES.some((val) => val === currentRole);
      const superUser = authData?.AuthenticationResponse?.superUserToken;
      const serviceName = queryParams?.service;
      if (queryParams?.redirectUrl) {
        navigation(queryParams?.redirectUrl);
      } else if (isMemberRole) {
        if (serviceName || superUser) {
          switch (serviceName || authData?.AuthenticationResponse?.role) {
            case 'payments':
              navigation(PAYMENTS.root);
              break;
            case 'shipping':
              navigation(SHIPPING.dashboard.path);
              break;
            case 'web':
              navigation(WEB.root);
              break;

            default:
              navigation(PAID_USER.path);
              break;
          }
        }
      } else if (isAdminRole || superUser) {
        switch (currentRole) {
          case 'sysAdmin':
          case 'sysManager':
          case 'sysEmployee':
            if (
              !(
                localStorage.getItem('auth_token') &&
                JSON.parse(window.atob(localStorage.getItem('auth_token')))
              )
            )
              navigation(ADMIN.root);
            break;
          case 'sysAgent':
            if (
              !(
                localStorage.getItem('auth_token') &&
                JSON.parse(window.atob(localStorage.getItem('auth_token')))
              )
            )
              navigation(ADMIN.COMPANIES.fullPath);
            break;
          default:
            navigation('/404');
            break;
        }
      }
    },

    [navigation, queryParams?.redirectUrl, queryParams?.service]
  );

  const getSetup = useCallback(
    async (authData) => {
      const setupResponse = await setup();
      if (errorHandler(setupResponse)) {
        localStorage.removeItem('auth_token');
        dispatch(
          initialize({
            isAuthenticated: false,
          })
        );
        return;
      }
      const isMemberRole = _MEMBER_ROLES.some(
        (val) => val === authData?.AuthenticationResponse?.role
      );
      const superUser = authData?.AuthenticationResponse?.superUserToken;

      if (isMemberRole) {
        const companyDetails = setupResponse?.data?.profile?.companyDetails;

        if (
          ['canceled', 'inActive'].some(
            (val) => val !== companyDetails?.subscriptionStatus
          )
        ) {
          const stripeResponse = await getStripeSubscription(
            {
              companyId: companyDetails?._id,
            },
            { preferCacheValue: true }
          );
          if (errorHandler(stripeResponse)) {
            localStorage.removeItem('auth_token');
            dispatch(
              initialize({
                isAuthenticated: false,
              })
            );
            return;
          }
          if (stripeResponse?.data?.stripeSubscription) {
            redirectPathFun(authData);
          } else {
            if (superUser) {
              navigation(ONBOARDING.MANAGE_PLAN.path);
            } else {
              dispatch(
                setRedirectPath({
                  URL: ONBOARDING.MANAGE_PLAN.path,
                  isChange: true,
                })
              );
            }
          }
        } else {
          if (superUser) {
            navigation(ONBOARDING.MANAGE_PLAN.path);
          } else {
            dispatch(
              setRedirectPath({
                URL: ONBOARDING.MANAGE_PLAN.path,
                isChange: true,
              })
            );
          }
        }
      } else {
        redirectPathFun(authData);
      }
      dispatch(
        initialize({
          isAuthenticated: true,
        })
      );
      return setupResponse;
    },
    [dispatch, getStripeSubscription, navigation, redirectPathFun, setup]
  );

  const createHash = useCallback((auth) => {
    return bcrypt.hashSync(
      JSON.stringify({
        username: auth?.email,
        serviceId: auth?.serviceId,
        serviceKey: auth?.apiKey,
      }),
      10
    );
  }, []);

  const login = useCallback(
    async (data, loginFn) => {
      await getPrimaryServiceKey();
      const response = await loginFn(data);
      if (!errorHandler(response)) {
        const authData = response?.data?.AuthenticationResponse;
        connectSocket({
          email: authData?.email,
          token: authData?.token,
          serviceId: authData?.serviceId,
          superUserToken: authData?.superUserToken,
        });
        const data = {
          serviceId: authData?.serviceId,
          serviceKey: authData?.apiKey,
          callerId: authData?.callerId,
          hash: createHash(authData),
        };
        dispatch(setRoleSliceFn({ role: authData?.role }));
        const decData = JSON.stringify({
          ...data,
          loginTime: customDayjs().format('DD-MM-YYYY HH:mm:ss'),
        });

        localStorage.setItem('auth_token', window.btoa(decData));
        const result = await getSetup(response?.data);
        const setupData = result?.data;
        const payments = setupData?.payments;
        const accountId = setupData?.payments?.stripeDetails?.accountId;
        const paymentSettings =
          payments?.stripeDetails?.accountSettings?.paymentSettings;
        if (
          accountId &&
          ['ca', 'cad'].includes(
            setupData?.payments?.stripeDetails?.account?.country?.toLowerCase()
          ) &&
          paymentSettings?.bank
        ) {
          const accountSettings = {
            paymentSettings: { ...paymentSettings, bank: false },
          };
          const res = await editProfileSetting({
            accountId,
            accountSettings,
          });
          if (res?.data?.status === 'success') {
            dispatch(
              socketPaymentSetupState({
                type: 'updatePaymentSetting',
                accountSettings,
              })
            );
          }
        }
      } else {
        dispatch(
          initialize({
            isAuthenticated: false,
          })
        );
      }
      return response;
    },
    [createHash, dispatch, editProfileSetting, getPrimaryServiceKey, getSetup]
  );

  const shipTimeLogin = useCallback(
    async (data) => {
      await getPrimaryServiceKey();
      const response = await ShiptimeUserAuth(data);
      if (!errorHandler(response)) {
        const authData = response?.data?.AuthenticationResponse;
        connectSocket({
          email: authData?.email,
          token: authData?.token,
          serviceId: authData?.serviceId,
          superUserToken: authData?.superUserToken,
        });
        const data = {
          serviceId: authData?.serviceId,
          serviceKey: authData?.apiKey,
          callerId: authData?.callerId,
          hash: createHash(authData),
        };
        dispatch(
          setRoleSliceFn({ role: response?.data?.AuthenticationResponse?.role })
        );
        const decData = JSON.stringify({
          ...data,
          loginTime: customDayjs().format('DD-MM-YYYY HH:mm:ss'),
        });

        localStorage.setItem('auth_token', window.btoa(decData));
        await getSetup(response?.data);
      } else {
        dispatch(
          initialize({
            isAuthenticated: false,
          })
        );
      }
      return response;
    },
    [ShiptimeUserAuth, createHash, dispatch, getPrimaryServiceKey, getSetup]
  );

  const register = useCallback(
    async (data) => {
      await getPrimaryServiceKey();
      const response = await signUp(data);
      return response;
    },
    [getPrimaryServiceKey, signUp]
  );

  const forgotPassword = useCallback(
    async (data) => {
      await getPrimaryServiceKey();
      return await forgotPasswordCall(data);
    },
    [forgotPasswordCall, getPrimaryServiceKey]
  );

  const resetPassword = useCallback(
    async (data) => {
      await getPrimaryServiceKey();
      const response = await reSetPasswordCall(data);
      if (!errorHandler(response)) {
        if (data?.username && data?.password) {
          const authData = response?.data?.AuthenticationResponse;
          const modifiedData = {
            serviceId: authData?.serviceId,
            serviceKey: authData?.apiKey,
            callerId: authData?.callerId,
            hash: createHash(authData),
          };
          const decData = JSON.stringify({
            ...modifiedData,
            loginTime: customDayjs().format('DD-MM-YYYY HH:mm:ss'),
          });
          localStorage.setItem('auth_token', window.btoa(decData));
          await getSetup(response?.data);
        }
      }
      return response;
    },

    [createHash, getPrimaryServiceKey, getSetup, reSetPasswordCall]
  );

  const setPassword = useCallback(
    async (data) => {
      await getPrimaryServiceKey();
      const response = await setPasswordCall(data);
      if (!errorHandler(response)) {
        const authData = response?.data?.AuthenticationResponse;
        const data = {
          serviceId: authData?.serviceId,
          serviceKey: authData?.apiKey,
          callerId: authData?.callerId,
          hash: createHash(authData),
        };
        dispatch(setRoleSliceFn({ role: authData?.role }));
        const decData = JSON.stringify({
          ...data,
          loginTime: customDayjs().format('DD-MM-YYYY HH:mm:ss'),
        });
        localStorage.setItem('auth_token', window.btoa(decData));
        await getSetup(response?.data);
      }
      return response;
    },
    [createHash, dispatch, getPrimaryServiceKey, getSetup, setPasswordCall]
  );

  const verifyAccount = useCallback(
    async (data) => {
      await getPrimaryServiceKey();
      const response = await verifyCall(data);
      if (!errorHandler(response)) {
        const authData = response?.data?.AuthenticationResponse;
        /* TODO : By connecting socket below resolves the signout issue on first onboarding. Need to find alternative of this*/
        connectSocket({
          email: authData?.email,
          token: authData?.token,
          serviceId: authData?.serviceId,
          superUserToken: authData?.superUserToken,
        });
        const data = {
          serviceId: authData?.serviceId,
          serviceKey: authData?.apiKey,
          callerId: authData?.callerId,
          hash: createHash(authData),
        };
        dispatch(setRoleSliceFn({ role: authData?.role }));
        const decData = JSON.stringify({
          ...data,
          loginTime: customDayjs().format('DD-MM-YYYY HH:mm:ss'),
        });
        localStorage.setItem('auth_token', window.btoa(decData));
        await getSetup(response?.data);
      }
      return response;
    },
    [createHash, dispatch, getPrimaryServiceKey, getSetup, verifyCall]
  );

  const isError = useMemo(
    () =>
      primaryServiceKeyError ||
      setupError ||
      stripeSubscriptionError ||
      isSignInError ||
      isSignUpError,
    [
      isSignInError,
      isSignUpError,
      primaryServiceKeyError,
      setupError,
      stripeSubscriptionError,
    ]
  );

  useEffect(() => {
    if (isError) {
      dispatch(setAuthLoader({ loader: false }));
    }
  }, [dispatch, isError]);

  useEffect(() => {
    if (stripeSubscriptionSuccess) {
      dispatch(setAuthLoader({ loader: false }));
    }
  }, [dispatch, stripeSubscriptionSuccess]);

  useEffect(() => {
    if (signUpSuccess) {
      dispatch(setAuthLoader({ loader: false }));
    }
  }, [dispatch, signUpSuccess]);

  const isTokenExpired = useMemo(() => {
    let expTime;
    if (localStorage.getItem('auth_token')) {
      expTime = JSON.parse(
        window.atob(localStorage.getItem('auth_token'))
      )?.loginTime;
    }
    if (expTime) {
      const dateTime = expTime?.split(/[-\s:]/);
      const [day, month, year, time] = dateTime;
      const formattedExpTime = `${year}-${month}-${day} ${time}:${
        expTime?.split(':')?.[1]
      }:${expTime?.split(':')?.[2]}`;
      const expirationDate = customDayjs(formattedExpTime);
      const currentDate = customDayjs();
      const diffInHours = currentDate.diff(expirationDate, 'hour');
      if (
        authContextState &&
        localStorage.getItem('auth_token') &&
        diffInHours > 8
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [authContextState]);

  useEffect(() => {
    const initializeFun = () => {
      let accessToken;
      if (isTokenExpired) {
        localStorage.removeItem('auth_token');
      } else {
        accessToken =
          localStorage.getItem('auth_token') &&
          JSON.parse(window.atob(localStorage.getItem('auth_token')));
        if (accessToken?.loginTime) {
          delete accessToken?.loginTime;
        }
      }
      try {
        if (
          loginWithToken &&
          !authContextState.isAuthenticated &&
          !!Object.keys(ipDetails)?.length
        ) {
          login(
            {
              accessToken: loginWithToken,
              location: { userIp: ipDetails?.ip },
            },
            loginFunction
          );
        } else if (
          accessToken &&
          !authContextState.isInitialized &&
          !authContextState.isAuthenticated
        ) {
          !!Object.keys(ipDetails)?.length && login(accessToken, signIn);
        } else if (!accessToken) {
          dispatch(
            initialize({
              isAuthenticated: false,
            })
          );
        }
      } catch (err) {
        dispatch(
          initialize({
            isAuthenticated: false,
          })
        );
      }
    };
    const unAuthorizedPage =
      !localStorage?.getItem('auth_token') &&
      Object.values(UNAUTHORIZE_PATH).find(
        (route) => route.path === location.pathname
      );

    if (
      location.pathname !== REDIRECTING.path &&
      (!unAuthorizedPage || loginFunction)
    ) {
      initializeFun();
    } else {
      if (!authContextState.isAuthenticated) {
        dispatch(
          initialize({
            isAuthenticated: false,
          })
        );
      }
    }
  }, [
    authContextState.isAuthenticated,
    authContextState.isInitialized,
    dispatch,
    ipDetails,
    isTokenExpired,
    location.pathname,
    login,
    loginFunction,
    loginWithToken,
    signIn,
  ]);

  return (
    <AuthContext.Provider
      value={{
        ...authContextState,
        role,
        login,
        register,
        forgotPassword,
        isSignUpError,
        verifyAccount,
        resetPassword,
        setPassword,
        getSetup,
        getPrimaryServiceKey,
        shipTimeLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.any,
  loginFunction: PropTypes.any,
  loginWithToken: PropTypes.any,
  ipDetails: PropTypes.object,
  referral: PropTypes.any,
};

export { AuthContext, AuthProvider };
