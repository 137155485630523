import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    editProfileSetting: build.mutation({
      query: (payload) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.updateProfileSettings.url,
        method: apiRoutes.PAYMENTS.endpoints.updateProfileSettings.method,
        body: payload,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const { useEditProfileSettingMutation } = extendedApi;
