import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  redirectPath: {
    URL: null,
    isChange: true,
  },
};

const authContextSlice = createSlice({
  name: 'authContext',
  initialState,
  reducers: {
    initialize: (state, action) => {
      const { isAuthenticated } = action.payload;
      state.isInitialized = true;
      state.isAuthenticated = isAuthenticated || false;
    },
    setRedirectPath: (state, action) => {
      state.redirectPath = action.payload;
    },
  },
});

export const { initialize, setRedirectPath } = authContextSlice.actions;
export default authContextSlice.reducer;
