import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  googleLoader: false,
  facebookLoader: false,
};

const authLoaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setAuthLoader: (state, action) => {
      state.isLoading = action?.payload?.loader;
    },
  },
});

export const { setAuthLoader } = authLoaderSlice.actions;
export default authLoaderSlice.reducer;
