import { useCallback, useEffect, useMemo } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Card,
  CardContent,
  Container,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import customDayjs from '../../../../../components/customDayjs';
import ElementsTooltip from '../../../../../components/ElementsTooltip';
import Label from '../../../../../components/Label';
import Scrollbar from '../../../../../components/Scrollbar';
import CustomTablePagination from '../../../../../components/table-wrapper/CustomTablePagination';
import TableWrapper from '../../../../../components/table-wrapper/TableWrapper';
import useTable from '../../../../../hooks/useTable';
import {
  useGetStripePayoutTransactionsQuery,
  useGetStripeSinglePayoutQuery,
} from '../../../../../redux/payments/payout/payoutActions';
import { PAYMENTS } from '../../../../../routes/paths';
import { fnGetCustomerListData } from '../../../../../utils/fnGetCustomerListData';
import { getCardIconByName } from '../../../../../utils/getCardIconByName';
import { getCurrency } from '../../../../../utils/getCurrency';

const PayoutDetail = () => {
  // Hooks
  const [t] = useTranslation(['payments/payouts', 'common', 'orders']);
  const navigate = useNavigate();
  const { payoutId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Global state
  const accountId = useSelector(
    (state) => state?.setup?.payments?.stripeDetails?.accountId
  );

  // Custom Hook
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultCurrentPage:
      searchParams.get('page') > 0 ? searchParams.get('page') - 1 : 0,
    defaultRowsPerPage:
      searchParams.get('perPage') > 0 ? searchParams.get('perPage') : 20,
  });

  const filters = useMemo(() => {
    const rowFilters = {
      accountId,
      payout: payoutId,
      perPage: searchParams.get('perPage') || 20,
      startAfter: searchParams.get('startAfter') || '',
      endBefore: searchParams.get('endBefore') || '',
      filter_type: 'charge',
    };

    Object.keys(rowFilters).forEach((key) => {
      if (!rowFilters[key] && rowFilters[key] !== 0) {
        delete rowFilters[key];
      }
    });

    return rowFilters;
  }, [accountId, payoutId, searchParams]);

  // API calls
  const {
    data,
    isLoading,
    isFetching: isPayoutFetching,
  } = useGetStripeSinglePayoutQuery(
    {
      accountId,
      payoutId,
    },
    { skip: !payoutId }
  );

  const {
    data: transactionsData,
    isLoading: transactionsIsLoading,
    isFetching,
  } = useGetStripePayoutTransactionsQuery(filters);

  useEffect(() => {
    if (!(isLoading || isPayoutFetching) && !data && payoutId)
      navigate(PAYMENTS.PAYOUTS.fullPath);
  }, [data, isLoading, isPayoutFetching, navigate, payoutId]);

  // Constants
  const payoutData = useMemo(() => data?.payout, [data?.payout]);
  const payoutTransactions = useMemo(
    () => transactionsData?.transactions?.data,
    [transactionsData?.transactions?.data]
  );
  const totalTransactions = useMemo(
    () => transactionsData?.transactions?.total_count,
    [transactionsData?.transactions?.total_count]
  );
  const isNegativeAmount = useMemo(
    () => Number(payoutData?.amount) < 0,
    [payoutData?.amount]
  );
  const totalConvertedToPositive = useMemo(
    () =>
      isNegativeAmount
        ? Math.abs(Number(payoutData?.amount))
        : Number(payoutData?.amount),
    [isNegativeAmount, payoutData?.amount]
  );
  const transactionColumns = useMemo(
    () => [
      {
        field: 'common:method',
        headerName: 'method',
        render: (row) => (
          <Stack direction={'row'} alignItems={'center'} gap={0.5}>
            {getCardIconByName(
              row?.source?.payment_method_details?.card?.brand
            )}
            {row?.source?.payment_method_details?.card ? (
              <Typography variant="body2">{`(****${row?.source?.payment_method_details?.card?.last4})`}</Typography>
            ) : null}
          </Stack>
        ),
      },
      {
        field: 'common:date',
        headerName: 'date',
        render: (row) => {
          return row?.created ? (
            <Stack direction="row" gap={1}>
              <Typography variant="body2">
                {customDayjs
                  .unix(row.created)
                  .locale(i18next.language)
                  .format('DD, MMM YYYY')}
              </Typography>
            </Stack>
          ) : (
            'N/A'
          );
        },
      },
      {
        field: 'common:customer',
        headerName: 'type',
        render: (row) => {
          const data = fnGetCustomerListData(row?.source?.customer);
          const fullName = `${data?.firstName} ${data?.lastName}`;
          return (
            <ElementsTooltip
              upperElement={fullName}
              maxLengthOfUpperElement={38}
            />
          );
        },
      },
      {
        field: 'common:amount',
        headerName: 'amount',
        render: (row) =>
          getCurrency({
            currency: row?.currency,
            amount: row?.amount,
            isStripeAmount: true,
          }),
      },
      {
        field: 'common:fee',
        headerName: 'fee',
        render: (row) =>
          getCurrency({
            currency: row?.currency,
            amount: row?.fee,
            isStripeAmount: true,
          }),
      },
      {
        field: 'common:action',
        headerName: 'action',
        render: (row) => (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Link
              underline="none"
              color={(theme) => theme.palette.secondary.main}
              onClick={() => {
                navigate(
                  `${PAYMENTS.TRANSACTIONS_INFO.fullPath}?transaction=${row?.id}`
                );
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              {t('common:view')}
            </Link>
          </Stack>
        ),
      },
    ],
    [navigate, t]
  );

  // Handle page / perPage change
  const handleChangePage = useCallback(
    (event, newPage) => {
      let filter = {};
      onChangePage(event, newPage);
      if (newPage > Number(searchParams.get('page') - 1)) {
        const startAfter =
          payoutTransactions[payoutTransactions?.length - 1]?.id;
        filter = {
          ...Object.fromEntries([...searchParams]),
          startAfter,
        };
        delete filter.endBefore;
      } else {
        const endBefore = payoutTransactions[0]?.id;
        filter = {
          ...Object.fromEntries([...searchParams]),
          endBefore,
        };
        delete filter.startAfter;
      }
      setSearchParams({ ...filter, page: newPage + 1 });
    },
    [onChangePage, payoutTransactions, searchParams, setSearchParams]
  );
  const handleChangeRowsPerPage = useCallback(
    (event) => {
      onChangeRowsPerPage(event);
      searchParams.delete('startAfter');
      searchParams.delete('endBefore');
      searchParams.delete('page');
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        perPage: parseInt(event.target.value),
      });
    },
    [onChangeRowsPerPage, searchParams, setSearchParams]
  );

  const payoutCardContent = useMemo(() => {
    return [
      {
        title: `${t('common:date')}:`,
        content: customDayjs
          .unix(payoutData?.created)
          .locale(i18next.language)
          .format('DD, MMM YYYY hh:mm:ss a'),
      },
      {
        title: `${t('common:amount')}:`,
        content: totalConvertedToPositive
          ? isNegativeAmount
            ? `
              ($ ${Number(totalConvertedToPositive / 100).toFixed(2)})
                ${payoutData?.destination?.currency.toString().toUpperCase()}`
            : `$ ${Number(totalConvertedToPositive / 100).toFixed(2)} 
              ${payoutData?.destination?.currency.toString().toUpperCase()}`
          : 'N/A',
      },
      {
        title: `${t('common:bank')}:`,
        content: payoutData?.destination?.bank_name
          ? `${payoutData?.destination?.bank_name}
    (**** ${payoutData?.destination?.last4})`
          : 'N/A',
      },
    ];
  }, [isNegativeAmount, payoutData, t, totalConvertedToPositive]);

  return (
    <Container maxWidth="xxl">
      <Stack alignItems={'center'} pb={3} direction={'row'} gap={1}>
        <IconButton
          size="small"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(PAYMENTS.PAYOUTS.fullPath)}
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <Typography variant="h5">
          {t('payments/payouts:payoutDetails')}
        </Typography>
      </Stack>
      <Stack gap={2}>
        <Card>
          <CardContent>
            {isLoading ? (
              <Skeleton variant="rectangular" width={70} height={20} />
            ) : (
              <Label
                variant={'ghost'}
                color={payoutData?.status === 'paid' ? 'primary' : 'error'}
                sx={{ textTransform: 'capitalize' }}
              >
                {payoutData?.status === 'paid'
                  ? t('payments/payouts:paid')
                  : t('payments/payouts:failed')}
              </Label>
            )}
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              gap={3}
              flexWrap={'wrap'}
              mt={2}
            >
              {payoutCardContent?.map((item, index) => {
                return isLoading ? (
                  <Stack key={item?.title} gap={1}>
                    <Skeleton variant="rectangular" width={100} height={15} />
                    <Skeleton variant="rectangular" width={300} height={15} />
                  </Stack>
                ) : (
                  <Stack key={item?.title}>
                    <Typography variant="body2" color="GrayText">
                      {item?.title}
                    </Typography>
                    <Typography variant="subtitle2">{item?.content}</Typography>
                  </Stack>
                );
              })}
            </Stack>

            {payoutData?.status === 'failed' &&
              (isLoading ? (
                <Stack gap={1} mt={3}>
                  <Skeleton variant="rectangular" width={100} height={15} />
                  <Skeleton variant="rectangular" width={'80%'} height={15} />
                </Stack>
              ) : (
                <Stack mt={2}>
                  <Typography variant="body2" color="GrayText">
                    {`${t('common:reason')}:`}
                  </Typography>
                  <Typography variant="subtitle1">
                    {payoutData?.failure_message}
                  </Typography>
                </Stack>
              ))}
          </CardContent>
        </Card>
        <Card>
          <Scrollbar>
            <TableWrapper
              isLoading={transactionsIsLoading || isFetching}
              columns={transactionColumns}
              rowsData={payoutTransactions}
              rowKey={'id'}
              t={t}
            />
          </Scrollbar>
          <CustomTablePagination
            isLoading={isLoading || isFetching}
            count={Number(totalTransactions) || 0}
            rowsPerPage={Number(rowsPerPage)}
            page={Number(page)}
            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
          />
        </Card>
      </Stack>
    </Container>
  );
};

export default PayoutDetail;
