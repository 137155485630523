import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';
import { updateCacheData } from '../utils/common';

api.enhanceEndpoints({
  addTagTypes: ['Members', 'PartnerAgent', 'getMemberUsers'],
});

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMemberUsers: build.query({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.getMemberUsers.url,
        method: apiRoutes.ACCOUNTS.endpoints.getMemberUsers.method,
        params: params,
      }),
      providesTags: ['Members', 'getMemberUsers'],
      transformResponse: (res) => {
        return {
          ...res,
          users: {
            allUsers: res.users,
            currentPageUsers: res.users,
            totalCount: res.total,
          },
        };
      },
    }),
    getSingleMemberUser: build.query({
      query: (params) => {
        return {
          url: apiRoutes.PAID.endpoints.getTeamUser.url.replace(':userId?', ''),
          method: apiRoutes.PAID.endpoints.getTeamUser.method,
          params: params,
        };
      },
      transformResponse: (res) => {
        return {
          ...res,
        };
      },
    }),
    getRoles: build.query({
      query: (params) => {
        return {
          url:
            params && params.includes('member')
              ? apiRoutes.PAID.endpoints.getRoles.url.replace(':role', 'member')
              : params && params.includes('sys')
              ? apiRoutes.PAID.endpoints.getRoles.url.replace(':role', 'sys')
              : apiRoutes.PAID.endpoints.getRoles.url.replace(':role', ''),
          method: apiRoutes.PAID.endpoints.getRoles.method,
        };
      },
      transformResponse: (res) => {
        return res.roles;
      },
    }),
    getPaidWebRoles: build.query({
      query: () => {
        return {
          url: apiRoutes.PAID.endpoints.getPaidWebRoles.url,
          method: apiRoutes.PAID.endpoints.getPaidWebRoles.method,
        };
      },
      transformResponse: (res) => {
        return res.roles;
      },
    }),
    addTeamMember: build.mutation({
      query: (body) => {
        return {
          url: apiRoutes.PAID.endpoints.AddTeamMemberUser.url,
          method: apiRoutes.PAID.endpoints.AddTeamMemberUser.method,
          body,
        };
      },
      invalidatesTags: ['getMemberUsers'],
      transformResponse: (res) => {
        return res;
      },
    }),
    editTeamMember: build.mutation({
      invalidatesTags: (res) => (res ? ['PartnerAgent'] : []),
      query: ({ id, user }) => {
        return {
          url: apiRoutes.PAID.endpoints.editTeamMember.url,
          method: apiRoutes.PAID.endpoints.editTeamMember.method,
          body: { userId: id, user: user },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getMemberUsers',
              dispatch,
              extendedApi,
            },
            (draft) => {
              const allUsersObj = draft.users.allUsers.find(
                (item) => item?._id === data?.updatedUserDetails?._id
              );
              const currentPageUsersObj = draft.users.currentPageUsers.find(
                (item) => item?._id === data?.updatedUserDetails?._id
              );
              // Checked objects are undefined or not
              allUsersObj &&
                Object.assign(allUsersObj, data?.updatedUserDetails);
              currentPageUsersObj &&
                Object.assign(currentPageUsersObj, data?.updatedUserDetails);
            }
          );
          updateCacheData(
            {
              state: getState(),
              method: 'getSingleMemberUser',
              dispatch,
              extendedApi,
            },
            (draft) => {
              draft.data = data?.updatedUserDetails;
              return draft;
            }
          );
        } catch (error) {
          return error;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    deleteTeamMember: build.mutation({
      query: (userIds) => {
        const values = {};
        userIds.forEach((s, i) => {
          values[`userIds[${i}]`] = s;
        });
        return {
          url: apiRoutes.PAID.endpoints.deleteTeamMember.url.replace(
            ':userId?',
            ''
          ),
          params: values,
          method: apiRoutes.PAID.endpoints.deleteTeamMember.method,
        };
      },
      invalidatesTags: (res) => (res ? ['Members'] : []),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        updateCacheData(
          {
            state: getState(),
            method: 'getMemberUsers',
            dispatch,
            extendedApi,
          },

          (draft) => {
            const allUsersData = draft.users.allUsers.filter((item) => {
              return !arg.includes(item._id);
            });

            const currentPageUsersData = draft.users.currentPageUsers.filter(
              (item) => {
                return !arg.includes(item._id);
              }
            );

            draft.users.totalCount -= arg.length;
            draft.users.allUsers = allUsersData;
            draft.users.currentPageUsers = currentPageUsersData;
          }
        );
      },
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetMemberUsersQuery,
  useLazyGetMemberUsersQuery,
  useGetRolesQuery,
  useGetPaidWebRolesQuery,
  useAddTeamMemberMutation,
  useEditTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useGetSingleMemberUserQuery,
} = extendedApi;
