import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import RHFButton from '../../../components/button/RHFButton';
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
import { removeEmptyData } from '../../../components/removeEmptyData';
import { products } from '../../../constants';
import { PARTNER_LINK } from '../../../constants/ExternalLinks';
import {
  useAddPartnerMutation,
  useGetAgentByIdQuery,
  useGetPartnerByIdQuery,
  useUpdatePartnerMutation,
} from '../../../redux/partners/partnersAction';
import {
  useLazyGetPlanListQuery,
  useLazyGetPlansByCompanyIdQuery,
} from '../../../redux/plan/planAction';
import * as routes from '../../../routes/paths';
import trimmedString from '../../../utils/trimmedString';
import PlanHeader from '../admin/plans/PlanHeader';
import PartnerAgents from './PartnerAgents';
import PartnerDetails from './PartnerDetails';

const CustomTab = withStyles({
  root: {
    backgroundColor: 'primary',
  },
  selected: {
    backgroundColor: 'info',
  },
})(Tab);

const PartnerForm = () => {
  const { id } = useParams();
  const [localProductsArray, setLocalProductsArray] = useState([]);

  // Global state
  const setUp = useSelector((state) => state?.setup);
  const profile = useMemo(() => setUp?.profile, [setUp?.profile]);
  const loginId = useMemo(() => profile?.loginId, [profile?.loginId]);
  const navigate = useNavigate();

  // Local state
  const [allPlans, setAllPlans] = useState([]);
  const [deletedPlans, setDeletedPlans] = useState([]);
  const [mainPlan, setMainPlan] = useState(true);
  const auth = useSelector((state) => state?.auth);
  const role = useMemo(() => auth?.role, [auth?.role]);
  const notAllowed = useMemo(
    () => ['sysEmployee', 'sysManager']?.includes(role),
    [role]
  );

  // API calls
  const {
    data: partnerById,
    isFetching: partnerByIdFetching,
    isLoading: partnerByIdLoading,
  } = useGetPartnerByIdQuery({ partnerId: id }, { skip: !id });
  const [
    getPlanList,
    { data, isFetching: getPlanFetching, isLoading: getPlanLoading },
  ] = useLazyGetPlanListQuery();

  const [
    getPlansByCompanyId,
    { data: plansByCompanyId, isFetching, isLoading: pageLoading },
  ] = useLazyGetPlansByCompanyIdQuery();

  const [addPartner, { isLoading }] = useAddPartnerMutation();
  const [updatePartner, { isLoading: isEditLoading }] =
    useUpdatePartnerMutation();
  const {
    data: agentById,
    isLoading: getAgentLoading,
    isFetching: getAgentFetching,
  } = useGetAgentByIdQuery(id, { skip: !id, refetchOnMountOrArgChange: true });

  const loader = useMemo(
    () =>
      partnerByIdFetching ||
      partnerByIdLoading ||
      pageLoading ||
      isFetching ||
      getAgentLoading ||
      getPlanFetching ||
      getPlanLoading ||
      getAgentFetching,
    [
      getAgentFetching,
      getAgentLoading,
      getPlanFetching,
      getPlanLoading,
      isFetching,
      pageLoading,
      partnerByIdFetching,
      partnerByIdLoading,
    ]
  );

  const defaultValues = useMemo(
    () => ({
      id: '',
      url: '',
      partnerName: '',
      hidePartner: false,
      intercept: 'active',
      paymentMethod: 'stripe',
      mainPlan: true,
      agentsArray: [
        {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          agentStatus: 'active',
        },
      ],
      productsAvailable: [
        {
          title: '',
          value: '',
        },
      ],
      plansArray: [
        {
          status: 'Active',
          planName: '',
          shippingRate: 'USPSCommercialBase',
          subscriptions: [
            {
              type: 'mo',
              priceInCAD: '',
              priceInUSD: '',
              stripePlanIdCAD: '',
              stripePlanIdUSD: '',
            },
            // For yearly plans
            {
              priceInCAD: '',
              priceInUSD: '',
              stripePlanIdCAD: '',
              stripePlanIdUSD: '',
              type: 'yr',
            },
          ],
          enDescription: '',
          frDescription: '',
          collectPaymentPercent: 0,
          collectPaymentAmount: 0,

          // For international charges
          intCollectPaymentPercent: 0,
          intCollectPaymentAmount: 0,
          includesWebsiteUpTo: 0,
          shippingDiscount: '',
          support: [],
        },
      ],
    }),
    []
  );
  const formSchema = useMemo(() => {
    const value = {
      partnerName: Yup.string()
        .trim()
        .required('Partner name is required')
        .matches(
          /^(?=.*[a-zA-Z0-9])(?=[\w!@#$%^&*()\-+={}[\]\\|;:'",.<>/?`~\s]*).*$/g,
          'Special characters are not allowed'
        )
        .matches(/.*[a-zA-Z].*/, 'Numbers are not allowed')
        .min(2, 'Minimum two character required'),
      url: Yup.string()
        .required('URL is required')
        .matches(
          /^(?=.*[a-zA-Z])(?=[\w!@#$%^&*()\-+={}[\]\\|;:'",.<>/?`~]*)(?!^\d+$)(?!.*\s{2}).*$/g,
          'Invalid url'
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          'Leading & trailing spaces are not allowed'
        )
        .min(2, 'Minimum two character required'),
      agentsArray: Yup.array().of(
        Yup.object().shape({
          first_name: Yup.string()
            .trim()
            .required('First name is required')
            .matches(/^([^0-9]*)$/, 'Numbers are not allowed')
            .matches(/^[a-zA-Z'\s]+$/, 'Special characters are not allowed')
            .min(2, 'Minimum 2 characters are required'),
          last_name: Yup.string()
            .trim()
            .required('Last name is required')
            .matches(/^([^0-9]*)$/, 'Numbers are not allowed')
            .matches(/^[a-zA-Z'\s]+$/, 'Special characters are not allowed')
            .min(2, 'Minimum 2 characters are required'),
          email: Yup.string()
            .required('Please enter email.')
            .email('Please enter valid email')
            .matches(/^(?!\s)(.*\S)?(?<!\s)$/, 'Please enter valid email')
            .min(6, 'Enter email between 6 to 40 character long')
            .max(40, 'Enter email between 6 to 40 character long'),
          phone: Yup.string()
            .required('Please enter phone number.')
            .matches(
              /^(?!\s)(.*\S)?(?<!\s)$/,
              'Please enter valid phone number'
            )
            .matches(/^(?!(0))[0-9]+$/, 'phone must be non zero')
            .matches(/^((?!(1))[0-9-]*)$/, 'phone can not start with 1')
            .min(10, 'Phone must be at least 10 characters'),
        })
      ),
    };
    if (!mainPlan) {
      const validationObj = {
        planName: Yup.string().required('Please enter partner name.'),
        includesWebsiteUpTo: Yup.number()
          .nullable('Include websites up to is required')
          .typeError('Include websites up to is required')
          .required('Include websites up to is required'),

        collectPaymentPercent: Yup.number()
          .nullable('Collect payment percent to is required.')
          .typeError('Collect payment percent to is required.')
          .required('Collect payment percent to is required.'),

        collectPaymentAmount: Yup.number()
          .nullable('Collect payment amount is required')
          .typeError('Collect payment amount is required')
          .required('Collect payment amount is required.'),

        // For international charges
        intCollectPaymentPercent: Yup.number()
          .nullable('Collect payment percent to is required.')
          .typeError('Collect payment percent to is required.')
          .required('Collect payment percent to is required.'),

        intCollectPaymentAmount: Yup.number()
          .nullable('Collect payment amount is required')
          .typeError('Collect payment amount is required')
          .required('Collect payment amount is required.'),

        shippingDiscount: Yup.number()
          .typeError('Shipping discount is required.')
          .required('Shipping discount is required.'),

        subscriptions: Yup.array().of(
          Yup.object().shape(
            {
              priceInCAD: Yup.string()
                .test(
                  'priceInCAD',
                  'Price In CAD is required',
                  (value, context) => {
                    const usdPrice =
                      context?.from?.[1]?.value?.subscriptions?.find(
                        (ele) => ele.priceInUSD
                      );
                    const cadPrice =
                      context?.from?.[1]?.value?.subscriptions?.find(
                        (ele) => ele.priceInCAD
                      );
                    if (!value && !cadPrice && !usdPrice) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
                .typeError('Price In CAD is required')
                .matches(
                  /^\d*\.?\d{0,2}$/,
                  'Price In CAD can have at most 2 decimal points'
                )
                .matches(
                  /^\d{0,6}(\.\d{0,2})?$/,
                  'Price In CAD must be less than 999,999'
                ),
              priceInUSD: Yup.string()
                .test(
                  'priceInUSD',
                  'Price In USD is required',
                  (value, context) => {
                    const usdPrice =
                      context?.from?.[1]?.value?.subscriptions?.some(
                        (ele) => ele.priceInUSD
                      );
                    const cadPrice =
                      context?.from?.[1]?.value?.subscriptions?.some(
                        (ele) => ele.priceInCAD
                      );
                    if (!value && !cadPrice && !usdPrice) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                )
                .typeError('Price In USD is required.')
                .matches(
                  /^\d*\.?\d{0,2}$/,
                  'Price In USD can have at most 2 decimal points'
                )
                .matches(
                  /^\d{0,6}(\.\d{0,2})?$/,
                  'Price In USD must be less than 999,999'
                ),
              type: Yup.string(),
              stripePlanIdUSD: Yup.string().when('priceInUSD', {
                is: (priceInUsd) => {
                  return priceInUsd;
                },
                then: Yup.string()
                  .required('Please enter stripe plan id in USD.')
                  .matches(
                    /^(?!\s)(.*\S)?(?<!\s)$/,
                    'Please enter valid stripe plan id'
                  ),
              }),
              stripePlanIdCAD: Yup.string().when('priceInCAD', {
                is: (priceInCAD) => {
                  return priceInCAD;
                },
                then: Yup.string()
                  .required('Please enter stripe plan id in CAD.')
                  .matches(
                    /^(?!\s)(.*\S)?(?<!\s)$/,
                    'Please enter valid stripe plan id'
                  ),
              }),
            },
            [
              'priceInCAD',
              'priceInUSD',
              'type',
              'stripePlanIdUSD',
              'stripePlanIdCAD',
            ]
          )
        ),
      };
      const derivedArray = localProductsArray.map((acc) => acc?.value);
      if (!derivedArray.includes('payments')) {
        delete validationObj.collectPaymentAmount;
        delete validationObj.collectPaymentPercent;

        // For international charges
        delete validationObj.intCollectPaymentAmount;
        delete validationObj.intCollectPaymentPercent;
      }
      if (!derivedArray.includes('web') && !derivedArray.includes('webCart')) {
        delete validationObj.includesWebsiteUpTo;
      }
      if (!derivedArray.includes('shipping')) {
        delete validationObj.shippingDiscount;
      }
      value.plansArray = Yup.array().of(
        Yup.object().shape(validationObj, ['priceInCAD', 'priceInUSD'])
      );
    }
    return value;
  }, [localProductsArray, mainPlan]);

  const partnerSchema = Yup.object().shape(formSchema);

  // Form setup
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(partnerSchema),
    defaultValues,
  });

  const [tabData, setTabData] = useState(['mo']);

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const {
    fields: planArrayFields,
    append: planArrayAppend,
    remove: planArrayRemove,
  } = useFieldArray({
    control,
    name: 'plansArray',
  });
  const values = watch();

  const callBackFn = useCallback(
    (data, index) => {
      const newArr = [...tabData];
      newArr[index] = data;
      setTabData(newArr);
      setValue('type', data);
    },
    [setValue, tabData]
  );

  const plansFeatures = useMemo(() => {
    let plansArray = data?.plans?.reduce((arr, obj) => {
      if (obj.active) {
        arr.push({ value: obj?.plan?.name, title: obj?.plan?.name });
      }
      return arr;
    }, []);
    return plansArray;
  }, [data?.plans]);

  const onSelectedPlan = useCallback(
    (val, index) => {
      setValue(`plansArray[${index}].planName`, val, {
        shouldValidate: true,
      });
    },
    [setValue]
  );

  useEffect(() => {
    setLocalProductsArray(values?.productsAvailable);
  }, [setLocalProductsArray, values?.productsAvailable]);

  const hasPaymentsPermission = useMemo(
    () =>
      values?.productsAvailable?.findIndex(
        (item) => item?.value === 'payments'
      ) > -1,
    [values?.productsAvailable]
  );
  const hasShippingPermission = useMemo(
    () =>
      values?.productsAvailable?.findIndex(
        (item) => item?.value === 'shipping'
      ) > -1,
    [values?.productsAvailable]
  );

  const hasWebPermission = useMemo(
    () =>
      values?.productsAvailable?.findIndex((item) =>
        ['web', 'webCart', 'web+cart'].includes(item?.value?.toLowerCase())
      ) > -1,
    [values?.productsAvailable]
  );

  const removePlan = useCallback(
    (e, index, item) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const product = values?.plansArray?.find((_, id) => id === index);

      const removedPlan = {};
      Object.keys(product)?.some((key) => {
        if (key === 'planName') {
          removedPlan.title = product[key];
          removedPlan.value = product[key];
        }
        return key === 'planName';
      }, []);
      setAllPlans([...allPlans, removedPlan]);

      product._id = plansByCompanyId?.plans?.find((plan) => {
        return item.planName === plan?.plan?.name;
      })?._id;
      product.partner = id;

      product.plan = plansByCompanyId?.plans?.find((plan) => {
        return item.planName === plan?.plan?.name;
      })?.plan?._id;
      product.deleted = true;

      setDeletedPlans((prevState) => [...prevState, product]);
      planArrayRemove(index);
    },
    [allPlans, id, planArrayRemove, plansByCompanyId?.plans, values?.plansArray]
  );

  const addNew = useCallback(() => {
    const plansArrayValues = values?.plansArray.map((ele) => {
      return ele.planName;
    });
    const filterPlanLabel = plansFeatures?.filter((ele) => {
      return !plansArrayValues?.includes(ele?.value);
    });
    const planLabel = filterPlanLabel.map((ele) => ele?.value);

    const stringForUID =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    planArrayAppend({
      id: stringForUID.charAt(Math.floor(Math.random() * stringForUID.length)),
      status: 'Active',
      planName: planLabel[0],
      subscriptions: [
        {
          priceInCAD: '',
          priceInUSD: '',
          stripePlanIdCAD: '',
          stripePlanIdUSD: '',
          type: 'mo',
        },
        {
          priceInCAD: '',
          priceInUSD: '',
          stripePlanIdCAD: '',
          stripePlanIdUSD: '',
          type: 'yr',
        },
      ],
      shippingRate: 'USPSCommercialBase',
      enDescription: '',
      frDescription: '',
      collectPaymentPercent: '',
      collectPaymentAmount: '',
      intCollectPaymentPercent: '',
      intCollectPaymentAmount: '',
      includesWebsiteUpTo: '',
      shippingDiscount: '',
      support: [],
    });
  }, [planArrayAppend, plansFeatures, values?.plansArray]);

  const [isEditAgent, setIsEditAgent] = useState(false);
  const [isImgError, setIsImgError] = useState(false);

  const onSubmit = useCallback(
    async (values) => {
      values.partnerName = trimmedString(values?.partnerName);
      if (isEditAgent) {
        enqueueSnackbar('Please save partner agent detail first.', {
          variant: 'error',
        });
      } else {
        const obj = { ...values };

        let companyPlans = [];

        if (!obj.mainPlan) {
          companyPlans = obj?.plansArray?.map((plans, index) => {
            const planData = data?.plans?.find((plan) => {
              return plans.planName === plan?.plan?.name;
            })?.plan;
            plans.plan = {
              ...planData,
              frDescription: plans.frDescription,
              enDescription: plans.enDescription,
            };
            if (id) {
              plans.partner = partnerById?._id;
            }
            plans.active = plans.status === 'Active';
            const subscriptionArray = plans?.subscriptions?.filter((ele) => {
              return (
                Object.keys(removeEmptyData(ele))?.length > 1 &&
                removeEmptyData(ele)
              );
            });
            plans.subscriptions = subscriptionArray?.map((subscription) => {
              subscription = {
                ...subscription,
                ...(subscription?.priceInCAD
                  ? {
                      priceInCAD:
                        subscription?.priceInCAD &&
                        Number(subscription?.priceInCAD),
                    }
                  : {}),
                ...(subscription?.priceInUSD
                  ? {
                      priceInUSD:
                        subscription?.priceInUSD &&
                        Number(subscription?.priceInUSD),
                    }
                  : {}),
              };
              return subscription;
            });

            return plans;
          });
        }
        const agents = obj?.agentsArray?.map((d) => {
          d.role = 'sysAgent';
          d.company_name = 'Paid';
          d.parent_user_name = `${profile?.first_name} ${profile?.last_name}`;
          d.first_name = trimmedString(d?.first_name);
          d.last_name = trimmedString(d?.last_name);
          d.createdBy = profile.loginId;
          d.email = d.email.toLowerCase();
          d.isNew = true;
          return d;
        });

        const partner = {
          paymentMethod: obj.paymentMethod ? obj.paymentMethod : 'stripe',

          name: obj.partnerName,
          url: obj.url.toLowerCase().replace(/\s+/g, ''),
          hidePartner: !!obj.hidePartner,
        };

        if (obj.partnerLogo) {
          partner.logo = obj.partnerLogo;
        }

        if (obj.mainPlan && obj.plansArray && obj.plansArray.length > 0) {
          companyPlans = [];
        }
        partner.mainPlan = obj.mainPlan;
        if (
          obj?.productsAvailable?.some((productAvail) => productAvail?.value)
        ) {
          if (!isImgError) {
            const productsName = obj?.productsAvailable?.map(
              (item) => item?.value
            );
            const productsAvailableArr = products?.filter((item) =>
              productsName?.includes(item?.value)
            );

            partner.productsAvailable = productsAvailableArr;
            const data = new FormData();
            if (!obj.partnerLogo && !partner.logo) {
              partner.isImageDeleted = true;
            } else {
              data.append('image', partner.logo);
            }
            data.append('partner', JSON.stringify(partner));
            data.append(
              'agents',
              JSON.stringify(
                agents?.some((agent) => agent?.first_name) ? agents : []
              )
            );
            data.append(
              'companyPlans',
              JSON.stringify(companyPlans?.concat(deletedPlans))
            );

            let response;
            const displayError = (response) => {
              const {
                error: { data: errorData },
              } = response;
              enqueueSnackbar(
                errorData?.error ||
                  errorData?.error?.error ||
                  errorData?.error?.message ||
                  errorData?.message ||
                  'error',
                {
                  variant: 'error',
                }
              );
            };
            const isAllPlanInActive = companyPlans?.every(
              (item) => item?.status === 'Inactive'
            );

            if (isAllPlanInActive && !obj?.mainPlan) {
              enqueueSnackbar(
                'Make sure either you have a custom plan or choose the main plan.',
                {
                  variant: 'error',
                }
              );
            } else {
              if (id) {
                // update
                const params = {
                  id,
                  data,
                };
                response = await updatePartner(params);

                const updatedTabData = companyPlans?.map((item) => {
                  const hasMonthly = item?.subscriptions?.some(
                    (sub) => sub.type === 'mo'
                  );
                  return hasMonthly ? 'mo' : 'yr';
                });
                setTabData(updatedTabData);
                if (response?.data?.status === 'success') {
                  enqueueSnackbar('Partner details updated successfully', {
                    variant: 'success',
                  });
                  navigate(
                    `${routes.ADMIN.PARTNERS.fullPath}?pageSize=${20}&page=${1}`
                  );
                } else {
                  displayError(response);
                }
              } else {
                if (agents?.some((agent) => agent?.first_name)) {
                  response = await addPartner(data);
                  if (response?.data?.status === 'success') {
                    enqueueSnackbar(
                      response?.data?.RegistrationResponse?.message,
                      {
                        variant: 'success',
                      }
                    );
                    navigate(
                      `${
                        routes.ADMIN.PARTNERS.fullPath
                      }?pageSize=${20}&page=${1}`
                    );
                  } else {
                    displayError(response);
                  }
                } else {
                  enqueueSnackbar('Please add at least one partner agent.', {
                    variant: 'error',
                  });
                }
              }
            }
          }
          // new add
        } else {
          enqueueSnackbar('Please select at least one product.', {
            variant: 'error',
          });
        }
      }
    },
    [
      addPartner,
      data?.plans,
      deletedPlans,
      id,
      isEditAgent,
      isImgError,
      navigate,
      partnerById?._id,
      profile?.first_name,
      profile?.last_name,
      profile.loginId,
      updatePartner,
    ]
  );

  useEffect(() => {
    setMainPlan(values.mainPlan);
  }, [values.mainPlan]);

  useEffect(() => {
    getPlanList(
      {
        loginId,
      },
      { preferCacheValue: true }
    );
    if (id)
      getPlansByCompanyId({
        loginId,
        partnerId: id,
      });
  }, [getPlanList, getPlansByCompanyId, id, loginId]);

  useEffect(() => {
    const finalTabArr = [];
    plansByCompanyId?.plans?.forEach((item) => {
      item?.subscriptions?.forEach((subscription) => {
        if (
          subscription?.type === 'mo' &&
          (subscription?.priceInCAD || subscription?.priceInUSD)
        ) {
          finalTabArr?.push('mo');
          return;
        } else if (
          subscription?.type === 'yr' &&
          (subscription?.priceInCAD || subscription?.priceInUSD)
        ) {
          finalTabArr?.push('yr');
        }
      });
    });
    setTabData(finalTabArr);
  }, [plansByCompanyId?.plans]);

  useEffect(() => {
    if (id) {
      const formData = partnerById?.partner;
      if (formData) {
        let defaultData = {
          id: id,
          url: formData.url,
          partnerName: formData.name,
          partnerLogo: formData.partnerLogo,
          paymentMethod: formData.paymentMethod,
          mainPlan: formData.mainPlan,
          hidePartner: formData?.hidePartner,
          productsAvailable: formData?.productsAvailable?.map((product) => {
            return {
              title: product?.title,
              value: product?.value,
            };
          }),
        };

        if (!formData.mainPlan && plansByCompanyId?.plans?.length) {
          defaultData.plansArray = plansByCompanyId?.plans?.map((plan) => {
            const monthlyPrice = plan?.subscriptions?.findIndex(
              (subscription) => subscription.type === 'mo'
            );
            const yearlyPrice = plan?.subscriptions?.findIndex(
              (subscription) => subscription.type === 'yr'
            );
            const planObj = {
              status: plan?.active ? 'Active' : 'Inactive',
              planName: plan?.plan?.name || plansFeatures[0].value,
              plan: plan?.plan,
              shippingRate: plan?.shippingRate?.replace(/\s+/g, ''),
              subscriptions: [],
              enDescription: plan?.plan?.enDescription,
              frDescription: plan?.plan?.frDescription,
              collectPaymentPercent: plan?.collectPaymentPercent,
              collectPaymentAmount: plan?.collectPaymentAmount,

              // For international charges
              intCollectPaymentPercent: plan?.intCollectPaymentPercent,
              intCollectPaymentAmount: plan?.intCollectPaymentAmount,
              includesWebsiteUpTo: plan?.includesWebsiteUpTo,
              shippingDiscount: plan?.shippingDiscount,
              support: plan?.support || [],
              _id: plan?._id,
            };
            if (monthlyPrice > -1) {
              planObj.subscriptions.push({
                type: plan?.subscriptions?.[monthlyPrice]?.type || 'mo',
                priceInCAD:
                  plan?.subscriptions?.[monthlyPrice]?.priceInCAD >= 0 &&
                  plan?.subscriptions?.[monthlyPrice]?.priceInCAD !== null
                    ? Number(plan?.subscriptions?.[monthlyPrice]?.priceInCAD)
                    : '' || '',
                priceInUSD:
                  plan?.subscriptions?.[monthlyPrice]?.priceInUSD >= 0 &&
                  plan?.subscriptions?.[monthlyPrice]?.priceInUSD !== null
                    ? Number(plan?.subscriptions?.[monthlyPrice]?.priceInUSD)
                    : '' || '',
                stripePlanIdCAD:
                  plan?.subscriptions?.[monthlyPrice]?.stripePlanIdCAD,
                stripePlanIdUSD:
                  plan?.subscriptions?.[monthlyPrice]?.stripePlanIdUSD,
              });
            } else {
              planObj.subscriptions.push({
                type: 'mo',
                priceInCAD: '',
                priceInUSD: '',
                stripePlanIdCAD: '',
                stripePlanIdUSD: '',
              });
            }
            if (yearlyPrice > -1) {
              planObj.subscriptions.push({
                type: plan?.subscriptions?.[yearlyPrice]?.type || 'yr',
                priceInCAD:
                  plan?.subscriptions?.[yearlyPrice]?.priceInCAD >= 0 &&
                  plan?.subscriptions?.[yearlyPrice]?.priceInCAD !== null
                    ? Number(plan?.subscriptions?.[yearlyPrice]?.priceInCAD)
                    : '' || '',
                priceInUSD:
                  plan?.subscriptions?.[yearlyPrice]?.priceInUSD >= 0 &&
                  plan?.subscriptions?.[yearlyPrice]?.priceInUSD !== null
                    ? Number(plan?.subscriptions?.[yearlyPrice]?.priceInUSD)
                    : '' || '',
                stripePlanIdCAD:
                  plan?.subscriptions?.[yearlyPrice]?.stripePlanIdCAD,
                stripePlanIdUSD:
                  plan?.subscriptions?.[yearlyPrice]?.stripePlanIdUSD,
              });
            } else {
              planObj.subscriptions.push({
                type: 'yr',
                priceInCAD: '',
                priceInUSD: '',
                stripePlanIdCAD: '',
                stripePlanIdUSD: '',
              });
            }
            return planObj;
          });
        } else {
          defaultData.plansArray = defaultValues?.plansArray;
        }
        reset(defaultData);
      }
    }
  }, [
    defaultValues?.plansArray,
    id,
    partnerById?.partner,
    plansByCompanyId?.plans,
    plansFeatures,
    reset,
  ]);

  const TABS = useMemo(
    () => [
      {
        value: 'url',
        label: 'URL',
        component: (
          <Box>
            <RHFTextField
              disabled={notAllowed}
              name="url"
              label="URL*"
              inputProps={{
                maxLength: 24,
                style: { textTransform: 'lowercase' },
              }}
            />
            <Typography mt={1.5}>
              {PARTNER_LINK}
              <strong>{values?.url?.toLowerCase().replace(/\s+/g, '')}</strong>
            </Typography>
          </Box>
        ),
      },
      {
        value: 'pricing',
        label: 'Pricing',
        component: (
          <>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <RHFCheckbox
                disabled={notAllowed}
                name="mainPlan"
                checked
                label="Use Master Paid Plans"
              />
              {planArrayFields?.length < plansFeatures?.length &&
              !values?.mainPlan ? (
                <Box display="flex" mb={2} justifyContent="end">
                  <RHFButton
                    startIcon={
                      <Iconify height={20} width={20} icon={'mdi:plus'} />
                    }
                    disabled={notAllowed}
                    variant="text"
                    color="secondary"
                    onClick={addNew}
                    title="Add New"
                  />
                </Box>
              ) : null}
            </Stack>
            {!values?.mainPlan ? (
              <>
                {planArrayFields?.map((item, index) => {
                  const plan = {
                    status: `plansArray[${index}].status`,
                    planName: `plansArray[${index}].planName`,
                    subscriptionType: `plansArray[${index}].subscriptionType`,
                    USPSShippingRates: `plansArray[${index}].shippingRate`,
                    priceInCAD: `plansArray[${index}].priceInCAD`,
                    priceInUSD: `plansArray[${index}].priceInUSD`,
                    stripePlanIdCAD: `plansArray[${index}].stripePlanIdCAD`,
                    stripePlanIdUSD: `plansArray[${index}].stripePlanIdUSD`,
                    enDescription: `plansArray[${index}].enDescription`,
                    frDescription: `plansArray[${index}].frDescription`,
                    percentage: `plansArray[${index}].collectPaymentPercent`,
                    amount: `plansArray[${index}].collectPaymentAmount`,

                    // For international charges
                    intPercentage: `plansArray[${index}].intCollectPaymentPercent`,
                    intAmount: `plansArray[${index}].intCollectPaymentAmount`,
                    webSiteUpTo: `plansArray[${index}].includesWebsiteUpTo`,
                    shippingDiscount: `plansArray[${index}].shippingDiscount`,
                    support: `plansArray[${index}].support`,
                  };
                  return (
                    <Box key={item.id}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                        mt={4}
                        mb={3}
                      >
                        <Typography variant="subtitle1">
                          {`${index + 1}. ${
                            (values?.plansArray?.length &&
                              values?.plansArray[index]?.planName) ||
                            plansFeatures?.[0]?.title
                          }`}
                        </Typography>
                        {planArrayFields.length > 1 && (
                          <IconButton
                            onClick={(e) => removePlan(e, index, item)}
                            color="error"
                            aria-label="delete"
                            size="small"
                          >
                            <Iconify
                              icon={'material-symbols:delete-outline-sharp'}
                            />
                          </IconButton>
                        )}
                      </Stack>
                      <PlanHeader
                        partner={true}
                        plansFeatures={plansFeatures}
                        index={index}
                        plan={plan}
                        setAllPlans={setAllPlans}
                        mainPlan={mainPlan}
                        id={id}
                        allPlans={allPlans}
                        subscriptionsField={
                          planArrayFields[index].subscriptions
                        }
                        onSelectedPlan={(val) => onSelectedPlan(val, index)}
                        hasPaymentsPermission={hasPaymentsPermission}
                        hasShippingPermission={hasShippingPermission}
                        hasWebPermission={hasWebPermission}
                        callBackFn={callBackFn}
                        tabData={tabData}
                        value={values?.plansArray?.[index]?.planName || ''}
                      />
                    </Box>
                  );
                })}
              </>
            ) : null}
          </>
        ),
      },
      {
        value: 'partnerAgents',
        label: 'Partner Agents',
        component: (
          <PartnerAgents
            notAllowed={notAllowed}
            showAdd={!errors?.agentsArray}
            agents={agentById?.partners}
            loading={getAgentLoading}
            setIsEditAgent={setIsEditAgent}
          />
        ),
      },
    ],
    [
      addNew,
      agentById?.partners,
      allPlans,
      callBackFn,
      errors?.agentsArray,
      getAgentLoading,
      hasPaymentsPermission,
      hasShippingPermission,
      hasWebPermission,
      id,
      mainPlan,
      notAllowed,
      onSelectedPlan,
      planArrayFields,
      plansFeatures,
      removePlan,
      tabData,
      values?.mainPlan,
      values?.plansArray,
      values?.url,
    ]
  );

  const [currentTab, setCurrentTab] = useState(TABS[0].value);

  const handleTabChange = useCallback((tab) => {
    setCurrentTab(tab);
  }, []);

  useEffect(() => {
    if (errors?.url) {
      setCurrentTab('url');
    } else if (errors?.plansArray) {
      setCurrentTab('pricing');
    } else if (errors?.agentsArray) {
      setCurrentTab('partnerAgents');
    }
  }, [errors?.agentsArray, errors?.plansArray, errors?.url]);

  const handleBack = useCallback(() => {
    navigate(routes.ADMIN.PARTNERS.fullPath);
  }, [navigate]);

  useEffect(() => {
    if (!(partnerByIdLoading || partnerByIdFetching) && !partnerById && id)
      navigate(routes.ADMIN.PARTNERS.fullPath);
  }, [id, navigate, partnerById, partnerByIdFetching, partnerByIdLoading]);

  return (
    <>
      <Container maxWidth="xxl">
        <Stack direction={'row'} alignItems={'center'} columnGap={1} mb={2}>
          <IconButton sx={{ cursor: 'pointer' }} onClick={handleBack}>
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          <Typography variant="h5">
            {id ? 'Edit Partner' : 'Add Partner'}
          </Typography>
        </Stack>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <PartnerDetails
              notAllowed={notAllowed}
              loader={loader}
              products={products}
              setIsImgError={setIsImgError}
            />

            <Grid item xs={12}>
              <Card>
                <Tabs
                  allowScrollButtonsMobile
                  variant="scrollable"
                  value={currentTab}
                  sx={{ px: 2, bgcolor: 'background.neutral' }}
                >
                  {TABS.map((tab, index) => (
                    <CustomTab
                      disableRipple
                      key={tab.value + index}
                      label={tab.label}
                      icon={tab.icon}
                      value={tab.value}
                      onClick={() => handleTabChange(tab.value)}
                    />
                  ))}
                </Tabs>

                <Box sx={{ padding: 3 }}>
                  {loader ? (
                    <Stack mb={2} gap={2} width={'100%'}>
                      <Skeleton
                        variant="rectangular"
                        width={'100%'}
                        height={55}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={'50%'}
                        height={25}
                      />
                    </Stack>
                  ) : (
                    TABS.map((tab) => {
                      return (
                        tab.value === currentTab && (
                          <Box key={tab.value}>{tab?.component}</Box>
                        )
                      );
                    })
                  )}

                  {notAllowed ? null : (
                    <Stack direction="row" spacing={2} justifyContent="end">
                      {loader ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={35}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={35}
                          />
                        </>
                      ) : (
                        <Stack
                          pt={2}
                          direction="row"
                          alignItems="center"
                          columnGap={1}
                        >
                          <RHFButton
                            variant="contained"
                            type="submit"
                            disabled={isLoading || isEditLoading}
                            title={id ? 'Update' : 'Save'}
                            startIcon={
                              isLoading || isEditLoading ? (
                                <CircularProgress size={15} color="inherit" />
                              ) : null
                            }
                          />
                          <RHFButton
                            color={'inherit'}
                            variant="outlined"
                            disabled={isLoading || isEditLoading}
                            title="Cancel"
                            onClick={() => {
                              navigate(routes.ADMIN.PARTNERS.fullPath);
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </>
  );
};

export default memo(PartnerForm);
