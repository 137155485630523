export const fnCamelCase = (keyName, replaceWith) => {
  if (keyName) {
    return keyName
      ?.toLowerCase()
      ?.split(replaceWith ? replaceWith : ' ')
      ?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      ?.join(' ');
  }
};

export const fnPascalCase = (keyName) => {
  if (keyName) {
    return keyName.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  }
};

export const escapeRegExp = (text) => {
  if (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
};

export const removeOnlySpecialChar = (str) => {
  return str?.replace(/[^a-zA-Z0-9 ]/g, ' ');
};

export const removeSpecialChar = (str) => {
  return fnCamelCase(removeOnlySpecialChar(str));
};

export const isValidEmail = (text) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (text) {
    return emailRegex.test(text);
  }
  return false;
};

export const fullyTrimmedString = (str) => str.replace(/\s/g, '');
