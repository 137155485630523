const addCommonProps = ({ props, bool }) => {
  let commonProps = {
    overflow: bool ? 'hidden' : '',
    textOverflow: bool ? 'ellipsis' : '',
    whiteSpace: 'nowrap',
  };
  if (props && Object.entries(props).length) {
    commonProps = {
      ...commonProps,
      ...props,
    };
  }
  return commonProps;
};

export default addCommonProps;
