import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../../components/RouteLazyLoading';
import { PAYMENTS } from '../../../../routes/paths';

const ProductServices = Loadable(
  lazy(() => import('../products-services/ProductServices'))
);
const ProductForm = Loadable(lazy(() => import('./sections/ProductForm')));

export default function ProductServicesRouter() {
  return useRoutes([
    {
      index: true,
      element: <ProductServices />,
    },
    {
      path: PAYMENTS.ADD_PRODUCT.path,
      element: <ProductForm />,
    },
    {
      path: PAYMENTS.EDIT_PRODUCT.path,
      element: <ProductForm />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
