/**
 * DO NOT MODIFY AND SAVE TO REPO
 * When Modifing this file use internal application processes.
 * This script is used to bootstrap the client application.
 * This script will check the document location to set up
 * the environment it will run based on the API Server
 * requirements.
 * This script is ment to die silently if there is an error,
 * use error handling with in the application to catch errors
 * before they get here.
 */

const AppConfig = {
  debug: true,
  isProd: false,
  showChoosePlanPage: false,
  localServer: process.env.REACT_APP_DEV_SERVER,
  port: 4949,
  service: {
    id: '',
    key: '',
  },
  timeout: 40000,
  stripeKey: process.env.REACT_APP_DEV_STRIPE_KEY,
  paymentSecretKey: process.env.REACT_APP_DEV_PAYMENT_SECRET_KEY,
  railzWidgetId: process.env.REACT_APP_DEV_RAILZ_WIDGET_ID,
  shippingPayment: process.env.REACT_APP_DEV_SHIPPING_PAYMENT,
  paidCartDomain: {
    active: true,
    domain: 'dev.paidcart.com',
    owner: 'PaidCart',
  },
  paidCartIp: process.env.REACT_APP_DEV_PAID_CART_IP,
  basename: '/',
  version: '2.2.4',
  SHIPPING_CODE_QUERY_PARMAS: 'appcode',
};
if (document.location.protocol !== 'https:') {
  AppConfig.host = process.env.REACT_APP_DEV_HTTP_HOST;
  AppConfig.port = process.env.REACT_APP_DEV_HTTP_PORT;
  AppConfig.localServer = process.env.REACT_APP_DEV_SERVER;
} else {
  if (document.location.hostname === 'dev.paid.com') {
    AppConfig.host = process.env.REACT_APP_HOST_DEVELOPMENT;
    AppConfig.port = process.env.REACT_APP_PORT_DEVELOPMENT;
    AppConfig.localServer = process.env.REACT_APP_DEV_SERVER;
  } else if (document.location.hostname === 'dev.paid.com/~') {
    AppConfig.host = process.env.REACT_APP_HOST_FEATURE_DEVELOPMENT;
    AppConfig.port = process.env.REACT_APP_PORT_FEATURE_DEVELOPMENT;
    AppConfig.localServer = process.env.REACT_APP_DEV_SERVER;
  } else if (document.location.hostname === 'staging.paid.com') {
    AppConfig.host = process.env.REACT_APP_HOST_STAGING;
    AppConfig.port = process.env.REACT_APP_PORT_STAGING;
    AppConfig.localServer = process.env.REACT_APP_STAGING_SERVER;
  } else if (document.location.hostname === 'sandbox.paid.com') {
    AppConfig.host = process.env.REACT_APP_HOST_SANDBOX;
    AppConfig.port = process.env.REACT_APP_PORT_SANDBOX;
  } else if (
    document.location.hostname === 'www.app.paid.com' ||
    document.location.hostname === 'app.paid.com'
  ) {
    AppConfig.host = process.env.REACT_APP_HOST_PRODUCTION;
    AppConfig.port = process.env.REACT_APP_PORT_PRODUCTION;
    AppConfig.stripeKey = process.env.REACT_APP_STRIPE_KEY;
    AppConfig.paymentSecretKey = process.env.REACT_APP_PAYMENT_SECRET_KEY;
    AppConfig.shippingPayment = process.env.REACT_APP_SHIPPING_PAYMENT;
    AppConfig.isProd = true;
    AppConfig.paidCartDomain.domain = 'paidcart.com';
    AppConfig.paidCartIp = process.env.REACT_APP_PAID_CART_IP;
  } else {
    AppConfig.host = process.env.REACT_APP_DEV_HTTPS_HOST;
    AppConfig.port = 4900;
    AppConfig.localServer = process.env.REACT_APP_DEV_SERVER;
  }
}
const hostArr = document.location.pathname.split('/');
if (
  Array.isArray(hostArr) &&
  hostArr.length >= 1 &&
  hostArr[1].indexOf('~') !== -1
) {
  AppConfig.basename = `/${hostArr[1]}/${hostArr[2]}`;
}
export default AppConfig;
