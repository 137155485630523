import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import PercentIcon from '@mui/icons-material/Percent';
import {
  Alert,
  Box,
  Card,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import {
  RHFAutoComplete,
  RHFMultiCheckbox,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from '../../../../components/hook-form';
import { fnKeyPressNumber } from '../../../../components/utilities/fnKeyPressNumber';
import { planStatus } from '../../../../constants';

const PlanHeader = ({
  partner = false,
  addPlanDetails,
  subscriptionsField,
  fields,
  plansFeatures,
  plan,
  onSelectedPlan,
  setAllPlans,
  mainPlan,
  id,
  allPlans,
  hasWebPermission,
  hasPaymentsPermission,
  hasShippingPermission,
  notAllowed = false,
  callBackFn,
  callBack,
  index,
  tabData,
  value,
  isEditPlan,
}) => {
  const { setValue, watch } = useFormContext();

  const values = watch();
  const changedPlan = values?.plansArray?.find((_, id) => id === index);

  useEffect(() => {
    if (plansFeatures?.length) {
      if (values?.plansArray?.some((item) => item?.planName)) {
        const planList = plansFeatures?.filter((obj) => {
          const matchedPlan = values?.plansArray?.find((plan) => {
            return plan?.planName === obj?.value;
          });
          return obj?.value !== matchedPlan?.planName;
        });
        setAllPlans(planList);
      } else {
        setAllPlans(plansFeatures);
      }
      if (mainPlan && !id) {
        setValue('plansArray[0].planName', plansFeatures[0]?.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    mainPlan,
    plansFeatures,
    setAllPlans,
    setValue,
    changedPlan?.planName,
  ]);

  // Constants
  const supportArray = useMemo(() => ['Email', 'Chat', 'Phone'], []);
  const shippingRates = useMemo(
    () => [
      {
        title: 'USPS Commercial Base',
        value: 'USPSCommercialBase',
      },
      {
        title: 'USPS Commercial Plus',
        value: 'USPSCommercialPlus',
      },
    ],
    []
  );

  //Local State
  const [currentPlanTab, setCurrentPlanTab] = useState('mo');
  const [currentValue, setCurrentValue] = useState();
  const [searchText, setSearchText] = useState('');

  const plans = values?.plansArray?.map((ele) => {
    return ele?.planName && ele?.planName;
  });

  useEffect(() => {
    if (isEditPlan) {
      const updatedTabData = values?.subscriptions?.find(
        (item) => (item?.priceInCAD || item?.piceInUSD) && item?.type === 'mo'
      );
      const currentTab = updatedTabData ? 'mo' : 'yr';
      setCurrentPlanTab(currentTab);
    }
  }, [setCurrentPlanTab, values?.subscriptions, isEditPlan]);

  const onInputChange = useCallback(
    (inputValue, reason) => {
      setCurrentValue(inputValue);
      const currentPlan = plansFeatures?.find((ele) =>
        plans?.includes(ele?.value)
      );
      if (reason === 'clear') {
        const planName = plans?.find((ele, id) => id === index);
        const removedPlan = plansFeatures?.find(
          (planFeature) => planFeature?.value === planName
        );
        if (allPlans?.every((ele) => ele?.value !== planName))
          setAllPlans([...allPlans, removedPlan]);
      }
      if (!plans?.includes(currentPlan?.value)) {
        setAllPlans([...allPlans, currentPlan]);
      }
    },
    [allPlans, index, plans, plansFeatures, setAllPlans]
  );

  const onChangePlan = useCallback(
    (val) => {
      if (val) {
        const plan = {
          planName: val?.value,
        };
        onSelectedPlan(plan?.planName);
        setSearchText(plan?.planName);
      } else {
        onSelectedPlan('');
        setSearchText('');
      }
    },
    [onSelectedPlan, setSearchText]
  );

  const handleCurrentPlanTabChange = useCallback(
    (e, tab) => {
      setCurrentPlanTab && setCurrentPlanTab(tab);
      callBack && callBack(tab);
      callBackFn && callBackFn(tab, index);
      if (!subscriptionsField?.length) addPlanDetails();
    },
    [
      addPlanDetails,
      callBack,
      callBackFn,
      index,
      setCurrentPlanTab,
      subscriptionsField?.length,
    ]
  );

  const currentPlanTabs = useMemo(
    () => [
      {
        label: 'Monthly',
        value: 'mo',
      },
      {
        label: 'Annually',
        value: 'yr',
      },
    ],
    []
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {partner ? (
            <RHFAutoComplete
              options={allPlans}
              name={plan.planName}
              fullWidth
              value={value}
              notChangeInForm={true}
              label={searchText ? '' : 'Plan Name*'}
              size={'small'}
              freeSolo={true}
              onChange={onChangePlan}
              hideAddOption={true}
              onInputChange={(e, value, reason) => onInputChange(value, reason)}
              isOptionEqualToValue={(option, value) => {
                return option?.['title'] === value;
              }}
              clearOnBlur={true}
              currentValue={currentValue}
              renderOption={(props, option) => {
                return (
                  option?.title && (
                    <Box {...props} key={option?.title}>
                      <Stack
                        direction={'row'}
                        alignItems="flex-end"
                        spacing={0.5}
                      >
                        <Typography>{option?.title}</Typography>
                      </Stack>
                    </Box>
                  )
                );
              }}
            />
          ) : (
            <RHFTextField
              disabled={notAllowed}
              name={plan.planName}
              label={'Plan Name*'}
            />
          )}
        </Grid>
        <Grid item sm={12}>
          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleCurrentPlanTabChange}
              value={tabData?.[index] || currentPlanTab}
              sx={{ bgcolor: 'background.neutral', px: 3 }}
            >
              {currentPlanTabs?.map((tab, index) => {
                return (
                  <Tab
                    disableRipple
                    key={tab.label + index}
                    label={
                      <Typography variant="body2">{tab?.label}</Typography>
                    }
                    value={tab.value}
                  />
                );
              })}
            </Tabs>
            {currentPlanTabs?.map((tab) => {
              const tabIndexValue = tabData?.[index] || 'mo';
              return tab?.value === tabIndexValue ? (
                <Box key={tab?.label}>
                  <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        *You can add either of the plan or both.
                      </Alert>
                    </Grid>
                    {fields?.map((subscription, index) => {
                      return subscription?.type === currentPlanTab ? (
                        <Grid
                          container
                          spacing={2}
                          pt={2}
                          pl={2}
                          key={subscription.id}
                        >
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`subscriptions[${index}].priceInUSD`}
                              label={'Price (USD)*'}
                              inputProps={{ maxLength: 9 }}
                              onKeyPress={fnKeyPressNumber}
                              onPaste={(e) => e.preventDefault()}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setValue(
                                    `subscriptions[${index}].priceInUSD`,
                                    Number(e.target.value),
                                    { shouldValidate: true }
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`subscriptions[${index}].priceInCAD`}
                              label={'Price (CAD)*'}
                              inputProps={{ maxLength: 9 }}
                              onKeyPress={fnKeyPressNumber}
                              onPaste={(e) => e.preventDefault()}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setValue(
                                    `subscriptions[${index}].priceInCAD`,
                                    Number(e.target.value),
                                    { shouldValidate: true }
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`subscriptions[${index}].stripePlanIdUSD`}
                              label={'Stripe Plan Id (USD)*'}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`subscriptions[${index}].stripePlanIdCAD`}
                              label={'Stripe Plan Id (CAD)*'}
                            />
                          </Grid>
                        </Grid>
                      ) : null;
                    })}

                    {subscriptionsField?.map((subscription, id) => {
                      return subscription?.type === tabIndexValue ? (
                        <Grid container spacing={2} pt={2} pl={2} key={id}>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`plansArray[${index}].subscriptions[${id}].priceInUSD`}
                              label={'Price (USD)*'}
                              inputProps={{ maxLength: 9 }}
                              onKeyPress={fnKeyPressNumber}
                              onPaste={(e) => e.preventDefault()}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setValue(
                                    `plansArray[${index}].subscriptions[${id}].priceInUSD`,
                                    Number(e.target.value),
                                    { shouldValidate: true }
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`plansArray[${index}].subscriptions[${id}].priceInCAD`}
                              label={'Price (CAD)*'}
                              inputProps={{ maxLength: 9 }}
                              onKeyPress={fnKeyPressNumber}
                              onPaste={(e) => e.preventDefault()}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  setValue(
                                    `plansArray[${index}].subscriptions[${id}].priceInCAD`,
                                    Number(e.target.value),
                                    { shouldValidate: true }
                                  );
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`plansArray[${index}].subscriptions[${id}].stripePlanIdUSD`}
                              label={'Stripe Plan Id (USD)*'}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <RHFTextField
                              disabled={notAllowed}
                              name={`plansArray[${index}].subscriptions[${id}].stripePlanIdCAD`}
                              label={'Stripe Plan Id (CAD)*'}
                            />
                          </Grid>
                        </Grid>
                      ) : null;
                    })}
                  </Grid>
                </Box>
              ) : null;
            })}
          </Card>
        </Grid>

        <Grid item sm={6} xs={12}>
          <RHFTextField
            disabled={notAllowed}
            name={plan.enDescription}
            placeholder={'English Description'}
            multiline
            rows={5}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <RHFTextField
            disabled={notAllowed}
            name={plan.frDescription}
            placeholder={'French Description'}
            multiline
            rows={5}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField
                name={plan.webSiteUpTo}
                label={`Includes Website UpTo ${
                  notAllowed || (partner && !hasWebPermission) ? '' : '*'
                }`}
                placeholder={'3'}
                onKeyPress={fnKeyPressNumber}
                disabled={notAllowed || (partner && !hasWebPermission)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={3} xs={6}>
          <RHFTextField
            name={plan.shippingDiscount}
            label={'Shipping Discount*'}
            onKeyPress={fnKeyPressNumber}
            disabled={notAllowed || (partner && !hasShippingPermission)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFSelect
            disabled={notAllowed}
            name={plan.USPSShippingRates}
            label={'USPS Shipping Rates'}
            options={shippingRates}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography>Status: </Typography>
            <RHFRadioGroup
              name={plan.status}
              options={planStatus}
              directionStyle={{
                direction: 'row',
                alignItems: 'center',
                columnGap: 1,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography>Support:</Typography>
            <RHFMultiCheckbox
              disabled={notAllowed}
              options={supportArray}
              name={plan.support}
              isRow={true}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ bgcolor: 'background.neutral', p: 1.5 }}
                >
                  <Typography variant="body2">
                    Collect Payments For Normal Charges
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" columnGap={2} p={2} pt={0}>
                  <RHFTextField
                    size="small"
                    name={plan.percentage}
                    label={`Percentage${
                      notAllowed || (partner && !hasPaymentsPermission)
                        ? ''
                        : '*'
                    }`}
                    placeholder={'3'}
                    onKeyPress={fnKeyPressNumber}
                    disabled={notAllowed || (partner && !hasPaymentsPermission)}
                    icon={<PercentIcon sx={{ borderRadius: 0 }} />}
                  />
                  <Typography>+</Typography>
                  <RHFTextField
                    size="small"
                    name={plan.amount}
                    label="Amount"
                    placeholder={'15'}
                    onKeyPress={fnKeyPressNumber}
                    disabled={notAllowed || (partner && !hasPaymentsPermission)}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ bgcolor: 'background.neutral', p: 1.5 }}
                >
                  <Typography variant="body2">
                    Collect Payments For International Charges / American Visa
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" columnGap={2} p={2} pt={0}>
                  <RHFTextField
                    size="small"
                    name={plan.intPercentage}
                    label={`Percentage${
                      notAllowed || (partner && !hasPaymentsPermission)
                        ? ''
                        : '*'
                    }`}
                    placeholder={'3'}
                    onKeyPress={fnKeyPressNumber}
                    disabled={notAllowed || (partner && !hasPaymentsPermission)}
                    icon={<PercentIcon sx={{ borderRadius: 0 }} />}
                  />
                  <Typography>+</Typography>
                  <RHFTextField
                    size="small"
                    name={plan.intAmount}
                    label="Amount"
                    placeholder={'15'}
                    onKeyPress={fnKeyPressNumber}
                    disabled={notAllowed || (partner && !hasPaymentsPermission)}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

PlanHeader.propTypes = {
  addPlanDetails: PropTypes.func,
  allPlans: PropTypes.any,
  callBack: PropTypes.func,
  callBackFn: PropTypes.func,
  fields: PropTypes.any,
  hasPaymentsPermission: PropTypes.any,
  hasShippingPermission: PropTypes.any,
  hasWebPermission: PropTypes.any,
  id: PropTypes.any,
  index: PropTypes.any,
  mainPlan: PropTypes.any,
  notAllowed: PropTypes.bool,
  onSelectedPlan: PropTypes.any,
  partner: PropTypes.bool,
  plan: PropTypes.any,
  planType: PropTypes.any,
  plansFeatures: PropTypes.array,
  setAllPlans: PropTypes.func,
  subscriptionsField: PropTypes.any,
  tabData: PropTypes.any,
  value: PropTypes.any,
  isEditPlan: PropTypes.any,
};

export default memo(PlanHeader);
