import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import emptyContent from '../assets/empty_content.svg';
import Image from './Image';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

EmptyContent.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  titleStyle: PropTypes.any,
  description: PropTypes.any,
};

export default function EmptyContent({
  title,
  titleStyle,
  description,
  img,
  ...other
}) {
  return (
    <RootStyle {...other}>
      <Image
        disabledEffect
        visibleByDefault
        alt="empty content"
        src={img || emptyContent}
        sx={{ height: 240, mb: 3 }}
      />

      <Typography sx={{ ...titleStyle }} variant="subtitle1" gutterBottom>
        {title}
      </Typography>

      {description && description}
    </RootStyle>
  );
}
