import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import AppConfig from '../app-config';
import { errorHandler } from '../utils/errorHandler';

let apiHeaders = {};

export const getAPIHeaders = (auth, resetHeaders) => {
  apiHeaders = !resetHeaders ? Object.assign({}, apiHeaders, auth) : {};
};

const baseQuery = fetchBaseQuery({
  baseUrl: `https://${AppConfig.host}:${AppConfig.port}`,
  prepareHeaders: (headers, { getState }) => {
    if (apiHeaders?.serviceId && apiHeaders?.key) {
      headers.set('X-Paid-Service-Id', apiHeaders?.serviceId);
      headers.set('X-Paid-Service-Key', apiHeaders?.key);
    }
    if (
      apiHeaders?.email &&
      apiHeaders?.token &&
      apiHeaders?.callerId &&
      apiHeaders?.sessionKey
    ) {
      headers.set('X-Paid-User-Username', apiHeaders.email);
      headers.set('X-Paid-User-Token', apiHeaders.token);
      headers.set('X-Paid-User-Session-Id', apiHeaders.callerId);
      headers.set('X-Paid-User-Session-Key', apiHeaders.sessionKey);
    }
    if (apiHeaders?.superUserToken) {
      headers.set('X-Paid-Super-User-Token', apiHeaders.superUserToken);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (
    apiHeaders?.serviceId &&
    apiHeaders?.key &&
    api.endpoint === 'getPrimaryServiceKey'
  ) {
    return { data: '' };
  }

  // TODO : Remove the key isHideToaster in the body/params
  // the key 'isHideToaster' is provided unnecessarily in the body/params which has no use in the backend

  const result = await baseQuery(args, api, extraOptions);
  errorHandler(
    result.error,
    !(args?.body?.isHideToaster || args?.params?.isHideToaster),
    result?.meta?.request?.url
  );
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['auth'],
  endpoints: () => ({}),
});
