import { FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';

import UploadAvatar from '../upload/UploadAvatar';
import UploadSingleFile from './../upload/UploadSingleFile';
RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
  setImageErrorMsg: PropTypes.func,
  imageErrorMsg: PropTypes.array,
};

export function RHFUploadAvatar({
  name,
  setImageErrorMsg,
  imageErrorMsg,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar
              error={checkError}
              {...other}
              file={field.value}
              setImageErrorMsg={setImageErrorMsg}
              imageErrorMsg={imageErrorMsg}
            />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

RHFUploadSingleFile.propTypes = {
  errorMsg: PropTypes.any,
  imageErrorMsg: PropTypes.any,
  name: PropTypes.string,
  onDeleteHandler: PropTypes.any,
};

export function RHFUploadSingleFile({
  name,
  errorMsg,
  onDeleteHandler,
  imageErrorMsg,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        return (
          <UploadSingleFile
            imageErrorMsg={imageErrorMsg}
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            onRemove={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onDeleteHandler && onDeleteHandler();
              field.onChange('');
            }}
            errorMsg={errorMsg}
            name={name}
            {...other}
          />
        );
      }}
    />
  );
}
