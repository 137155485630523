import { memo, useCallback, useState } from 'react';

import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import DoneIcon from '@mui/icons-material/Done';
import { Grid, Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { copyTextToClipboard } from '../utils/fnCopyToClipboard';
import RHFButton from './button/RHFButton';
const CopyToClipBoard = ({
  copyMeText,
  fieldName,
  field,
  hidePageName,
  isCheckoutPage,
  textVariant = undefined,
}) => {
  // Local state
  const [copySuccess, setCopySuccess] = useState('');
  const [t] = useTranslation(['payments/checkouts']);

  // For copy to clipboard
  const copyToClipBoard = useCallback(async (copyMe, fieldName) => {
    if (copyMe) {
      try {
        copyTextToClipboard(copyMe);
        setCopySuccess({ fieldName: fieldName, text: 'copied' });
      } catch (err) {
        setCopySuccess('Failed to copy!');
      }
    }
  }, []);

  // Functions
  const clearCopyMsg = useCallback(() => {
    setTimeout(() => {
      setCopySuccess({ fieldName: '', text: 'copied' });
    }, 3000);
  }, []);

  return (
    <Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={hidePageName === false && 'center'}
      >
        {fieldName ? (
          !isCheckoutPage && copySuccess?.fieldName === field ? (
            <Stack direction={'row'} alignItems="center" gap={0.5}>
              {clearCopyMsg()}
              <DoneIcon
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              />

              <Typography color="primary">
                {hidePageName === false ? '' : copySuccess?.text}
              </Typography>
            </Stack>
          ) : isCheckoutPage ? (
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: { xs: 'flex-start', sm: 'center' },
              }}
              px={{ xs: 1, sm: 3 }}
              pt={5}
              width={'100%'}
              rowGap={{ xs: 1 }}
            >
              <Grid item xs={12} sm={8}>
                <Stack
                  flexWrap={'wrap'}
                  direction={{ xs: 'column', sm: 'row' }}
                >
                  <Typography variant={textVariant || 'body2'}>
                    {hidePageName === false
                      ? ''
                      : copyMeText
                      ? copyMeText
                      : '-'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent={'flex-end'}
                >
                  <RHFButton
                    title={
                      copySuccess?.fieldName === field
                        ? copySuccess?.text
                        : t('copyToClipboard')
                    }
                    variant={'contained'}
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipBoard(copyMeText, fieldName);
                      clearCopyMsg();
                    }}
                    customStyle={{
                      px: { xs: 0.5, sm: 2 },
                      width: {
                        xs: '100%',
                        md: '90%',
                        lg: '65%',
                        xl: '55%',
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Link
              underline="none"
              disabled
              gap={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'row',
                color: (theme) => theme.palette.secondary.main,
              }}
              onClick={(e) => {
                e.stopPropagation();
                copyToClipBoard(copyMeText, fieldName);
              }}
            >
              <ContentCopyTwoToneIcon
                fontSize="small"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              />
              <Typography variant={textVariant || 'body2'}>
                {hidePageName === false ? '' : copyMeText ? copyMeText : '-'}
              </Typography>
            </Link>
          )
        ) : (
          '-'
        )}
      </Stack>
    </Stack>
  );
};

CopyToClipBoard.propTypes = {
  copyMeText: PropTypes.string,
  field: PropTypes.string,
  fieldName: PropTypes.string,
  hidePageName: PropTypes.bool,
  isCheckoutPage: PropTypes.bool,
  textVariant: PropTypes.string,
};

export default memo(CopyToClipBoard);
