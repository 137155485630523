import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import RHFButton from '../../../components/button/RHFButton';
import customDayjs from '../../../components/customDayjs';
import ElementsTooltip from '../../../components/ElementsTooltip';
import { RHFRadioGroup, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import TableWrapper from '../../../components/table-wrapper/TableWrapper';
import { fnKeyPressNumber } from '../../../components/utilities/fnKeyPressNumber';
import { statusList } from '../../../constants';
import { useEditTeamMemberMutation } from '../../../redux/team/teamAction';
import trimmedString from '../../../utils/trimmedString';

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {},
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const PartnerAgents = ({
  agents,
  loading,
  showAdd = true,
  notAllowed = false,
  setIsEditAgent,
}) => {
  // Form set up
  const methods = useFormContext({});
  const {
    control,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = methods;
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'agentsArray',
  });
  const values = watch();

  // API calls
  const [editTeamMember, { isLoading }] = useEditTeamMemberMutation();

  // Local State
  const [showAddAgentView, setShowAddAgentView] = useState(
    fields?.length > 1 ? false : true
  );
  const [editAgent, setEditAgent] = useState(false);
  const [rowData, setRowData] = useState({});
  const [expanded, setExpanded] = useState({});

  // Functions
  const addNew = useCallback(() => {
    setExpanded((prevVal) => ({
      [`agent${fields.length}`]: true,
    }));
    const stringForUID =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz2345678901';
    append({
      id: stringForUID.charAt(Math.floor(Math.random() * stringForUID.length)),
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      agentStatus: 'active',
    });
  }, [append, fields.length]);

  useEffect(() => {
    if (errors?.agentsArray) {
      errors?.agentsArray?.map(
        (field, index) =>
          field !== undefined &&
          setExpanded((prevVal) => ({
            ...prevVal,
            [`agent${index}`]: true,
          }))
      );
    }
  }, [errors?.agentsArray, errors.priceItem]);

  const toggleAgentView = useCallback(() => {
    setShowAddAgentView(!showAddAgentView);
    setEditAgent(false);
    setIsEditAgent(false);
    if (!showAddAgentView) {
      remove();
      resetField('agentsArray', []);
    } else {
      if (
        !values?.agentsArray?.length ||
        (showAddAgentView && !editAgent && !!agents?.length)
      ) {
        remove();
      }
      addNew();
    }
  }, [
    addNew,
    agents?.length,
    editAgent,
    remove,
    resetField,
    setIsEditAgent,
    showAddAgentView,
    values?.agentsArray?.length,
  ]);

  const editAgentFunc = useCallback(
    async (values) => {
      const user = { ...values.agentsArray[0] };
      const rowStatus = rowData?.status?.toLowerCase();
      user.first_name = trimmedString(user?.first_name);
      user.last_name = trimmedString(user?.last_name);
      if (rowData.email === user.email) {
        delete user.email;
      }
      user.isPartner = true;
      user.status =
        rowStatus === 'not_verified' ? rowStatus : user?.agentStatus;
      const res = await editTeamMember({
        id: rowData?._id,
        user,
      });
      if (res?.data?.status === 'success') {
        enqueueSnackbar('Agent updated successfully', {
          variant: 'success',
        });
        toggleAgentView();
      }
      setIsEditAgent(false);
    },
    [
      editTeamMember,
      rowData?._id,
      rowData.email,
      rowData?.status,
      setIsEditAgent,
      toggleAgentView,
    ]
  );

  const removeAgent = useCallback(
    (e, index, item) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      remove(index);
    },
    [remove]
  );

  // Function - inside a function
  const handleChangeRow = (panel) => (event, isExpanded) => {
    setExpanded((prevVal) => ({ ...prevVal, [panel]: isExpanded }));
  };

  useEffect(() => {
    if (!agents?.length && fields?.length === 1) {
      setShowAddAgentView(true);
    }
  }, [agents?.length, fields?.length]);

  const tHeadArray = [
    {
      field: 'First Name',
      headerName: 'first_name',
      sort: true,
      render: (row) => (
        <ElementsTooltip
          upperElement={row?.first_name || '-'}
          maxLengthOfUpperElement={35}
        />
      ),
    },
    {
      field: 'Last Name',
      headerName: 'last_name',
      sort: true,
      render: (row) => (
        <ElementsTooltip
          upperElement={row?.last_name || '-'}
          maxLengthOfUpperElement={35}
        />
      ),
    },
    {
      field: 'Email',
      headerName: 'email',
      sort: true,
      render: (row) => (
        <ElementsTooltip
          upperElement={row?.email || '-'}
          maxLengthOfUpperElement={35}
        />
      ),
    },
    {
      field: 'Phone',
      headerName: 'phone',
      sort: true,
      render: (row) => row?.phone,
    },
    {
      field: 'Status',
      headerName: 'status',
      sort: true,
      render: (row) => row?.status?.toUpperCase(),
    },
    {
      field: 'Creation Date',
      headerName: 'created',
      sort: true,
      render: (row) =>
        row?.created ? customDayjs(row?.created).format('DD, MMM YYYY') : '-',
    },
    {
      field: 'Last Login Date',
      headerName: 'lastLogin',
      sort: true,
      render: (row) =>
        row?.lastLogin
          ? customDayjs(row?.lastLogin).format('DD, MMM YYYY')
          : '-',
    },
  ];
  return (
    <>
      {notAllowed ? null : (
        <Stack alignItems="end" pb={2}>
          {loading ? (
            <Skeleton variant="rectangular" width={200} height={50} />
          ) : (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <RHFButton
                color={showAddAgentView ? 'secondary' : 'inherit'}
                startIcon={
                  showAddAgentView ? (
                    <Iconify height={20} width={20} icon={'mdi:plus'} />
                  ) : undefined
                }
                variant="text"
                onClick={toggleAgentView}
                title={showAddAgentView ? 'Add New' : 'Cancel'}
              />
              {!editAgent && !showAddAgentView ? (
                <RHFButton
                  startIcon={<AddIcon />}
                  variant="text"
                  onClick={addNew}
                  color={'secondary'}
                  title="Add New"
                />
              ) : null}
            </Stack>
          )}
        </Stack>
      )}
      {showAddAgentView && !editAgent && !!agents?.length ? (
        <Scrollbar>
          <TableWrapper
            onRowClick={
              notAllowed
                ? undefined
                : (row) => {
                    setEditAgent(true);
                    setIsEditAgent(true);
                    setShowAddAgentView(false);
                    setRowData(row);
                    const data = {
                      first_name: row?.first_name,
                      last_name: row?.last_name,
                      email: row?.email,
                      phone: row?.phone,
                      agentStatus: row?.status?.toLowerCase(),
                    };
                    if (fields.length <= 0) {
                      append(data);
                    } else {
                      resetField('agentsArray', []);
                      update(0, data);
                    }
                    if (row?.status?.toLowerCase() === 'not_verified') {
                      setValue('agentsArray[0].agentStatus', 'inactive');
                    }
                  }
            }
            isLoading={loading}
            rowsData={agents}
            columns={tHeadArray}
            rowKey={'_id'}
          />
        </Scrollbar>
      ) : (
        <Stack mb={2} gap={2}>
          {fields?.map((item, index) => (
            <Accordion
              key={item.id}
              sx={{ mb: 2 }}
              expanded={
                expanded[`agent${index}`]
                  ? expanded[`agent${index}`]
                  : fields.length === 1 || false
              }
              onChange={handleChangeRow(`agent${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width={1}
                >
                  <Typography>Agent {index + 1}</Typography>
                  {fields.length > 1 && (
                    <IconButton
                      onClick={(e) => removeAgent(e, index, item)}
                      color="error"
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container mt={1} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <RHFTextField
                      name={`agentsArray[${index}].first_name`}
                      label="First Name*"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RHFTextField
                      name={`agentsArray[${index}].last_name`}
                      label="Last Name*"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RHFTextField
                      name={`agentsArray[${index}].email`}
                      label="Email*"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RHFTextField
                      name={`agentsArray[${index}].phone`}
                      label="Phone*"
                      inputProps={{ maxLength: 10 }}
                      onKeyPress={(e) => fnKeyPressNumber(e, false)}
                    />
                  </Grid>
                  {editAgent && !!agents?.length ? (
                    <Grid
                      item
                      xs={12}
                      lg={5}
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <Typography>User Status:</Typography>
                      <RHFRadioGroup
                        valueKey="value"
                        getOptionLabel={(option) => (
                          <Typography variant="body1">
                            {option.title}
                          </Typography>
                        )}
                        disabled={
                          item?.agentStatus?.toLowerCase() === 'inactive'
                        }
                        directionStyle={{
                          direction: 'row',
                          alignItems: 'center',
                        }}
                        options={statusList}
                        name={`agentsArray[${index}].agentStatus`}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          {editAgent && !!agents?.length ? (
            <>
              <Box display="flex" justifyContent="end">
                <RHFButton
                  variant="contained"
                  onClick={() => {
                    if (!errors?.agentsArray?.length) {
                      editAgentFunc(values);
                    }
                  }}
                  title="Save"
                  disabled={isLoading}
                  startIcon={
                    isLoading ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : null
                  }
                />
              </Box>
            </>
          ) : null}
        </Stack>
      )}
    </>
  );
};

PartnerAgents.propTypes = {
  agents: PropTypes.any,
  loading: PropTypes.bool,
  notAllowed: PropTypes.bool,
  setIsEditAgent: PropTypes.func,
  showAdd: PropTypes.bool,
};

export default PartnerAgents;
