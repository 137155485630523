import { createSlice } from '@reduxjs/toolkit';

import { cartTypes } from '../../constants';
import { _MEMBER_ROLES } from '../../constants/roles';
import { fnCamelCase, fnPascalCase } from '../../utils/formatText';
import { authApi } from '../auth/authAction';
import { extendedApi } from '../integration/integrationActions';
import { planApi } from '../plan/planAction';
import { setupApi } from './setupAction';

const cartTypeText = 'ecwid';
const initialState = {
  pagesPermission: {},
  profile: {
    language: 'En',
    first_name: null,
    last_name: null,
    email: null,
    created: null,
    lastUpdate: null,
    setupComplete: false,
    currentPlan: {},
    cartType: cartTypeText,
    partner: {},
    importInfo: {
      isValidating: false,
      isImporting: false,
      fileInfo: '',
      progress: 0,
    },
    progressQueue: {
      isSyncing: false,
    },
  },
  payments: {
    stripeData: null,
    stripeDetails: null,
    companyDetails: {},
  },
  sidebar: {},
};

const setupSlice = createSlice({
  name: 'setup',
  initialState,
  reducers: {
    socketPaymentSetupState: (state, action) => {
      let customData = JSON.parse(JSON.stringify(state));
      switch (action?.payload?.type) {
        case 'updateCurrency':
          if (
            customData?.payments?.stripeDetails?.accountSettings
              ?.generalSettings?.defaultCurrency
          ) {
            customData.payments.stripeDetails.accountSettings.generalSettings.defaultCurrency =
              action?.payload?.currency;
          }
          if (
            customData?.payments?.stripeDetails?.accountSettings
              ?.generalSettings?.defaultTerms
          ) {
            customData.payments.stripeDetails.accountSettings.generalSettings.defaultTerms =
              action?.payload?.term;
          }
          state.payments = customData?.payments;
          return;

        case 'updatePayout': {
          const payoutSchedule =
            customData?.payments?.stripeDetails?.account?.settings?.payouts
              ?.schedule;
          if (payoutSchedule?.interval) {
            payoutSchedule.interval = action?.payload?.schedule?.interval;
            if (!action?.payload?.schedule?.isManualPayout) {
              if (action?.payload?.schedule?.interval === 'weekly') {
                payoutSchedule.weekly_anchor =
                  action?.payload?.schedule?.dayOfWeek;
              } else {
                payoutSchedule.monthly_anchor =
                  action?.payload?.schedule?.dateOfMonth;
              }
            }
          }
          state.payments = customData?.payments;
          return;
        }
        case 'updateProfileName':
          customData.profile.first_name = action?.payload?.first_name;
          customData.profile.last_name = action?.payload?.last_name;
          state.profile = customData?.profile;
          return;

        default:
          if (action?.payload) {
            const data = action.payload;
            const scenario = setNotificationsScenarios(data);
            state.payments = Object.assign(
              {},
              {
                stripeData: data?.stripeData,
                stripeDetails: { ...data?.stripeDetails, scenario },
              }
            );
            if (
              data?.profile?._id &&
              data?.profile?.paymentMethod?.toLowerCase() === 'firstdata'
            ) {
              state.payments = {
                ...state.payments,
                paymentsOption: 'firstdata',
              };
            } else {
              state.payments = { ...state.payments, paymentsOption: 'stripe' };
            }
          }
      }
    },
    socketShippingSetupState: (state, action) => {
      if (action.payload) {
        const data = action.payload;
        delete data?.type;
        state.shipping = { ...state.shipping, ...data };
      }
    },
    SocketNotificationSetupState: (state, action) => {
      if (action.payload) {
        state.notifications = action.payload;
      }
    },
    resetSetupState: () => initialState,
    socketUpdateAdminSetting: (state, action) => {
      if (action.payload) {
        const data = action.payload;
        state.settings = { ...state.settings, ...data };
      }
    },
    updateCompanyDetails: (state, action) => {
      if (action.payload) {
        const data = action.payload;
        state.profile = { ...state.profile, ...data };
      }
    },
    updateSideBarStatus: (state, action) => {
      if (action.payload) {
        const data = action.payload;
        state.sidebar = { ...state.sidebar, ...data };
      }
    },
    partnerUpdate: (state, action) => {
      const data = action.payload;
      state.profile.cartType = data?.cartType ? data?.cartType : cartTypeText;
      state.profile.partner = data;
      state.pagesPermission = setUsersPermissions(state);
    },
    webUpdate: (state, action) => {
      state.web = { ...action?.payload };
    },
    updateImportInfo: (state, { payload }) => {
      const importInfo = state.profile.importInfo;
      state.profile.importInfo = { ...importInfo, ...payload };
    },
    updateSyncingInfo: (state, { payload }) => {
      const progressQueue = state.profile.progressQueue;
      state.profile.progressQueue = { ...progressQueue };
      if (payload?.processName) {
        // Directly replace the object in state
        state.profile.progressQueue[payload.processName] =
          payload?.[payload?.processName];
      } else {
        // Replace progress queue with empty object after process completed.
        state.profile.progressQueue = {
          ...state.profile.progressQueue,
          ...payload,
        };
      }
    },
    //sync obj
    updateRemainingSyncObj: (state, { payload }) => {
      state.accountIntegration.remainingTransactionsToSync = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      setupApi.endpoints.setup.matchFulfilled,
      (state, action) => {
        const data = action.payload;
        const currentRole = data?.profile?.role?.role;
        const isMemberRole = _MEMBER_ROLES.some((val) => val === currentRole);
        state = Object.assign({}, data, {
          ...(isMemberRole && { pagesPermission: setUsersPermissions(data) }),
          profile: { ...state.profile, ...data?.profile },
        });
        if (isMemberRole) {
          state.profile.cartType = state?.profile?.partner?.cartType
            ? state?.profile.partner?.cartType
            : cartTypeText;
          const scenario = setNotificationsScenarios(data?.payments);
          state.payments = Object.assign(
            {},
            {
              stripeData: data?.payments?.stripeData,
              stripeDetails: { ...data.payments?.stripeDetails, scenario },
            }
          );
          if (
            data?.profile?._id &&
            data?.profile?.paymentMethod?.toLowerCase() === 'firstdata'
          ) {
            state.payments = { ...state.payments, paymentsOption: 'firstdata' };
          } else {
            state.payments = { ...state.payments, paymentsOption: 'stripe' };
          }
        }
        return state;
      }
    );

    builder.addMatcher(
      extendedApi.endpoints.getSingleBusiness.matchFulfilled,

      (state, action) => {
        const accountIntegration = { ...state.accountIntegration };
        accountIntegration.business = action.payload.data;
        state.accountIntegration = accountIntegration;
      }
    );
    builder.addMatcher(
      authApi.endpoints.partnerLogoByUrl.matchFulfilled,
      (state, action) => {
        if (action.payload?.partnerName && action.payload?.cartType) {
          state.profile = {
            ...state.profile,
            cartType: action.payload.cartType,
          };
        }
      }
    );

    builder.addMatcher(
      planApi.endpoints.changePlan.matchFulfilled,
      (state, action) => {
        if (action.payload?.status === 'success') {
          const profileDetails = { ...state.profile.companyDetails };
          profileDetails.currentPlan = action.payload.currentPlan;
          state.profile.companyDetails = profileDetails;
          const obj = Object.assign({}, state, {
            pagesPermission: setUsersPermissions(state),
            profile: state.profile,
          });
          state.pagesPermission = obj.pagesPermission;
        }
      }
    );
  },
});

export const {
  socketPaymentSetupState,
  socketUpdateAdminSetting,
  socketShippingSetupState,
  resetSetupState,
  updateCompanyDetails,
  updateSideBarStatus,
  partnerUpdate,
  SocketNotificationSetupState,
  webUpdate,
  updateImportInfo,
  updateSyncingInfo,
  updateRemainingSyncObj,
} = setupSlice.actions;

export default setupSlice.reducer;

const setUsersPermissions = (value) => {
  const pagesPermission = {};
  const plan = value?.profile?.companyDetails?.currentPlan?.plan;
  const partner = value?.profile?.partner;
  const permission = ['cart', 'payments', 'shipping', 'web'];

  if (partner && partner?.productsAvailable?.length) {
    const partnerPermission = value?.profile?.partner?.productsAvailable;

    partnerPermission.forEach((obj) => {
      const planName =
        cartTypes?.includes(partner?.cartType) && obj?.value === 'web+cart'
          ? 'web'
          : obj?.value;
      if (
        permission.some((val) => {
          return val === planName;
        })
      ) {
        pagesPermission[`has${fnPascalCase(planName)}Permission`] = !!(
          plan && plan[planName]?.length
        );
      }
    });
  } else {
    permission.forEach((val) => {
      pagesPermission[`has${fnCamelCase(val)}Permission`] = !!(
        plan &&
        plan[val]?.length &&
        !(
          plan?.[val]?.length === 1 &&
          plan?.[val]?.find((service) => service.en === 'Number of Products')
        )
      );
    });
  }
  return pagesPermission;
};

const setNotificationsScenarios = (payments) => {
  const scenario = {};
  const stripeDetails = payments?.stripeDetails;
  if (stripeDetails !== null) {
    if (
      !stripeDetails?.isVerified &&
      !stripeDetails?.chargesEnabled &&
      !stripeDetails?.payoutsEnabled
    ) {
      scenario.fifth = true;
    } else if (
      stripeDetails?.accountStatus === 'restricted_soon' ||
      !stripeDetails?.isVerified
    ) {
      scenario.first = true;
    } else if (
      stripeDetails?.isVerified &&
      !stripeDetails?.chargesEnabled &&
      !stripeDetails?.payoutsEnabled
    ) {
      scenario.second = true;
    } else if (
      stripeDetails?.isVerified &&
      stripeDetails?.chargesEnabled &&
      !stripeDetails?.payoutsEnabled
    ) {
      scenario.third = true;
    } else if (
      stripeDetails?.isVerified &&
      stripeDetails?.chargesEnabled &&
      stripeDetails?.payoutsEnabled
    ) {
      scenario.forth = true;
    }
  }
  return scenario;
};
