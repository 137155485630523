import { useEffect, useRef } from 'react';

import { Box } from '@mui/material';
import RailzConnection from '@railzai/railz-connect';
import { useSelector } from 'react-redux';

import AppConfig from '../../../../app-config';
import * as routes from '../../../../routes/paths';

const RailzConnect = () => {
  const ref = useRef();

  const companyId = useSelector(
    (state) => state?.setup?.profile?.companyDetails?._id
  );

  const widgetId = AppConfig?.railzWidgetId;

  useEffect(() => {
    const widget = new RailzConnection();
    widget.mount({
      parentElement: ref.current,
      widgetId: widgetId,
      businessName: companyId,
      closeEnabled: true,
      closeRedirectUrl: routes.PAYMENTS.INTEGRATION.fullPath,
      redirectUrl: `${routes.PAYMENTS.INTEGRATION.fullPath}?success=true`,
      serviceFilterEnabled: false,
    });
  }, [companyId, widgetId]);

  return <Box ref={ref}></Box>;
};

export default RailzConnect;
