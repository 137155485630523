import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

SettingsBasedGuard.propTypes = {
  children: PropTypes.node,
  serviceType: PropTypes.string,
};

export default function SettingsBasedGuard({ children, serviceType }) {
  const initialValues = useSelector((state) => state?.setup?.settings);
  if (initialValues[serviceType]) {
    return <Navigate to={'/payments/dashboard'} replace />;
  }

  return <>{children}</>;
}
