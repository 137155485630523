export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          whiteSpace: 'noWrap',
          paddingLeft: theme.spacing(3),
          padding: '14px',
        },
        head: {
          color: theme.palette.grey[500],
          backgroundColor: theme.palette.background.neutral,
          paddingLeft: theme.spacing(3),
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          paddingLeft: theme.spacing(3),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'flex-start',
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          mb: 10,
        },
      },
    },
  };
}
