import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

const RHFCheckbox = ({ name, disabled = false, ...other }) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              disabled={disabled}
              {...field}
              checked={Boolean(field?.value)}
            />
          )}
        />
      }
      {...other}
    />
  );
};

RHFCheckbox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
const RHFMultiCheckbox = ({ name, options, isRow, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) => {
          if (field?.value) {
            return field?.value?.includes(option)
              ? field?.value?.filter((value) => value !== option)
              : [...field.value, option];
          } else return [option];
        };

        return (
          <FormGroup row={isRow}>
            {options.map((option, index) => (
              <FormControlLabel
                key={option + index}
                control={
                  <Checkbox
                    checked={field?.value?.includes(option) || false}
                    onChange={() => field?.onChange(onSelected(option))}
                  />
                }
                label={option}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
};

RHFMultiCheckbox.propTypes = {
  isRow: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export { RHFCheckbox, RHFMultiCheckbox };
