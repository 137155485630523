import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';

api.enhanceEndpoints({
  addTagTypes: ['Partners', 'PartnerAgent', 'getPartners', 'getPartnerById'],
});

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPartners: build.query({
      providesTags: ['Partners', 'getPartners'],
      query: (params) => {
        return {
          url: `${apiRoutes.ADMIN.endpoints.partners.url}`,
          method: apiRoutes.ADMIN.endpoints.partners.method,
          params,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),

    getPartnerById: build.query({
      providesTags: ['Partners', 'getPartnerById'],
      query: (params) => {
        const { partnerId } = params;
        return {
          url: `${apiRoutes.ADMIN.endpoints.partners.url}${
            partnerId ? `/${partnerId}` : ''
          }`,
          method: apiRoutes.ADMIN.endpoints.partners.method,
          params: {},
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),

    getAgentById: build.query({
      providesTags: ['PartnerAgent'],
      query: (id) => {
        return {
          url: `${apiRoutes.ADMIN.endpoints.getAgents.url}/${id}`,
          method: apiRoutes.ADMIN.endpoints.getAgents.method,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),

    addPartner: build.mutation({
      invalidatesTags: (res) => (res ? ['Partners'] : []),
      query: (body) => {
        return {
          url: apiRoutes.ADMIN.endpoints.addPartner.url,
          method: apiRoutes.ADMIN.endpoints.addPartner.method,
          body,
        };
      },
      transformResponse: (res) => {
        return res;
      },
    }),

    updatePartner: build.mutation({
      invalidatesTags: ['PartnerAgent', 'getPartners', 'getPartnerById'],
      query: (params) => {
        const { id, data } = params;
        return {
          url: `${apiRoutes.ADMIN.endpoints.updatePartner.url}/${id}`,
          method: apiRoutes.ADMIN.endpoints.updatePartner.method,
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnerByIdQuery,
  useGetAgentByIdQuery,
  useAddPartnerMutation,
  useUpdatePartnerMutation,
} = extendedApi;

export const {
  endpoints: { getPartners, addPartner, updatePartner },
} = extendedApi;
