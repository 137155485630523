import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';
import useAuth from '../../hooks/useAuth';
import { UNAUTHORIZE_PATH } from '../paths';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={UNAUTHORIZE_PATH.SIGN_IN.path} />;
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
