import { forwardRef } from 'react';

import { Skeleton, Stack, TableCell, TableRow } from '@mui/material';

const TableSkeleton = forwardRef((_props, ref) => {
  const { tableCell, isImage, ...other } = _props;
  return (
    <TableRow {...other} ref={ref}>
      {[...Array(tableCell)].map((_, index) => {
        return (
          <TableCell key={index}>
            {index === 0 && isImage ? (
              <Stack spacing={1} direction="row" alignItems="center">
                <Skeleton
                  variant="rectangular"
                  width={40}
                  height={40}
                  sx={{ borderRadius: 1 }}
                />
                <Skeleton variant="text" width={'100%'} />
              </Stack>
            ) : (
              <Skeleton variant="text" />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
});

TableSkeleton.displayName = TableSkeleton;
export default TableSkeleton;
