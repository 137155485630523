import { useEffect, useLayoutEffect, useMemo } from 'react';

import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { _SYS_ADMIN_ROLES } from '../../constants/roles';
import i18n from '../../locales/i18n';
import { ONBOARDING, PAYMENTS } from '../paths';

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
  type: PropTypes.string,
};

export default function RoleBasedGuard({ accessibleRoles, children, type }) {
  const { t } = useTranslation(['toast-msg']);
  const currentRole = useSelector((state) => state.setup?.profile?.role?.role);
  const navigation = useNavigate();
  const location = useLocation();
  const setup = useSelector((state) => state?.setup);
  const accountId = useMemo(
    () => setup?.payments?.stripeDetails?.accountId,
    [setup?.payments?.stripeDetails?.accountId]
  );

  const stripeDetails = useMemo(
    () => setup?.payments?.stripeDetails,
    [setup?.payments?.stripeDetails]
  );
  const currentPlan = useMemo(
    () => setup?.profile?.companyDetails?.currentPlan?.plan,
    [setup?.profile?.companyDetails?.currentPlan?.plan]
  );
  const profile = useMemo(() => setup?.profile, [setup.profile]);

  const partnerProducts = useMemo(
    () => profile?.partner?.productsAvailable,
    [profile?.partner?.productsAvailable]
  );
  const productsAvailable = useMemo(
    () => profile?.productsAvailable,
    [profile?.productsAvailable]
  );
  const finalProducts = useMemo(
    () => (partnerProducts ? partnerProducts : productsAvailable),
    [partnerProducts, productsAvailable]
  );

  useEffect(() => {
    !currentPlan &&
      !_SYS_ADMIN_ROLES.includes(currentRole) &&
      navigation(ONBOARDING.MANAGE_PLAN.path);
  }, [currentPlan, currentRole, navigation]);

  useEffect(() => {
    _SYS_ADMIN_ROLES.includes(currentRole) && i18n.changeLanguage('en');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAccountNotVerified = useMemo(
    () =>
      stripeDetails?.accountStatus?.toLowerCase() === 'restricted' ||
      !stripeDetails?.isVerified,
    [stripeDetails?.accountStatus, stripeDetails?.isVerified]
  );

  const products = useMemo(
    () =>
      finalProducts?.map((ele) => {
        let product = ele?.value;
        if (ele?.value === 'web+cart') {
          product = 'web';
        }
        return product;
      }),
    [finalProducts]
  );

  //Filtering roles based on products provided by admin
  const filteredRoles = useMemo(() => {
    let filRoles = [];
    if (
      finalProducts &&
      (currentRole !== 'memberAdmin' ||
        (Object.keys(profile?.partner)?.length &&
          currentRole === 'memberAdmin')) &&
      type &&
      type !== 'admin'
    ) {
      if (!products?.includes(type)) {
        filRoles = accessibleRoles?.filter((ele) => ele !== currentRole);
      } else {
        filRoles = [...accessibleRoles];
      }
    } else {
      filRoles = [...accessibleRoles];
    }
    return filRoles;
  }, [
    finalProducts,
    currentRole,
    profile?.partner,
    type,
    products,
    accessibleRoles,
  ]);

  useLayoutEffect(() => {
    // For admin login, hide Chat Icon
    if (type === 'admin' && document?.getElementById('fc_frame')) {
      document.getElementById('fc_frame').style.display = 'none';
    }

    if (
      !_SYS_ADMIN_ROLES.includes(currentRole) &&
      ['payments']?.includes(type)
    ) {
      if (
        !(
          location.pathname?.includes(PAYMENTS.DASHBOARD.fullPath) ||
          location.pathname?.includes(PAYMENTS.ONBOARDING.fullPath)
        )
      ) {
        if (
          (!!stripeDetails?.scenario?.first ||
            !!stripeDetails?.scenario?.second ||
            !!stripeDetails?.scenario?.fifth ||
            !accountId) &&
          isAccountNotVerified
        ) {
          enqueueSnackbar(t('toast-msg:pageAccessError'), {
            variant: 'error',
          });
          navigation(PAYMENTS.DASHBOARD.fullPath);
        }
      } else {
        if (
          location.pathname?.includes(PAYMENTS.DASHBOARD.fullPath) &&
          accountId
        ) {
          navigation(PAYMENTS.DASHBOARD.fullPath);
        }
      }
    }
  }, [
    accountId,
    currentRole,
    isAccountNotVerified,
    location.pathname,
    navigation,
    stripeDetails?.scenario,
    t,
    type,
  ]);

  if (!filteredRoles?.some((val) => val === currentRole)) {
    const auth =
      localStorage.getItem('auth_token') && localStorage.getItem('admin_data');
    if (auth) {
      localStorage.removeItem('admin_data');
      window.close();
      return;
    } else {
      return <Navigate to={'/404'} replace />;
    }
  }

  return <>{children}</>;
}
