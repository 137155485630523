import { useEffect } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { fnPascalCase } from '../../utils/formatText';
import Iconify from '../Iconify';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RHFMultiSelectAutoComplete = ({
  name,
  values,
  options,
  isTax,
  addNew,
  setValue,
  getLabel,
  getValue,
  onChange,
  addItemFunction,
  hideAddOption,
  label,
  isOptionEqualField,
  helperText,
  renderChipLabel,
  addNewLabel,
  addNewTitle,
  error,
  ...other
}) => {
  const { control, trigger, watch } = useFormContext();
  const filter = createFilterOptions();

  useEffect(() => {
    if (watch(name)) {
      trigger(name);
    }
  }, [name, trigger, watch]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          sx={{ ...other?.sx }}
          multiple
          value={values}
          isOptionEqualToValue={(option, value) =>
            option[isOptionEqualField || 'title'] ===
            value[isOptionEqualField || 'title']
          }
          onChange={(event, newValue) => {
            if (event.key !== 'Backspace') {
              if (onChange && !newValue[newValue.length - 1]?.isAdd) {
                onChange(newValue);
              }
              field.onChange(newValue);
              if (addItemFunction && newValue[newValue.length - 1]?.isAdd) {
                addItemFunction(
                  newValue[newValue.length - 1].inputValue,
                  newValue[newValue.length - 1]?.isAdd
                );
              }
            }
          }}
          options={options}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option && option[getLabel || 'name' || 'title'];
          }}
          onBlur={(e) => !e.target.value && trigger(name)}
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <Box {...props} key={[option.id || option._id]}>
              {option?.addServiceFeeOrTax ? (
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <Iconify
                    height={14}
                    width={14}
                    color={'secondary.light'}
                    icon={'mdi:plus'}
                  />
                  <Typography color={'secondary.light'} variant="body1">
                    {option?.addServiceFeeOrTax}
                  </Typography>
                </Stack>
              ) : option?.site_name ? (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Stack direction={'row'} alignItems="center" spacing={0.5}>
                    <Avatar
                      variant="rounded"
                      alt="Remy Sharp"
                      src={option?.thumbnail_url}
                      sx={{
                        '& img': {
                          objectFit: 'contain',
                        },
                      }}
                    >
                      <Iconify icon="material-symbols:image-rounded" />
                    </Avatar>
                    <Typography>
                      {fnPascalCase(option[getLabel || 'name'])}
                    </Typography>
                  </Stack>
                </>
              ) : (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Stack direction={'row'} alignItems="flex-end" spacing={0.5}>
                    <Typography>
                      {fnPascalCase(option[getLabel || 'title'])}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      hidden={!option?.percentage || option?.title}
                    >
                      {`(${option?.percentage}${
                        option?.isRateInAmount ? '$' : '%'
                      })
                  ${option?.inclusive ? 'Incl.' : ''}`}
                    </Typography>
                  </Stack>
                </>
              )}
            </Box>
          )}
          renderTags={
            renderChipLabel
              ? (value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={
                          typeof renderChipLabel === 'function'
                            ? renderChipLabel(option)
                            : null
                        }
                      />
                    );
                  })
              : () => {}
          }
          filterOptions={(options, params) => {
            const { inputValue } = params;
            const filtered = inputValue
              ? filter(options, params)
              : filter(options, params).slice(0, 10);
            const isExisting = options.some(
              (option) => inputValue === (option && option?.title)
            );
            if (inputValue && !isExisting && !hideAddOption) {
              filtered.unshift({
                inputValue,
                [getLabel || 'title']: `Add "${params.inputValue}"`,
                isAdd: true,
                _id: Math.random(16),
              });
            }
            if (!options.length && !inputValue) {
              filtered.unshift({
                inputValue: '',
                [addNewTitle || 'title']: addNewLabel || 'Add',
                isAdd: true,
                _id: Math.random(16),
              });
            }

            return filtered;
          }}
          {...other}
          renderInput={(params) => (
            <TextField
              FormHelperTextProps={{
                sx: {
                  marginX: 0,
                },
              }}
              error={error ? error : false}
              {...params}
              placeholder={label}
              InputLabelProps={{ shrink: true }}
              helperText={helperText}
            />
          )}
        />
      )}
    />
  );
};

RHFMultiSelectAutoComplete.propTypes = {
  addItemFunction: PropTypes.func,
  addNew: PropTypes.func,
  getLabel: PropTypes.string,
  getValue: PropTypes.string,
  helperText: PropTypes.any,
  hideAddOption: PropTypes.any,
  isOptionEqualField: PropTypes.string,
  isTax: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  setValue: PropTypes.func,
  renderChipLabel: PropTypes.func,
  values: PropTypes.any,
  error: PropTypes.bool,
  addNewLabel: PropTypes.any,
  addNewTitle: PropTypes.any,
};

export default RHFMultiSelectAutoComplete;
