import { combineReducers } from 'redux';

import authSlice from '../redux/auth/authSlice';
import { api, getAPIHeaders } from './apiInterceptor';
import authLoadingSlice from './auth/authLoadingSlice';
import authContextSlice from './other/authContextSlice';
import planSlice from './plan/planSlice';
import routeSlice from './routeSlice';
import setupSlice from './setup/setupSlice';

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authSlice,
  setup: setupSlice,
  authContext: authContextSlice,
  plan: planSlice,
  route: routeSlice,
  loader: authLoadingSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    getAPIHeaders({}, true);
    localStorage.removeItem('auth_token');
    Object.keys(localStorage).forEach((value) => {
      if (!value.includes('i18nextLng')) {
        localStorage.removeItem(value);
      }
    });
    return combinedReducer({ authContext: { isInitialized: true } }, action);
  }
  return combinedReducer(state, action);
};

export { rootReducer };
