import { io } from 'socket.io-client';

import AppConfig from '../../app-config';
import { setRoleSliceFn } from '../../redux/auth/authSlice';
import { extendedApi } from '../../redux/payments/customerAction';
import { setup } from '../../redux/setup/setupAction';
import {
  updateCompanyDetails,
  socketPaymentSetupState,
  socketUpdateAdminSetting,
  socketShippingSetupState,
  partnerUpdate,
  SocketNotificationSetupState,
  webUpdate,
  updateImportInfo,
  updateSyncingInfo,
  updateRemainingSyncObj,
} from '../../redux/setup/setupSlice';
import { store } from '../../redux/store';

export let socket;

export const disconnectSocket = () => {
  store.dispatch({ type: 'LOG_OUT' });
  store.dispatch({
    type: 'authContext/setRedirectPath',
    payload: { URL: '/sign-in', isChange: false },
  });
};

export const connectSocket = ({ email, token, serviceId, superUserToken }) => {
  // Socket connection
  const host = `${document.location.protocol}//${
    AppConfig ? AppConfig.host : ''
  }:${AppConfig ? AppConfig.port : ''}`;

  const query = {
    'X-Paid-User-Username': email,
    'X-Paid-User-Token': token,
    'X-Paid-User-Service-Id': serviceId,
    ...(superUserToken && { 'X-Paid-Super-User-Token': superUserToken }),
  };
  socket = io(host, {
    query,
    transports: ['websocket'],
    secure: true,
    autoConnect: true,
  });

  socket.on('PROFILE_UPDATE', async (data) => {
    let currentRole = store?.getState()?.setup?.profile?.role?.role;
    if (currentRole !== data?.role?.role && currentRole) {
      const { data: setupData } = await store.dispatch(setup.initiate());
      currentRole = setupData?.profile?.role?.role;
    }
    if (data?.role?.role === currentRole) {
      store.dispatch(
        setRoleSliceFn({
          role: currentRole,
        })
      );
      store.dispatch(
        updateCompanyDetails({
          ...data,
          type: 'PROFILE_UPDATE',
        })
      );
    }
    // console.log('Socket PROFILE_UPDATE', data);
  });

  socket.on('PAYMENT_UPDATE', (data) => {
    store.dispatch(
      socketPaymentSetupState({
        ...data,
        type: 'PAYMENT_UPDATE',
      })
    );
    // console.log('PAYMENT_UPDATE', data);
  });

  socket.on('NOTIFICATION_UPDATE', (data) => {
    store.dispatch(SocketNotificationSetupState(data));
  });

  socket.on('PARTNER_UPDATE', (data) => {
    store.dispatch(partnerUpdate(data));
    // console.log('PARTNER_UPDATE', data);
  });

  socket.on('ADMIN_SETTING_UPDATE', (data) => {
    store.dispatch(
      socketUpdateAdminSetting({
        ...data,
        type: 'ADMIN_SETTING_UPDATE',
      })
    );
  });

  socket.on('connect', () => {
    // console.log('connectSocket:::::::::');
  });

  socket.on('error', (data) => {
    // console.log(data, 'Socket Error');
  });

  socket.on('SIGNOUT', (reason) => {
    store.dispatch({ type: 'LOG_OUT' });
    store.dispatch({
      type: 'authContext/setRedirectPath',
      payload: { URL: '/sign-in', isChange: false },
    });
  });

  socket.on('SHIPPING_UPDATE', (data) => {
    // console.log(data, 'Socket SHIPPING_UPDATE');
    store.dispatch(
      socketShippingSetupState({
        ...data,
        type: 'SHIPPING_UPDATE',
      })
    );
  });

  socket.on('WEB_UPDATE', (data) => {
    store.dispatch(webUpdate(data));
  });

  socket.on('ADDING_MULTI_USER_PROGRESS', (msg) => {
    const prevProgress = store.getState()?.setup?.profile?.importInfo?.progress;
    let importProgress = Math.round((+msg?.progress / +msg?.totalRecord) * 100);
    store.dispatch(updateImportInfo({ progress: importProgress }));
    if (
      importProgress &&
      importProgress !== 100 &&
      importProgress % 10 === 0 &&
      prevProgress !== importProgress
    ) {
      store.dispatch(extendedApi.util.invalidateTags(['getCustomers']));
    }
  });

  socket.on('SYNCING_TRANSACTIONS', (msg) => {
    if (msg) {
      const finalPayload = {
        processName: msg.transactionType,
        [msg.transactionType]: {
          progress: msg.progress || 0,
          syncing: true,
        },
      };
      store.dispatch(updateSyncingInfo(finalPayload));

      // TODO: Need to change this logic for complete the process.
      if (msg.progress === 100) {
        finalPayload[msg.transactionType] = {};
        store.dispatch(updateSyncingInfo(finalPayload));
      }
    }
  });

  socket.on('REMAINING_TRANSACTIONS_TO_SYNC', (msg) => {
    store.dispatch(updateRemainingSyncObj(msg));
  });
};
