import amexCard from '../assets/amex-light.svg';
import Checkout from '../assets/checkout.svg';
import company from '../assets/dashboard/company.svg';
import mostused from '../assets/dashboard/mostused.svg';
import partners from '../assets/dashboard/partner.svg';
import signup from '../assets/dashboard/signup.svg';
import users from '../assets/dashboard/users.svg';
import deleteIcon from '../assets/deleteIcon.svg';
import discoverCard from '../assets/discover.svg';
import exclamationMark from '../assets/exclamationMark.svg';
import UploadFromComputer from '../assets/fileupload.svg';
import integrationImage from '../assets/Integration/integration.svg';
import Invoice from '../assets/invoice.svg';
import expiredResetLink from '../assets/like-expire.svg';
import linkExpired from '../assets/linkExpired.svg';
import LockIcon from '../assets/lockIcon.svg';
import cartPreview from '../assets/login/cart-preview.svg';
import facebookIcon from '../assets/login/facebook-social-icon.svg';
import linkedInIcon from '../assets/login/linkedin-social-icon.svg';
import LogoPaidCart from '../assets/login/logo-paid-cart.svg';
import LogoPaidPayment from '../assets/login/logo-paid-payment.svg';
import LogoPaidShipping from '../assets/login/logo-paid-shipping.svg';
import LogoPaidWebCart from '../assets/login/logo-paid-web+cart.svg';
import LogoPaidWeb from '../assets/login/logo-paid-web.svg';
import PaymentPreview from '../assets/login/payment-preview.svg';
import shippingPreview from '../assets/login/shipping-preview.svg';
import slideCard from '../assets/login/slide-cart.svg';
import SlidePayments from '../assets/login/slide-payment.svg';
import slideShipping from '../assets/login/slide-shipping.svg';
import SlideWebCart from '../assets/login/slide-web+cart.svg';
import slideWeb from '../assets/login/slide-web.svg';
import twitterIcon from '../assets/login/twitter-logo.svg';
import webCartPreview from '../assets/login/web+cart-preview.svg';
import masterCard from '../assets/mastercard.svg';
import SiteNotFoundImg from '../assets/no_sites.svg';
import offline from '../assets/offline.svg';
import imageNotFoundNew from '../assets/online_gallery.svg';
import successIcon from '../assets/Payments/acceptIcon.svg';
import checkoutNotAvl from '../assets/Payments/checkoutNotAvailable.svg';
import correctIcon from '../assets/Payments/correct.svg';
import csv_icon from '../assets/Payments/csv_icon.svg';
import currentIcon from '../assets/Payments/current.svg';
import depositedIcon from '../assets/Payments/deposited.svg';
import disputeTransactions from '../assets/Payments/dispute_transactions.svg';
import folderIcon from '../assets/Payments/folder.svg';
import wixIcon from '../assets/Payments/ic_wix.svg';
import wordPressIcon from '../assets/Payments/ic_wordpress.svg';
import defaultPaymentMethodIcon from '../assets/Payments/icon-03.png';
import unAuthorizedIcon from '../assets/Payments/lock-icon.svg';
import newCustomer from '../assets/Payments/newCustomer.svg';
import newCustomerIcon from '../assets/Payments/newCustomerIcon.svg';
import newInvoiceIcon from '../assets/Payments/newInvoiceIcon.svg';
import newPOSIcon from '../assets/Payments/newPOSIcon.svg';
import newQuickCharge from '../assets/Payments/newQuickCharge.svg';
import addBank from '../assets/Payments/NoDataSvgs/NoBankAccounts.svg';
import NoCheckoutOrder from '../assets/Payments/NoDataSvgs/NoCheckout Orders.svg';
import NoCustomer from '../assets/Payments/NoDataSvgs/NoCustomers.svg';
import noLocation from '../assets/Payments/NoDataSvgs/NoLocations.svg';
import Payout from '../assets/Payments/NoDataSvgs/NoPayouts.svg';
import gatewayReports from '../assets/Payments/NoDataSvgs/NoReports.svg';
import noOrder from '../assets/Payments/NoDataSvgs/NoStoreOreders.svg';
import gatewayTransactions from '../assets/Payments/NoDataSvgs/NoTransactions.svg';
import noPayment from '../assets/Payments/noPayments.svg';
import CheckoutsIcon from '../assets/Payments/onboarding/checkouts.svg';
import InvoicesIcon from '../assets/Payments/onboarding/invoices.svg';
import PosInvoice from '../assets/Payments/onboarding/pos_icon.svg';
import QuickCharge from '../assets/Payments/onboarding/quick_charge.svg';
import RecurringInvoicesIcon from '../assets/Payments/onboarding/recurring-invoices.svg';
import StripeCard from '../assets/Payments/onboarding/stripecard.svg';
import openEyeIcon from '../assets/Payments/open_eye.svg';
import paymentIcon from '../assets/Payments/paymentIcon.svg';
import payoutIcon from '../assets/Payments/payoutIcon.svg';
import companyPlaceholder from '../assets/Payments/placeholder-company.png';
import Previous from '../assets/Payments/Previous.svg';
import reportIcon from '../assets/Payments/reportIcon.svg';
import salesIcon from '../assets/Payments/salesIcon.svg';
import sendIcon from '../assets/Payments/send.svg';
import successPayment from '../assets/Payments/SuccessPayment.svg';
import syncIcon from '../assets/Payments/syncIcon.svg';
import Upcoming from '../assets/Payments/Upcoming.svg';
import amex_Icon from '../assets/Payments_cards/amex.svg';
import diners_Icon from '../assets/Payments_cards/Diners.svg';
import discover_Icon from '../assets/Payments_cards/discover.svg';
import jcb_Icon from '../assets/Payments_cards/JCB.svg';
import master_Icon from '../assets/Payments_cards/mastercard.svg';
import union_Icon from '../assets/Payments_cards/Union.svg';
import visa_Icon from '../assets/Payments_cards/visa.svg';
import PaidCart from '../assets/pi-cart.svg';
import PaidPIcon from '../assets/pi-p.svg';
import PaidLogo from '../assets/pi-paid.svg';
import PaidPayments from '../assets/pi-payments.svg';
import PaidShipping from '../assets/pi-shopping.svg';
import PaidWeb from '../assets/pi-web.svg';
import Products from '../assets/products.svg';
import Promotion from '../assets/promotion.svg';
import ShipTime from '../assets/shiptime-icon.svg';
import ShipTimeLogoEn from '../assets/shiptime-logo-en.svg';
import ShipTimeLogoFr from '../assets/shiptime-logo-fr.svg';
import AddUser from '../assets/teams/add_user.svg';
import noServicesAvailable from '../assets/unavailable.svg';
import uploadCompanyLogoImg from '../assets/upload-company-logo.svg';
import verifyEmail from '../assets/verifyEmail.svg';
import visaCard from '../assets/visa-logo.svg';
import megaphone from '../assets/Web/megaPhon.svg';
import searchIcon from '../assets/Web/search-icon.svg';

const Images = {
  sendIcon: {
    alt: 'Send icon',
    filename: sendIcon,
  },
  syncIcon: {
    alt: 'Sync icon',
    filename: syncIcon,
  },
  openEyeIcon: {
    alt: 'Preview icon',
    filename: openEyeIcon,
  },
  folderIcon: {
    alt: 'Draft icon',
    filename: folderIcon,
  },
  depositedIcon: {
    alt: 'Deposited icon',
    filename: depositedIcon,
  },
  correctIcon: {
    alt: 'Paid icon',
    filename: correctIcon,
  },
  users: { alt: 'Users', filename: users },
  partners: { alt: 'Partners', filename: partners },
  mostused: { alt: 'Most Used', filename: mostused },
  company: { alt: 'Company', filename: company },
  signup: { alt: 'Signup', filename: signup },
  noPayment: { alt: 'No Payment', filename: noPayment },
  currentIcon: {
    alt: 'Current Amount',
    filename: currentIcon,
  },
  noCheckoutOrder: {
    alt: 'No Checkouts',
    filename: NoCheckoutOrder,
  },
  reportIcon: {
    alt: 'Reports',
    filename: reportIcon,
  },
  successPayment: {
    alt: 'Successful Payment',
    filename: successPayment,
  },
  paymentIcon: {
    alt: 'Payment',
    filename: paymentIcon,
  },
  previous: {
    alt: 'Previous',
    filename: Previous,
  },
  upcoming: {
    alt: 'Upcoming',
    filename: Upcoming,
  },
  payoutIcon: {
    alt: 'Payout',
    filename: payoutIcon,
  },
  newCustomer: {
    alt: 'NewCustomer',
    filename: newCustomer,
  },
  newInvoiceIcon: {
    alt: 'newInvoice',
    filename: newInvoiceIcon,
  },
  newQuickCharge: {
    alt: 'newQuickCharge',
    filename: newQuickCharge,
  },
  newPOSIcon: {
    alt: 'newPOS',
    filename: newPOSIcon,
  },
  newCustomerIcon: {
    alt: 'NewCustomer',
    filename: newCustomerIcon,
  },
  salesIcon: {
    alt: 'Sales',
    filename: salesIcon,
  },
  successIcon: {
    alt: 'Success',
    filename: successIcon,
  },
  noServicesAvailable: {
    alt: 'No Services Unavailable',
    filename: noServicesAvailable,
  },
  Promotion: {
    alt: 'Promotion',
    filename: Promotion,
  },
  PaidPIcon: {
    alt: 'PaidPIcon',
    filename: PaidPIcon,
  },
  PaidLogo: {
    alt: 'paidLogo',
    filename: PaidLogo,
  },
  PaidPayments: {
    alt: 'PaidPayments',
    filename: PaidPayments,
  },
  'PaidWeb + Cart': {
    alt: 'PaidWeb',
    filename: PaidWeb,
  },
  PaidCart: {
    alt: 'PaidCart',
    filename: PaidCart,
  },
  PaidShipping: {
    alt: 'PaidShipping',
    filename: PaidShipping,
  },
  PaidWeb: {
    alt: 'PaidWeb',
    filename: PaidWeb,
  },
  FacebookIcon: {
    alt: 'FacebookIcon',
    filename: facebookIcon,
  },
  LinkedInIcon: {
    alt: 'LinkedInIcon',
    filename: linkedInIcon,
  },
  TwitterIcon: {
    alt: 'TwitterIcon',
    filename: twitterIcon,
  },
  LockIcon: {
    alt: 'LockIcon',
    filename: LockIcon,
  },
  QuickCharge: {
    alt: 'QuickCharge',
    filename: QuickCharge,
  },
  posInvoice: {
    alt: 'PosImage',
    filename: PosInvoice,
  },
  resetPasswordLink: {
    filename: expiredResetLink,
    alt: 'link expired',
  },
  InvoicesIcon: {
    alt: 'InvoicesIcon',
    filename: InvoicesIcon,
  },
  RecurringInvoicesIcon: {
    alt: 'RecurringInvoicesIcon',
    filename: RecurringInvoicesIcon,
  },
  CheckoutsIcon: {
    alt: 'CheckoutsIcon',
    filename: CheckoutsIcon,
  },
  StripeCardIcon: {
    alt: 'StripeCardIcon',
    filename: StripeCard,
  },
  discoverCard: {
    alt: 'Discover Card',
    filename: discoverCard,
  },
  amexCard: {
    alt: 'American Express Card',
    filename: amexCard,
  },
  visaCard: {
    alt: 'Visa Card',
    filename: visaCard,
  },
  masterCard: {
    alt: 'Master Card',
    filename: masterCard,
  },
  uploadCompanyLogo: {
    alt: 'UploadCompanyLogoImg',
    filename: uploadCompanyLogoImg,
  },
  shipTimeLogo: {
    alt: 'shipTimeLogo',
    filename: ShipTime,
  },
  shipTimeLogoEn: {
    alt: 'shipTimeLogo in english',
    filename: ShipTimeLogoEn,
  },
  shipTimeLogoFr: {
    alt: 'shipTimeLogo in french',
    filename: ShipTimeLogoFr,
  },
  defaultImageForAvatar: {
    alt: 'image',
    filename:
      'https://lh5.googleusercontent.com/proxy/bUuQB7WRorPPDc_w2lDHsuOcK1s6LMc5EqVMZNQiOTfbkFE6Ynjqaql9vwxZ1MX4Ei7XmlZWm0p6P0q8gT4AgryWEBd0NYv5GTUMQextF7rEfWWfJuHCdECbkZfjnL16Fjaco94BiUCyoyFGKa_V8T7AqDEJx5jx661gT9QZVjHaqvx1EwE5wxk=w1920-h1014',
  },
  add_user: {
    alt: 'Users',
    filename: AddUser,
  },
  megaPhon: {
    alt: 'megaphone',
    filename: megaphone,
  },
  imageNotFoundNew: {
    alt: 'imageNotFoundNew',
    filename: imageNotFoundNew,
  },
  siteNotFound: {
    alt: 'siteNotFound',
    filename: SiteNotFoundImg,
  },
  templateNotFound: {
    alt: 'templateNotFound',
    filename: searchIcon,
  },
  invoice: {
    alt: 'noInvoice',
    filename: Invoice,
  },
  verifyEmail: {
    alt: 'verifyEmail',
    filename: verifyEmail,
  },
  linkExpired: {
    alt: 'linkExpired',
    filename: linkExpired,
  },
  offline: {
    alt: 'offline',
    filename: offline,
  },
  products: {
    alt: 'noProducts',
    filename: Products,
  },
  customer: {
    alt: 'noCustomer',
    filename: NoCustomer,
  },
  checkout: {
    alt: 'noCheckout',
    filename: Checkout,
  },
  payout: {
    alt: 'noPayout',
    filename: Payout,
  },
  addBank: {
    alt: 'noBank',
    filename: addBank,
  },
  deleteIcon: {
    alt: 'noCustomer',
    filename: deleteIcon,
  },
  exclamationMarkIcon: {
    alt: 'exclamation',
    filename: exclamationMark,
  },
  uploadImageFromComputer: {
    alt: 'uploadImageFromComputer',
    filename: UploadFromComputer,
  },
  wordPressIcon: {
    alt: 'wordPressIcon',
    filename: wordPressIcon,
  },
  wixIcon: {
    alt: 'wixIcon',
    filename: wixIcon,
  },
  getWayDispute: {
    alt: 'disputeTransactions',
    filename: disputeTransactions,
  },
  gatewayReports: {
    alt: 'gatewayReports',
    filename: gatewayReports,
  },
  defaultPaymentMethodIcon: {
    alt: 'defaultPaymentMethod',
    filename: defaultPaymentMethodIcon,
  },
  gatewayTransactions: {
    alt: 'gatewayTransactions',
    filename: gatewayTransactions,
  },
  noOrder: {
    alt: 'noOrder',
    filename: noOrder,
  },
  noLocation: {
    alt: 'noLocation',
    filename: noLocation,
  },
  SlidePayments: {
    alt: 'SlidePayments',
    filename: SlidePayments,
  },
  slideShipping: {
    alt: 'slideShipping',
    filename: slideShipping,
  },
  slideWeb: {
    alt: 'slideWeb',
    filename: slideWeb,
  },
  slideCard: {
    alt: 'slideCard',
    filename: slideCard,
  },
  shippingPreview: {
    alt: 'shippingPreview',
    filename: shippingPreview,
  },
  cartPreview: {
    alt: 'cartPreview',
    filename: cartPreview,
  },
  webCartPreview: {
    alt: 'webCartPreview',
    filename: webCartPreview,
  },
  LogoPaidCart: {
    alt: 'LogoPaidCart',
    filename: LogoPaidCart,
  },
  LogoPaidPayment: {
    alt: 'LogoPaidPayment',
    filename: LogoPaidPayment,
  },
  LogoPaidShipping: {
    alt: 'LogoPaidShipping',
    filename: LogoPaidShipping,
  },
  LogoPaidWebCart: {
    alt: 'LogoPaidWebCart',
    filename: LogoPaidWebCart,
  },
  LogoPaidWeb: {
    alt: 'LogoPaidWeb',
    filename: LogoPaidWeb,
  },
  PaymentPreview: {
    alt: 'PaymentPreview',
    filename: PaymentPreview,
  },
  SlideWebCart: {
    alt: 'SlideWebCart',
    filename: SlideWebCart,
  },
  discoverCardNew: {
    alt: 'Discover Card',
    filename: discover_Icon,
  },
  amexCardNew: {
    alt: 'American Express Card',
    filename: amex_Icon,
  },
  visaCardNew: {
    alt: 'Visa Card',
    filename: visa_Icon,
  },
  masterCardNew: {
    alt: 'Master Card',
    filename: master_Icon,
  },
  dinersCardNew: {
    alt: 'Diners Card',
    filename: diners_Icon,
  },
  jcbCardNew: {
    alt: 'JCB Card',
    filename: jcb_Icon,
  },
  unionCardNew: {
    alt: 'Union Card',
    filename: union_Icon,
  },
  unAuthorizedIcon: {
    alt: 'No Checkout order',
    filename: unAuthorizedIcon,
  },
  checkoutNotAvl: {
    alt: 'No Checkout order',
    filename: checkoutNotAvl,
  },
  csvIcon: {
    alt: 'CSV file icon',
    filename: csv_icon,
  },
  companyPlaceholder: {
    alt: 'Company placeholder',
    filename: companyPlaceholder,
  },
  integrationImage: {
    alt: 'Integration Image',
    filename: integrationImage,
  },
};

export default Images;
