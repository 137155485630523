import { useEffect } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';

const FormProvider = ({ children, onSubmit, methods, style }) => {
  //Validation error message translation functionality
  const {
    formState: { errors },
    trigger,
  } = methods;
  useEffect(() => {
    const errorKeys = Object.keys(errors || {});
    if (errorKeys?.length && !!i18next.language) {
      trigger(errorKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} style={style}>
        {children}
      </form>
    </Form>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  style: PropTypes.any,
};

export default FormProvider;
