import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';

api.enhanceEndpoints({ addTagTypes: ['Integration'] });
export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSingleBusiness: build.query({
      query: (params) => ({
        url:
          apiRoutes.INTEGRATION.basePath +
          apiRoutes.INTEGRATION.endpoints.getSingleBusiness.url,
        method: apiRoutes.INTEGRATION.endpoints.getSingleBusiness.method,
        params: params,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
    disconnectRailzService: build.mutation({
      query: (params) => {
        return {
          url:
            apiRoutes.INTEGRATION.basePath +
            `${apiRoutes.INTEGRATION.endpoints.disconnectRailzService.url}`,
          method: apiRoutes.INTEGRATION.endpoints.disconnectRailzService.method,
          body: params,
        };
      },

      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetSingleBusinessQuery,
  useDisconnectRailzServiceMutation,
  useLazyGetSingleBusinessQuery,
} = extendedApi;

export const {
  endpoints: { Integration },
} = extendedApi;
