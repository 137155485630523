export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none !important',
          },
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedIGrey: {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[300],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          '&:hover': {
            boxShadow: theme.customShadows.primary,
          },
        },
        containedSecondary: {
          '&:hover': {
            boxShadow: theme.customShadows.secondary,
          },
        },
        containedInfo: {
          '&:hover': {
            boxShadow: theme.customShadows.info,
          },
        },
        containedSuccess: {
          '&:hover': {
            boxShadow: theme.customShadows.success,
          },
        },
        containedWarning: {
          '&:hover': {
            boxShadow: theme.customShadows.warning,
          },
        },
        containedError: {
          color: theme.palette.grey[0],
          background: theme.palette.error.main,
          boxShadow: 'none',
          ':hover': {
            background: theme.palette.error.main,
          },
        },
        containedCancel: {
          color: theme.palette.grey[0],
          background: theme.palette.grey.main,
          borderColor: theme.palette.grey.main,
          ':hover': {
            background: theme.palette.grey.main,
            borderColor: theme.palette.grey.main,
          },
        },
        containedWhite: {
          color: theme.palette.primary.main,
          background: theme.palette.grey[0],
          ':hover': {
            background: theme.palette.grey[0],
            borderColor: theme.palette.grey[0],
          },
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedWhite: {
          color: theme.palette.grey[0],
          border: `1px solid ${theme.palette.grey[0]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
