import { Card, Grid, Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';

const RadioGroup = ({ optionLength = 2 }) => {
  return (
    <Stack alignItems={'center'} gap={2} direction={'row'}>
      <Skeleton variant="rectangular" width={80} height={25} />
      {[...Array(optionLength)].map((_, index) => (
        <Stack direction={'row'} key={index} gap={1} alignItems={'center'}>
          <Skeleton variant="rounded" width={20} height={20} />
          <Skeleton variant="rectangular" width={50} height={25} />
        </Stack>
      ))}
    </Stack>
  );
};

RadioGroup.propTypes = {
  optionLength: PropTypes.number,
};

const PartnerDetailSkeleton = () => {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Card sx={{ p: 3 }}>
          <Skeleton variant="rectangular" width={'100%'} height={270} />
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card sx={{ p: 3 }}>
          <Stack gap={2}>
            <Skeleton variant="rectangular" width={'100%'} height={145} />
            <Stack
              gap={2}
              direction={{ xs: 'column', xxl: 'row' }}
              justifyContent={'space-between'}
            ></Stack>

            <Skeleton variant="rectangular" width={'30%'} height={45} />
            <Grid container gap={2}>
              {[...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={'20%'}
                  height={35}
                />
              ))}
            </Grid>
          </Stack>
        </Card>
      </Grid>
    </>
  );
};

export default PartnerDetailSkeleton;
