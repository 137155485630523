import { MenuItem, Skeleton, TextField } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    top: '-2px',
  },
  '& .MuiInputLabel-shrink': {
    top: '0px',
  },
});

const RHFSelect = ({
  name,
  options,
  onChange,
  valueField = 'value',
  labelField = 'title',
  renderLabel,
  menuStyle,
  translations = [],
  ...other
}) => {
  const { control } = useFormContext();
  const [t] = useTranslation([...translations]);

  //For check translation file
  const getLabel = (label) => (translations?.length ? t(label) : label);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
            field.onChange(e.target.value);
          }}
          sx={{
            '& .MuiOutlinedInput-input': {
              py: '14px',
            },
          }}
          InputProps={{
            sx: {
              height: other.size === 'small' ? 'auto' : '48px',
            },
          }}
          select
          fullWidth
          FormHelperTextProps={{
            sx: {
              marginX: 0,
            },
          }}
          error={!!error}
          helperText={error?.message}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                },
              },
            },
          }}
          {...other}
        >
          {options?.length ? (
            options?.map((option, index) => (
              <MenuItem
                value={option?.[valueField]}
                key={index}
                sx={{ ...menuStyle, ...(option?.hide && { display: 'none' }) }}
              >
                {getLabel(
                  renderLabel ? renderLabel(option) : option?.[labelField]
                )}
              </MenuItem>
            ))
          ) : (
            <Skeleton animation="wave" variant="rectangular" height={35} />
          )}
        </StyledTextField>
      )}
    />
  );
};

RHFSelect.propTypes = {
  labelField: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  renderLabel: PropTypes.func,
  options: PropTypes.array,
  valueField: PropTypes.any,
  optionAsAValue: PropTypes.bool,
  menuStyle: PropTypes.object,
  translations: PropTypes.array,
};

export default RHFSelect;
