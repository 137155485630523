import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { Container, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RHFButton from '../../../../components/button/RHFButton';
import Image from '../../../../components/Image';
import ConfirmationModal from '../../../../components/modal/ConfirmationModal';
import Images from '../../../../constants/images';
import {
  useLazyGetSingleBusinessQuery,
  useDisconnectRailzServiceMutation,
} from '../../../../redux/integration/integrationActions';
import { useSyncTransactionsToRailzMutation } from '../../../../redux/payments/transactionAction';
import { updateSyncingInfo } from '../../../../redux/setup/setupSlice';
import * as routes from '../../../../routes/paths';
import palette from '../../../../theme/palette';

export const Integration = () => {
  // React hooks
  const { t } = useTranslation(['common', 'payments/integration']);
  const navigate = useNavigate();

  const setup = useSelector((state) => state?.setup);

  const hasRemainingSyncCounts = useMemo(() => {
    if (setup?.accountIntegration?.remainingTransactionsToSync) {
      return Object.values(
        setup?.accountIntegration?.remainingTransactionsToSync
      )?.some((e) => e);
    }
    return false;
  }, [setup?.accountIntegration?.remainingTransactionsToSync]);

  const dispatch = useDispatch();

  // API
  const [syncTransactionsToRailz] = useSyncTransactionsToRailzMutation();

  // Local state
  const [openConfirmModal, setOpenConfirmModal] = useState({
    modalOpen: false,
    id: null,
    archive: null,
    modelData: null,
  });
  const [content, setContent] = useState({
    cancelButtonAction: () =>
      setOpenConfirmModal((prevState) => ({
        ...prevState,
        modalOpen: false,
      })),
    submitButtonAction: () => {
      navigate(routes.ONBOARDING?.MANAGE_PLAN?.path);
    },
  });
  // Global state

  const business = useSelector(
    (state) => state?.setup?.accountIntegration?.business
  );

  const remainingTransactionsToSync = useMemo(() => {
    const requiredSyncArr = [];
    const remainingSyncObj =
      setup?.accountIntegration?.remainingTransactionsToSync;
    for (let ele in remainingSyncObj) {
      if (remainingSyncObj[ele]) {
        requiredSyncArr.push(ele);
      }
    }
    return requiredSyncArr;
  }, [setup?.accountIntegration?.remainingTransactionsToSync]);

  // Constants
  const accountId = useMemo(
    () => setup?.payments?.stripeDetails?.accountId,
    [setup?.payments?.stripeDetails?.accountId]
  );
  const companyId = useMemo(
    () => setup?.profile?.companyDetails?._id,
    [setup?.profile?.companyDetails?._id]
  );

  const profile = useMemo(() => setup?.profile, [setup?.profile]);
  const companyDetails = useMemo(
    () => profile?.companyDetails,
    [profile?.companyDetails]
  );
  const businessName = useMemo(
    () => companyDetails?.name,
    [companyDetails?.name]
  );
  const currentPlan = useMemo(
    () => companyDetails?.currentPlan,
    [companyDetails?.currentPlan]
  );
  const status = useMemo(
    () => business?.status === 'active',
    [business?.status]
  );

  //Railz sync
  const isSyncing = useMemo(
    () => setup.profile.progressQueue.isSyncing,
    [setup.profile.progressQueue.isSyncing]
  );

  const SeparatorStyle = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }));

  // API calls
  const [disconnectFunc, { isLoading: isDisconnectLoading }] =
    useDisconnectRailzServiceMutation();
  const [getSingleBusinessFunc, { isLoading, isFetching }] =
    useLazyGetSingleBusinessQuery();

  const getBusinessInfo = useCallback(async () => {
    const response = await getSingleBusinessFunc({
      businessName: companyId,
    });
    if (response?.data?.status === 'success') {
      navigate(routes.PAYMENTS.INTEGRATION.fullPath);
    } else {
      enqueueSnackbar(response?.error?.data?.message || 'Error', {
        variant: 'error',
      });
    }
  }, [navigate, getSingleBusinessFunc, companyId]);

  const getAccountStatus = (businessStatus) => {
    switch (businessStatus) {
      case 'active':
        return (
          <Stack direction="row" justifyContent="center" mt={4}>
            {isLoading ? (
              <Skeleton variant="rectangular" height={30} sx={{ width: 120 }} />
            ) : (
              <Typography variant="h5" textAlign="center">
                {t('payments/integration:connected')}
              </Typography>
            )}
          </Stack>
        );
      case 'inactive':
        return (
          <Stack direction="row" justifyContent="center" mt={4}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                height={30}
                sx={{
                  width: 120,
                }}
              />
            ) : (
              <Typography variant="h3" textAlign="center">
                {t('payments/integration:notConnected')}
              </Typography>
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  const disconnectRailService = useCallback(async () => {
    const response = await disconnectFunc({
      businessName: business?.businessName,
    });

    if (response?.data?.status === 'success') {
      enqueueSnackbar(response?.data?.message, { variant: 'success' });
      getBusinessInfo();
    } else {
      enqueueSnackbar('Error', { variant: 'error' });
    }
  }, [business?.businessName, disconnectFunc, getBusinessInfo]);

  useLayoutEffect(() => {
    getBusinessInfo();
    // eslint-disable-next-line
  }, []);

  const hasSyncingService = useMemo(() => {
    return !setup?.settings?.hideIntegration;
  }, [setup?.settings?.hideIntegration]);

  const connectFn = useCallback(() => {
    if (currentPlan?.price === 0) {
      setContent((prevState) => ({
        ...prevState,
        cancelButtonTitle: t('common:keepExploring'),
        submitButtonTitle: t('paid/subscription-billing:changePlan'),
        submitButtonColor: 'primary',
        submitButtonVariant: 'contained',
        title: (
          <Trans
            i18nKey="paid/member-dashboard:noPermission"
            t={t}
            businessName={businessName}
          >
            Unlock {{ businessName }}&apos;s full potential
          </Trans>
        ),
        bodyText: (
          <Trans
            t={t}
            i18nKey="paid/member-dashboard:upgradeIntegrationMessage"
          >
            You&apos;re just one upgrade away from connecting with your
            preferred accounting services with{' '}
            <SeparatorStyle variant="subtitle">Paid </SeparatorStyle>
          </Trans>
        ),
        image: Images?.LockIcon?.filename,
      }));
      setOpenConfirmModal((prevState) => ({
        ...prevState,
        modalOpen: true,
        archive: false,
      }));
    } else {
      navigate(routes.PAYMENTS.RAILINTEGRATION.fullPath);
    }
  }, [businessName, currentPlan?.price, navigate, t]);

  // Railz sync confirmation modal
  const syncHandler = () => {
    setContent((prevState) => ({
      ...prevState,
      cancelButtonTitle: 'Cancel',
      submitButtonTitle: 'Start',
      submitButtonColor: 'primary',
      submitButtonVariant: 'contained',
      title: 'Sync All transactions',
      bodyText: 'Are you sure you want to sync all transactions?',
      submitButtonAction: () => {
        handleSync();
      },
      image: Images?.syncIcon?.filename,
    }));
    setOpenConfirmModal((prevState) => ({
      ...prevState,
      modalOpen: true,
    }));
  };

  // Handle sync API
  const handleSync = useCallback(async () => {
    //Check and change based on remainning sync (it should be dynamic).

    let syncPayload = {
      isSyncing: true,
    };

    //Just for prepare the payload
    for (let i = 0; i < remainingTransactionsToSync.length; i++) {
      const transactionType = remainingTransactionsToSync[i];
      syncPayload[transactionType] = { syncing: true, progress: 0 };
    }
    dispatch(updateSyncingInfo(syncPayload));

    setOpenConfirmModal((prevState) => ({
      ...prevState,
      modalOpen: false,
    }));

    const res = await syncTransactionsToRailz({
      accountId,
      companyId,
    });

    // Show snackbar response after completed
    if (res?.data?.status === 'success') {
      enqueueSnackbar('Data sync successfully.', {
        variant: 'success',
      });
    }

    // After completed the syncing update the status of the syncing false.
    const updatedPayload = {
      isSyncing: false,
    };

    dispatch(updateSyncingInfo(updatedPayload));
  }, [
    accountId,
    companyId,
    dispatch,
    remainingTransactionsToSync,
    syncTransactionsToRailz,
  ]);

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Stack alignItems="center" justifyContent="center" height="100%">
        {isLoading ? (
          <Stack direction="row">
            <Skeleton variant="rectangular" height={400} width={500} />
          </Stack>
        ) : (
          <Image src={Images.integrationImage.filename} sx={{ height: 400 }} />
        )}
        {getAccountStatus(business?.status?.toLowerCase())}
        <Stack p={3} spacing={2} mr={5}>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              height={30}
              sx={{
                align: 'center',
              }}
            />
          ) : (
            <Typography
              variant="body1"
              color={palette?.light?.grey?.[500]}
              textAlign="center"
            >
              {t('payments/integration:serviceCardTitle')}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" justifyContent="center" gap={2}>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              height={30}
              sx={{
                width: 120,
              }}
            />
          ) : !status ? (
            <RHFButton
              type="submit"
              variant={'contained'}
              title={t('payments/integration:connect')}
              onClick={() => connectFn()}
            />
          ) : (
            <>
              <RHFButton
                isLoading={isDisconnectLoading || isLoading || isFetching}
                disabled={
                  isDisconnectLoading || isLoading || isFetching || isSyncing
                }
                type="submit"
                variant={'contained'}
                color={'error'}
                title={t('payments/integration:disconnect')}
                onClick={() => {
                  disconnectRailService();
                }}
              />
              {hasSyncingService && hasRemainingSyncCounts ? (
                <RHFButton
                  title={'Sync all transactions'}
                  color="primary"
                  onClick={() => {
                    syncHandler();
                  }}
                  disabled={isSyncing || isLoading || isFetching}
                  variant={'contained'}
                  customStyle={{ px: { xs: 1, sm: 2 } }}
                />
              ) : null}
            </>
          )}
        </Stack>
      </Stack>
      <ConfirmationModal
        modelData={openConfirmModal}
        open={openConfirmModal.modalOpen}
        data={content}
      />
    </Container>
  );
};

export default Integration;
