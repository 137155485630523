export const fnGetCustomerListData = (item) => {
  let fName;
  let lName;
  const obj = {};
  const customerName = item?.name || '';
  const isMetadata = item?.metadata && Object.keys(item?.metadata)?.length;
  if (customerName) {
    if (customerName.indexOf(' ') !== -1) {
      fName = customerName.substr(0, customerName.indexOf(' '));
      lName = customerName.substr(customerName.indexOf(' ') + 1);
    } else {
      fName = customerName;
    }
  }
  const metadataFirstName = item?.metadata?.first_name || '';
  const metadataLastName = item?.metadata?.last_name || '';
  const metadataBusinessName = item?.metadata?.business_name || '';

  const firstName =
    isMetadata && metadataFirstName ? metadataFirstName : fName ? fName : '';
  const lastName =
    isMetadata && metadataLastName ? metadataLastName : lName ? lName : '';
  const businessName =
    isMetadata && metadataBusinessName ? metadataBusinessName : '';

  obj.firstName = firstName;
  obj.lastName = lastName;
  obj.businessName = businessName;
  obj.displayName = item?.displayName || '';
  return obj;
};
