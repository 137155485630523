import { DialogContent, Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import PropTypes from 'prop-types';

import Images from '../../constants/images';
import RHFButton from '../button/RHFButton';
import Iconify from '../Iconify';
import ModalWrapper from './ModalWrapper';

const ConfirmationModal = (props) => {
  const { open, loading, data, modelData, maxWidth, fullWidth } = props;
  const { showIcon = true, renderButtons = true } = data;

  return (
    <ModalWrapper
      clearIcon={true}
      onClose={() => {
        data?.cancelButtonAction();
      }}
      isLoading={loading}
      fullWidth={fullWidth || false}
      maxWidth={maxWidth}
      open={open}
    >
      <DialogContent sx={{ pb: 0, px: 8 }}>
        {showIcon && (
          <Box justifyContent="center" display={'flex'} mb={2}>
            {data?.icon ? (
              data?.icon
            ) : (
              <img
                src={data?.image ? data?.image : Images.deleteIcon.filename}
                alt={Images.deleteIcon.alt}
              />
            )}
          </Box>
        )}

        <Stack textAlign={'center'}>
          <Typography variant="h4" mb={1}>
            {data?.title}
          </Typography>
          <Typography
            variant={
              data?.subtitle || data?.subtitle1 ? 'subtitle1' : 'subtitle2'
            }
            color={(theme) => theme.palette.grey['dark']}
            my={data?.subtitle ? 2 : 0}
          >
            {data?.bodyText || data?.subtitle1}
          </Typography>
        </Stack>
        {data?.subtitle ? (
          <Box display="flex" alignItems="center" mb={2} mt={1}>
            <Typography
              variant="body1"
              color={(theme) => theme.palette.grey['dark']}
            >
              {data?.subtitle}
            </Typography>
          </Box>
        ) : null}
        {data?.points
          ? data?.points?.map((ele) => (
              <Grid
                display="-webkit-box"
                alignItems="center"
                direction="row"
                key={ele}
                webkit-box-align="center"
              >
                <Iconify
                  height={20}
                  width={20}
                  icon={'mdi:tick-circle'}
                  color={(theme) => theme.palette.primary.main}
                />
                <Typography mx={2} my={0.5} variant="body2">
                  {ele}
                </Typography>
              </Grid>
            ))
          : null}
      </DialogContent>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        gap={2}
        sx={{ p: 3 }}
      >
        {renderButtons && (
          <>
            {data?.submitButtonAction ? (
              <RHFButton
                color={data?.submitButtonColor}
                title={data?.submitButtonTitle}
                variant={
                  data?.submitButtonVariant
                    ? data?.submitButtonVariant
                    : 'containedError'
                }
                onClick={() => data?.submitButtonAction(modelData)}
                isLoading={loading}
              />
            ) : null}
            <RHFButton
              title={data?.cancelButtonTitle}
              onClick={() => {
                if (data?.cancelButtonAction) {
                  data?.cancelButtonAction();
                }
              }}
              variant="outlined"
              color="inherit"
              disabled={loading}
            />
          </>
        )}
      </Stack>
    </ModalWrapper>
  );
};

ConfirmationModal.propTypes = {
  data: PropTypes.shape({
    Description: PropTypes.any,
    bodyText: PropTypes.any,
    cancelButtonAction: PropTypes.func,
    cancelButtonColor: PropTypes.any,
    cancelButtonTitle: PropTypes.any,
    cancelButtonVariant: PropTypes.string,
    icon: PropTypes.any,
    image: PropTypes.any,
    points: PropTypes.shape({
      map: PropTypes.func,
    }),
    renderButtons: PropTypes.bool,
    showIcon: PropTypes.bool,
    submitButtonAction: PropTypes.func,
    submitButtonColor: PropTypes.any,
    submitButtonIcon: PropTypes.any,
    submitButtonTitle: PropTypes.any,
    submitButtonVariant: PropTypes.string,
    subtext: PropTypes.any,
    subtitle: PropTypes.any,
    subtitle1: PropTypes.any,
    title: PropTypes.any,
  }),
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  loading: PropTypes.any,
  modelData: PropTypes.any,
  open: PropTypes.any,
  sx: PropTypes.any,
};

export default ConfirmationModal;
