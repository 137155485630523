import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} size={50} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${props?.value}/${props?.total}`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export function CircularWithValueLabel({ progressValue, totalRecord }) {
  return (
    <CircularProgressWithLabel value={progressValue} total={totalRecord} />
  );
}

CircularWithValueLabel.propTypes = {
  progressValue: PropTypes.number,
  totalRecord: PropTypes.number,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${props?.value}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export function LinearWithValueLabel({ progressValue, totalRecord }) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progressValue} total={totalRecord || 0} />
    </Box>
  );
}

LinearWithValueLabel.propTypes = {
  progressValue: PropTypes.number,
  totalRecord: PropTypes.number,
};
