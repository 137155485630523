import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';

export const CustomTooltip = styled(({ ...props }) => (
  <Tooltip {...props} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
