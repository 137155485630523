import { memo, useCallback, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import { FormProvider } from '../../../components/hook-form';
import SearchField from '../../../components/search/SearchField';
import { debounce } from '../../../redux/utils/common';

const PartnersFilter = ({ searchParams, setSearchParams }) => {
  const defaultValues = useMemo(() => {
    return {
      filter_partner_name: decodeURIComponent(
        searchParams.get('filter_partner_name')?.trim() || ''
      ),
    };
  }, [searchParams]);

  // Form methods
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  });
  const { control, watch, setValue } = methods;
  const searchValue = watch('filter_partner_name');

  const handleClear = useCallback(() => {
    setValue('filter_partner_name', '');
    searchParams.delete('filter_partner_name');
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: 0,
    });
  }, [searchParams, setSearchParams, setValue]);

  const handleSearch = useCallback(
    (e) => {
      debounce(() => {
        if (e?.target?.value?.trim()) {
          searchParams?.delete('page');
          setSearchParams({
            ...Object.fromEntries([...searchParams]),
            filter_partner_name: encodeURIComponent(e?.target?.value?.trim()),
          });
        } else {
          handleClear();
        }
      }, [500]);
    },
    [handleClear, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.has('filter_partner_name')) {
      setValue(
        'filter_partner_name',
        decodeURIComponent(searchParams.get('filter_partner_name')?.trim())
      );
    }
  }, [searchParams, setValue]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={(e) => e.preventDefault()}>
        <Grid
          container
          sx={{ p: 3 }}
          columnGap={2}
          display="flex"
          alignItems="center"
        >
          <Grid item xs={12} lg={8}>
            <Controller
              name="filter_partner_name"
              control={control}
              render={({ field: { ref, ...other } }) => {
                return (
                  <SearchField
                    {...other}
                    placeholder="Search Partner"
                    removeIcon={!!searchValue}
                    onRemove={handleClear}
                    handleSearch={handleSearch}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

PartnersFilter.propTypes = {
  searchParams: PropTypes.shape({
    delete: PropTypes.func,
    filter_partner_name: PropTypes.any,
    get: PropTypes.func,
    has: PropTypes.func,
  }),
  setSearchParams: PropTypes.func,
};

export default memo(PartnersFilter);
