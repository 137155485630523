import * as routes from '../routes/paths';

export const getCurrentPageName = (path) => {
  let foundObj;
  let fullPath;
  let currentPath;
  JSON.stringify(routes, (_, nestedValue) => {
    fullPath = nestedValue?.fullPath || nestedValue?.path;
    currentPath = path?.toString();

    if (fullPath?.includes(':')) {
      fullPath = fullPath?.split(':')[0];
    }

    if (nestedValue && currentPath?.includes(fullPath)) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  // TODO : we can use this to set page title in further development currently using Page in all component need to replace it
  if (foundObj) {
    document.title = foundObj?.title;
  }
  return foundObj;
};
