import { forwardRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import Iconify from '../Iconify';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalWrapper({
  open,
  title,
  children,
  sx,
  onClose,
  isLoading,
  clearIcon,
  titleStyle,
  titleIcon,
  ...other
}) {
  return (
    <Dialog open={open} TransitionComponent={Transition} sx={sx} {...other}>
      <DialogTitle
        variant="h5"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignSelf: !title ? 'end' : '',
          alignItems: 'center',
          p: 3,
          pb: 0,
          ...titleStyle,
        }}
      >
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          {titleIcon && (
            <Iconify
              icon={titleIcon}
              sx={{
                height: 30,
                width: 30,
                color: (theme) => theme?.palette?.grey.main,
              }}
            />
          )}
          <Typography variant="h5">
            {typeof title === 'function' ? title() : title}
          </Typography>
        </Stack>
        {clearIcon ? (
          <IconButton onClick={onClose} disabled={isLoading}>
            <ClearIcon sx={{ cursor: 'pointer' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
      {children}
    </Dialog>
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  clearIcon: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  title: PropTypes.any,
  titleStyle: PropTypes.object,
  titleIcon: PropTypes.string,
  isLoading: PropTypes.bool,
};
