import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UploadIllustration from '../../assets/illustration_upload';
import RejectionFiles from './RejectionFiles';

export default function BlockContent({ isImportFile, imageErrorMsg }) {
  const [t] = useTranslation('common', 'paid/member-dashboard');
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction={'column'}
      sx={{
        width: 1,
        height: 1,
        textAlign: { xs: 'center', md: 'center' },
        p: 2,
      }}
    >
      {imageErrorMsg?.length > 0 && (
        <RejectionFiles fileRejections={imageErrorMsg} />
      )}
      <UploadIllustration sx={{ width: 75 }} />

      <Box>
        <Typography variant="subtitle1">
          {isImportFile ? t('dropOrSelectFile') : t('dropOrSelectImage')}
        </Typography>
      </Box>
      <Box
        style={{
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        <Typography variant="caption">
          {isImportFile ? 'CSV(UTF-8)' : 'JPEG, PJPEG, PNG, X-PNG'}
        </Typography>
      </Box>
    </Stack>
  );
}

BlockContent.propTypes = {
  imageErrorMsg: PropTypes.any,
  isImportFile: PropTypes.bool,
};
