import { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';
import { _SYS_ADMIN_ROLES, _SYS_AGENT } from '../../constants/roles';
import useAuth from '../../hooks/useAuth';
import { UNAUTHORIZE_PATH, ADMIN, PAID_USER, ONBOARDING } from '../paths';

UnAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function UnAuthGuard({ children }) {
  const auth = useAuth();
  const routeState = useSelector((state) => state?.route);
  const setup = useSelector((state) => state?.setup);
  const plans = useSelector((state) => state?.plan?.plans);

  const setupRole = useMemo(
    () => setup?.profile?.role?.role,
    [setup?.profile?.role?.role]
  );

  const profile = useMemo(() => setup?.profile, [setup?.profile]);
  const isAdminRole = useMemo(() => {
    return _SYS_ADMIN_ROLES.some((val) => val === (auth?.role || setupRole));
  }, [auth?.role, setupRole]);

  const object = useMemo(() => {
    return {
      subscriptionStatus: profile?.companyDetails?.subscriptionStatus || '',
      status: profile?.companyDetails?.status || '',
      isPlanSelected: profile?.companyDetails?.currentPlan?._id || null,
      stripePaymentMethod: plans?.stripePaymentMethod || {},
      userPaymentMethod: setup?.payments?.stripeData?.paymentMethod || {},
    };
  }, [
    plans?.stripePaymentMethod,
    profile?.companyDetails?.currentPlan?._id,
    profile?.companyDetails?.status,
    profile?.companyDetails?.subscriptionStatus,
    setup?.payments?.stripeData?.paymentMethod,
  ]);

  if (!auth?.isInitialized) {
    return <LoadingScreen />;
  }
  if (auth?.isAuthenticated) {
    if (
      routeState?.prevRoute &&
      routeState?.prevRoute !== UNAUTHORIZE_PATH.SIGN_IN.path
    ) {
      return <Navigate to={routeState?.prevRoute} />;
    } else {
      if (isAdminRole) {
        if (auth?.role === _SYS_AGENT) {
          return <Navigate to={ADMIN?.COMPANIES.fullPath} />;
        } else {
          return <Navigate to={ADMIN?.root} />;
        }
      } else {
        if (
          object.stripePaymentMethod?.secondStatus !==
            'PAYMENT_UNCOLLECTIBLE' &&
          ((!object.isPlanSelected && object.isPlanSelected !== null) ||
            (object.subscriptionStatus &&
              object.subscriptionStatus.toLowerCase() === 'canceled') ||
            object.subscriptionStatus.toLowerCase() === 'inactive' ||
            (object.stripePaymentMethod &&
              Object.keys(object.stripePaymentMethod).length &&
              !object.stripePaymentMethod?.isActive))
        ) {
          // redirecting to manage plan page
          return <Navigate to={ONBOARDING.MANAGE_PLAN.path} />;
        } else {
          return <Navigate to={PAID_USER.path} />;
        }
      }
    }
  }

  return <>{children}</>;
}
