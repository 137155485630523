import { lazy } from 'react';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import NotistackProvider from './components/NotistackProvider';
import PublicPageWrapper from './components/PublicPageWrapper';
import { Loadable } from './components/RouteLazyLoading';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import i18n from './locales/i18n';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import '../node_modules/simplebar/src/simplebar.css';
import './index.scss';
import {
  ECWID_UNAUTHORIZED,
  GET_ECWID_CHECKOUT,
  PAYMENTS,
} from './routes/paths';
import ThemeProvider from './theme';

const CheckoutPaymentForm = Loadable(
  lazy(() =>
    import(
      './pages/client/payments/all-transactions/checkout-orders/public-checkout/CheckoutPaymentForm'
    )
  )
);

const PublicInvoice = Loadable(
  lazy(() =>
    import('./pages/client/payments/invoicing/components/PublicInvoice')
  )
);

const EcwidCheckoutForm = Loadable(
  lazy(() =>
    import(
      './pages/client/payments/store-orders/public-store-order/EcwidCheckoutForm'
    )
  )
);

const EcwidCheckoutUnAuthorized = Loadable(
  lazy(() =>
    import(
      './pages/client/payments/store-orders/public-store-order/EcwidCheckoutUnAuthorized'
    )
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18n}>
        <CollapseDrawerProvider>
          <ThemeProvider>
            <NotistackProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/*" element={<App />} />
                  <Route element={<PublicPageWrapper />}>
                    <Route
                      path={PAYMENTS.INVOICE_PREVIEW.path}
                      element={<PublicInvoice />}
                    />
                    <Route
                      path={PAYMENTS.CHECKOUT_PREVIEW.path}
                      element={<CheckoutPaymentForm />}
                    />
                    <Route
                      path={GET_ECWID_CHECKOUT.path}
                      element={<EcwidCheckoutForm />}
                    />
                  </Route>
                  <Route
                    path={ECWID_UNAUTHORIZED.path}
                    element={<EcwidCheckoutUnAuthorized />}
                  />
                </Routes>
              </BrowserRouter>
            </NotistackProvider>
          </ThemeProvider>
        </CollapseDrawerProvider>
      </I18nextProvider>
    </ReduxProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
