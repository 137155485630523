import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Image from '../../../../components/Image';
import { LinearWithValueLabel } from '../../../../components/upload/CustomProgressBar';
import { bytesToSize } from '../../../../constants';
import Images from '../../../../constants/images';

export const ImportProgress = ({
  uploadErrorMsg,
  defaultImportInfo,
  updateImportInfo,
}) => {
  const { isImporting, isValidating, progress, fileInfo } = useSelector(
    (state) => state.setup.profile.importInfo
  );
  const dispatch = useDispatch();

  return isValidating ? (
    <Card sx={{ borderRadius: 1 }}>
      <Stack p={1.9} spacing={1}>
        <Stack direction={'row'} spacing={2}>
          <Skeleton variant="rectangular" height={30} width={30} />
          <Stack spacing={1.3}>
            <Skeleton height={12} width={180} variant="rectangular" />
            <Skeleton height={12} width={160} variant="rectangular" />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  ) : (
    <Collapse
      in={!!(fileInfo && !uploadErrorMsg?.length)}
      timeout={'auto'}
      unmountOnExit
    >
      {fileInfo && !uploadErrorMsg?.length ? (
        <Card sx={{ borderRadius: 1 }}>
          <Stack p={1.5} spacing={1}>
            <Stack direction={'row'}>
              <Image
                src={Images?.csvIcon?.filename}
                alt={Images?.csvIcon?.alt}
                sx={{
                  width: 30,
                  mr: 2,
                }}
              />
              <Stack spacing={0.5} width={'100%'}>
                <Typography variant="caption" fontWeight={600}>
                  {fileInfo.path}
                </Typography>
                <Typography variant="caption">
                  {bytesToSize(fileInfo?.size)}
                </Typography>
              </Stack>
              {!isImporting && (
                <IconButton
                  onClick={() => {
                    dispatch(updateImportInfo(defaultImportInfo));
                  }}
                  disableRipple
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
            {isImporting && <LinearWithValueLabel progressValue={progress} />}
          </Stack>
        </Card>
      ) : null}
    </Collapse>
  );
};

ImportProgress.propTypes = {
  defaultImportInfo: PropTypes.object,
  updateImportInfo: PropTypes.func,
  uploadErrorMsg: PropTypes.array,
};
