import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import errorImage from '../../assets/errorImage.svg';
import Image from '../Image';
import BlockContent from './BlockContent';

const DropZoneStyle = styled('div')(({ theme, closeIconHover, disabled }) => {
  return {
    outline: 'none',
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.grey[0],
    border: `2px dashed ${theme.palette.grey[500_32]}`,
    ...(disabled
      ? {
          opacity: 0.72,
          cursor: 'auto',
        }
      : {
          '&:hover': {
            ...(closeIconHover === 'false' && {
              opacity: 0.72,
              cursor: 'pointer',
            }),
          },
        }),
  };
});

UploadSingleFile.propTypes = {
  accept: PropTypes.any,
  error: PropTypes.bool,
  errorMsg: PropTypes.func,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  imageErrorMsg: PropTypes.any,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

export default function UploadSingleFile({
  error = false,
  onRemove,
  file,
  helperText,
  sx,
  errorMsg,
  accept,
  imageErrorMsg,
  disabled,
  ...other
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    onFileDialogCancel: true,
    accept: accept
      ? accept
      : {
          'image/jpeg': [],
          'image/png': [],
          'image/pjpeg': [],
          'image/x-png': [],
        },
    disabled,
    ...other,
  });

  // Local state
  const [closeIconHover, setCloseIconHover] = useState('false');

  useEffect(() => {
    errorMsg(fileRejections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  return (
    <Box sx={{ width: 1, height: 1, ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        closeIconHover={closeIconHover}
        disabled={disabled}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '12% 0',
          }),
        }}
      >
        {file ? (
          <Box>
            <Image
              alt="file preview"
              src={isString(file) ? file : file.preview}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
                objectFit: 'contain',
              }}
              onError={(e) => {
                e.target.src = errorImage;
              }}
            />
            <IconButton
              onMouseEnter={() => setCloseIconHover('true')}
              onMouseLeave={() => setCloseIconHover('false')}
              size="small"
              onClick={(e) => onRemove(e, file)}
              sx={{
                top: 6,
                p: '2px',
                right: 6,
                visibility: disabled ? 'hidden' : 'visible',
                position: 'absolute',
                color: 'common.white',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                },
              }}
            >
              <CloseIcon sx={{ fontSize: 'small' }} />
            </IconButton>
          </Box>
        ) : (
          <>
            <input {...getInputProps()} />
            <BlockContent
              imageErrorMsg={imageErrorMsg}
              isImportFile={other?.name === 'csv'}
              {...other}
            />
          </>
        )}
      </DropZoneStyle>

      {helperText && helperText}
    </Box>
  );
}
