import Image from '../components/Image';
import Images from '../constants/images';

export const getCardIconByName = (card, customWidth, customHeight) => {
  if (card) {
    switch (card.toLowerCase()) {
      case 'amex':
        return (
          <Image
            src={Images.amexCardNew.filename}
            alt={Images.amexCardNew.alt}
            sx={{
              height: customHeight || '20px',
              width: customWidth || '20px',
              mr: 1,
            }}
          />
        );
      case 'discover':
        return (
          <Image
            src={Images.discoverCardNew.filename}
            alt={Images.discoverCardNew.alt}
            sx={{
              height: customHeight || '30px',
              width: customWidth || '30px',
              mr: 1,
            }}
          />
        );
      case 'visa':
        return (
          <Image
            src={Images.visaCardNew.filename}
            alt={Images.visaCardNew.alt}
            sx={{
              height: customHeight || '30px',
              width: customWidth || '30px',
              mr: 1,
            }}
          />
        );
      case 'mastercard':
        return (
          <Image
            src={Images.masterCardNew.filename}
            alt={Images.masterCardNew.alt}
            sx={{
              height: customHeight || '30px',
              width: customWidth || '30px',
              mr: 1,
            }}
          />
        );
      case 'diners':
        return (
          <Image
            src={Images.dinersCardNew.filename}
            alt={Images.dinersCardNew.alt}
            sx={{
              height: customHeight || '30px',
              width: customWidth || '30px',
              mr: 1,
            }}
          />
        );
      case 'jcb':
        return (
          <Image
            src={Images.jcbCardNew.filename}
            alt={Images.jcbCardNew.alt}
            sx={{
              height: customHeight || '30px',
              width: customWidth || '30px',
              mr: 1,
            }}
          />
        );
      case 'unionpay':
        return (
          <Image
            src={Images.unionCardNew.filename}
            alt={Images.unionCardNew.alt}
            sx={{
              height: customHeight || '30px',
              width: customWidth || '30px',
              mr: 1,
            }}
          />
        );
      default:
        return null;
    }
  } else {
    return '-';
  }
};

export const fnGetCardName = (card) => {
  if (card) {
    switch (card) {
      case 'amex':
        return 'American Express';
      case 'discover':
        return 'Discover';
      case 'visa':
        return 'Visa';
      case 'mastercard':
        return 'Master Card';
      case 'diners':
        return 'Diners Club';
      case 'jcb':
        return 'JCB';
      case 'unionpay':
        return 'UnionPay';
      default:
        return null;
    }
  } else {
    return '-';
  }
};

export const getMonthFromNumber = (number) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (number) {
    return months.find((d, i) => i + 1 === number);
  } else {
    return '-';
  }
};
