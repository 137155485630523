import { createSlice } from '@reduxjs/toolkit';

import { getAPIHeaders } from '../apiInterceptor';
import { authApi } from './authAction';

const initialState = {
  language: null,
  first_name: null,
  last_name: null,
  email: null,
  superUserToken: null,
  authenticated: false,
  token: null,
  serviceId: null,
  apiKey: null,
  role: '',
  callerId: null,
  sessionKey: null,
  redirectPath: null,
  partnerDetails: {
    isLoading: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => {
      getAPIHeaders({});
      return {};
    },
    setRoleSliceFn: (state, action) => {
      state.role = action?.payload?.role;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.partnerLogoByUrl.matchPending,
      (state) => {
        state.partnerDetails.isLoading = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.partnerLogoByUrl.matchFulfilled,
      (state, action) => {
        const partner = action.payload;
        return {
          ...state,
          partnerDetails: { isLoading: false, ...partner },
        };
      }
    );
    builder.addMatcher(
      authApi.endpoints.verify.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
    builder.addMatcher(
      authApi.endpoints.signIn.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
    builder.addMatcher(
      authApi.endpoints.resetPassword.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
    builder.addMatcher(
      authApi.endpoints.setPassword.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
    builder.addMatcher(
      authApi.endpoints.signInWithToken.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
    builder.addMatcher(
      authApi.endpoints.loginWithGoogle.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
    builder.addMatcher(
      authApi.endpoints.loginWithFacebook.matchFulfilled,
      (state, action) => {
        const authenticationResponse = action.payload.AuthenticationResponse;
        return { ...state, ...authenticationResponse };
      }
    );
  },
});

export const { resetAuthState, setRoleSliceFn } = authSlice.actions;
export default authSlice.reducer;
