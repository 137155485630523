import CloseIcon from '@mui/icons-material/Close';
import { Paper, IconButton, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
  onRemove: PropTypes.func,
};
export default function RejectionFiles({ fileRejections, onRemove }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mb: 2,
        width: '100%',
        position: 'relative',
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      <Typography variant="caption" sx={{ wordBreak: 'break-word' }}>
        {fileRejections?.[0]?.errors?.[0]?.message}
      </Typography>

      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onRemove && onRemove(e);
        }}
        sx={{
          top: '10%',
          p: '2px',
          right: '0.5%',
          position: 'absolute',
          color: 'common.white',
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
          },
        }}
      >
        <CloseIcon sx={{ fontSize: 'small' }} />
      </IconButton>
    </Paper>
  );
}
