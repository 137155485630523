import { useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const StyledTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    top: '-2px',
  },
  '& .MuiInputLabel-shrink': {
    top: '0px',
  },
});

const RHFTextField = ({
  name,
  handleIconClick,
  endText,
  toggleSwitch,
  toggleSwitchValue,
  handleToggleSwitch,
  toggleSwitchComponent,
  startAdornment,
  onChange,
  inputProps,
  disabled,
  type,
  addressName,
  maxLength,
  showZero = true,
  ...other
}) => {
  // Local States

  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext();

  const fields = { ...other };

  useEffect(() => {
    if (watch(name)) {
      trigger(name);
    }
  }, [addressName, name, trigger, watch]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <StyledTextField
            {...field}
            disabled={disabled}
            fullWidth
            error={!!(error || errors[addressName]?.message) && !disabled}
            sx={{
              '& input::placeholder': { fontSize: '14px' },
              '& .MuiOutlinedInput-input': {
                padding:
                  fields.multiline || other.size === 'small' ? 'auto' : '12px',
              },
            }}
            onChange={(e) => {
              if (
                !showZero &&
                typeof e?.target?.value === 'string' &&
                Number(e?.target?.value[0]) === 0
              ) {
                return;
              }
              if (onChange) {
                onChange(e.target.value);
              }
              if (type === 'number' && maxLength && e.target.value !== '') {
                field.onChange(
                  Number(e.target.value.substring(0, Number(maxLength)))
                );
              } else {
                field.onChange(e.target.value);
              }
            }}
            type={
              fields?.field === 'password' && !showPassword
                ? 'password'
                : type || 'text'
            }
            helperText={error?.message || errors[addressName]?.message}
            {...other}
            FormHelperTextProps={{
              sx: {
                marginX: 0,
              },
            }}
            InputProps={{
              inputProps: {
                ...inputProps,
                ...(fields?.field === 'password' && { maxLength: 48 }),
              },
              sx: {
                height:
                  fields.multiline || other.size === 'small' ? 'auto' : '48px',
                paddingRight: toggleSwitch ? 0 : 'auto',
              },
              startAdornment: startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
              endAdornment:
                fields.field === 'password' ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={
                        fields.field === 'password'
                          ? () => {
                              setShowPassword(!showPassword);
                            }
                          : handleIconClick
                      }
                      edge="end"
                    >
                      {fields?.field === 'password' && field?.value ? (
                        showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )
                      ) : (
                        fields?.icon && fields.icon
                      )}
                    </IconButton>
                  </InputAdornment>
                ) : endText ? (
                  <InputAdornment position="end">{endText}</InputAdornment>
                ) : fields?.icon ? (
                  <IconButton onClick={handleIconClick} edge="end">
                    {fields?.icon && fields.icon}
                  </IconButton>
                ) : toggleSwitch ? (
                  <InputAdornment position="end">
                    <ToggleButtonGroup
                      value={toggleSwitchValue}
                      exclusive
                      onChange={handleToggleSwitch}
                      sx={{
                        border: 'transparent',
                        height: '50px',
                      }}
                    >
                      {toggleSwitchComponent.map((obj, i) => (
                        <ToggleButton key={i} value={obj.value}>
                          {obj.icon}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </InputAdornment>
                ) : null,
            }}
          />
        );
      }}
    />
  );
};
RHFTextField.propTypes = {
  addressName: PropTypes.any,
  disabled: PropTypes.bool,
  endText: PropTypes.any,
  handleIconClick: PropTypes.any,
  handleToggleSwitch: PropTypes.any,
  inputProps: PropTypes.any,
  limitLength: PropTypes.any,
  maxLength: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  startAdornment: PropTypes.any,
  toggleSwitch: PropTypes.any,
  toggleSwitchComponent: PropTypes.array,
  toggleSwitchValue: PropTypes.any,
  type: PropTypes.string,
  showZero: PropTypes.bool,
};

export default RHFTextField;
