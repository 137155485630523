import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';
api.enhanceEndpoints({
  addTagTypes: ['getTransactionById', 'getStripeTransactions'],
});

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStripeTransactions: build.query({
      query: (filter) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getStripeTransactions.url,
        method: apiRoutes.PAYMENTS.endpoints.getStripeTransactions.method,
        params: filter,
      }),
      providesTags: ['getStripeTransactions'],
      transformResponse: (res) => {
        return res;
      },
    }),
    getAllStripeTransactions: build.query({
      query: (filter) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getAllStripeTransactions.url,
        method: apiRoutes.PAYMENTS.endpoints.getAllStripeTransactions.method,
        params: filter,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
    getTransactionById: build.query({
      query: (payload) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getTransactionById.url,
        method: apiRoutes.PAYMENTS.endpoints.getTransactionById.method,
        params: payload,
      }),
      providesTags: ['getTransactionById'],
      transformResponse: (res) => {
        return res;
      },
    }),
    downloadTransactionReceipt: build.query({
      query: (payload) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.viewTransactionReceipt.url,
        method: apiRoutes.PAYMENTS.endpoints.viewTransactionReceipt.method,
        params: payload,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
    sendStripeReceipt: build.mutation({
      query: (payload) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.sendStripeReceipt.url,
        method: apiRoutes.PAYMENTS.endpoints.sendStripeReceipt.method,
        body: payload,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
    syncTransactionsToRailz: build.mutation({
      query: (payload) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.syncTransactionsToRailz.url,
        method: apiRoutes.PAYMENTS.endpoints.syncTransactionsToRailz.method,
        body: payload,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetStripeTransactionsQuery,
  useLazyGetAllStripeTransactionsQuery,
  useGetTransactionByIdQuery,
  useLazyDownloadTransactionReceiptQuery,
  useSendStripeReceiptMutation,
  useSyncTransactionsToRailzMutation,
} = extendedApi;
