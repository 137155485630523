import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../components/RouteLazyLoading';

const Dashboard = Loadable(lazy(() => import('./dashboard/index')));

export default function Router() {
  return useRoutes([
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
