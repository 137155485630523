import { Suspense } from 'react';

import LoadingScreen from './LoadingScreen';

// eslint-disable-next-line react/display-name
export const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
