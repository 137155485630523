export const _SYS_ADMIN = 'sysAdmin';
export const _SYS_AGENT = 'sysAgent';
export const _SYS_EMPLOYEE = 'sysEmployee';
export const _SYS_MANAGER = 'sysManager';
export const _MEMBER_ADMIN = 'memberAdmin';
export const _MEMBER_MANAGER = 'memberManager';
export const _MEMBER_EMPLOYEE = 'memberEmployee';
export const _EMPLOYEE = 'employee';
export const _ADMIN = 'admin';
export const _AGENT = 'agent';
export const _GUEST = 'guest';
export const _SYS_ADMIN_ROLES = [
  _SYS_ADMIN,
  _SYS_AGENT,
  _SYS_EMPLOYEE,
  _SYS_MANAGER,
];
export const _MEMBER_ADMIN_ROLE = [_MEMBER_ADMIN, _MEMBER_MANAGER];
export const _MEMBER_ROLES = [_MEMBER_ADMIN, _MEMBER_MANAGER, _MEMBER_EMPLOYEE];
