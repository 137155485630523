import { useEffect } from 'react';

import './App.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import { AuthProvider } from './contexts/AuthContext';
import i18n from './locales/i18n';
import Routes from './routes';

function App() {
  useEffect(() => {
    i18n.on('initialized', async () => {
      await i18n.changeLanguage(localStorage.getItem('i18nextLng'));
    });
    return () => i18n.off('initialized');
  }, []);
  return (
    <AuthProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <NotistackProvider>
              <MotionLazyContainer>
                <Routes />
              </MotionLazyContainer>
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeLocalization>
      </ThemeColorPresets>
    </AuthProvider>
  );
}

export default App;
