import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../../components/RouteLazyLoading';
import { PAYMENTS } from '../../../../routes/paths';

const TransactionsList = Loadable(lazy(() => import('./TransactionsList')));
const TransactionInfo = Loadable(
  lazy(() => import('./section/TransactionInfo'))
);

export default function TransactionRoute() {
  return useRoutes([
    {
      index: true,
      element: <TransactionsList />,
    },
    {
      path: PAYMENTS.TRANSACTIONS_INFO.path,
      element: <TransactionInfo />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
