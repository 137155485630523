import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../../components/RouteLazyLoading';
import { PAYMENTS } from '../../../../routes/paths';

const CustomerPage = Loadable(lazy(() => import('./CustomerPage')));
const CustomerForm = Loadable(lazy(() => import('./sections/CustomerForm')));
const CustomerDetail = Loadable(
  lazy(() => import('./sections/CustomerDetail'))
);

export default function CustomersRoute() {
  return useRoutes([
    {
      index: true,
      element: <CustomerPage />,
    },
    {
      path: PAYMENTS.ADD_CUSTOMER.path,
      element: <CustomerForm />,
    },
    {
      path: PAYMENTS.EDIT_CUSTOMER.path,
      element: <CustomerForm />,
    },
    {
      path: PAYMENTS.CUSTOMER_DETAILS.path,
      element: <CustomerDetail />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
