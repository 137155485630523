import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { rowsPerPageOptions } from '../constants';

export default function useTable(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

  const [order, setOrder] = useState(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [rowsPerPage, setRowsPerPage] = useState(
    props?.defaultRowsPerPage || rowsPerPageOptions[0]
  );

  const [selected, setSelected] = useState(props?.defaultSelected || []);

  const onSort = (id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        sort: id,
        order: isAsc ? 'desc' : 'asc',
      });
    }
  };

  const onSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllRows = (checked, newSelecteds) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (searchParams.get('page') < 0) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: 1,
      });
    }
  }, [searchParams, setSearchParams]);

  return {
    order,
    page,
    orderBy,
    rowsPerPage,
    selected,

    setPage,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    setRowsPerPage,

    onSort,
    onChangePage,
    onChangeRowsPerPage,
  };
}
