import {
  _MEMBER_ADMIN,
  _MEMBER_EMPLOYEE,
  _MEMBER_MANAGER,
  _MEMBER_ROLES,
  _SYS_ADMIN,
  _SYS_AGENT,
  _SYS_EMPLOYEE,
  _SYS_MANAGER,
} from '../constants/roles';

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const ROOTS_PATH = '/';
export const ROOTS_DASHBOARD = '/dashboard';
export const ROOTS_PAYMENTS = '/payments';
const ROOTS_ADMIN = '/admin';
const ROOTS_WEB = '/web';

export const UNAUTHORIZE_PATH = {
  SIGN_IN: {
    path: '/sign-in',
    fullPath: '/sign-in',
    permissions: [],
    title: 'Log In',
    pageName: 'log-in',
  },
  SIGN_UP: {
    path: '/sign-up',
    fullPath: '/sign-up',
    permissions: [],
    title: 'Sign Up',
    pageName: 'sign-up',
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    fullPath: '/forgot-password',
    permissions: [],
    title: 'Forgot Password',
    pageName: 'forgot-password',
  },
  VERIFY_CODE: {
    path: '/verify/:code?',
    fullPath: '/verify/:code?',
    permissions: [],
    title: 'Paid | Verify Your',
    pageName: 'verify-code',
  },
  VERIFY_ACCOUNT: {
    path: '/account-verify/:code?',
    fullPath: '/account-verify/:code?',
    permissions: [],
    title: 'Verify Your',
    pageName: 'verify-code',
  },
  RESET_PASSWORD: {
    path: '/reset-password/:code?',
    fullPath: '/reset-password/:code?',
    permissions: [],
    title: 'Reset Password',
    pageName: 'reset-password',
  },
  SET_PASSWORD: {
    path: '/set-password/:code?',
    fullPath: '/set-password/:code?',
    permissions: [],
    title: 'Set Password',
    pageName: 'set-password',
  },
};

export const ONBOARDING = {
  MANAGE_PLAN: {
    path: '/manage-plan',
    fullPath: '/manage-plan',
    permissions: [_MEMBER_ADMIN, _MEMBER_MANAGER],
    title: 'Paid | Manage Plan',
    pageName: 'manage-plan',
    category: 'common',
  },
};

export const SHIPTIME_USER_AUTHENTICATING = {
  path: '/gate',
  fullPath: '/gate',
  permissions: [],
  title: 'Paid | Shiptime User Authenticating',
  pageName: 'shiptime-user-authenticating',
};

export const CLIENT = {
  MY_PROFILE: {
    path: '/profile',
    fullPath: '/profile',
    title: 'Paid | Profile',
    pageName: 'profile',
    category: 'COMMON',
  },
  SUBSCRIPTION_BILLING: {
    path: '/subscription-billing',
    fullPath: '/subscription-billing',
    title: 'Paid | Subscription & Billing',
    pageName: 'subscription-billing',
    category: 'common',
  },
  TEAM_USERS: {
    path: '/team/users',
    fullPath: '/team/users',
    title: 'Paid | Team Users',
    pageName: 'team-users',
    category: 'common',
  },
  ADD_TEAM_MEMBER: {
    path: '/user-form',
    fullPath: '/team/users/user-form',
    pageName: 'add-team-member',
    title: 'Paid | Add Team Member',
    category: 'common',
  },
  EDIT_TEAM_MEMBER: {
    path: '/user-form/edit/:id',
    fullPath: '/team/users/user-form/edit',
    pageName: 'edit-team-member',
    title: 'Paid | Edit Team Member',
    category: 'common',
  },
};

export const PAID_USER = {
  path: '/services',
  fullPath: '/services',
  permissions: _MEMBER_ROLES,
  title: 'Paid | Services',
  pageName: 'service',
  category: 'common',
};

export const PAID_ADMIN = {
  path: '/admin',
  fullPath: '/admin',
  permissions: [_SYS_ADMIN, _SYS_AGENT, _SYS_EMPLOYEE, _SYS_MANAGER],
  title: 'Paid | Admin',
  pageName: 'paid-admin',
};

export const PAYMENTS = {
  root: ROOTS_PAYMENTS,
  DASHBOARD: {
    path: '/dashboard',
    fullPath: path(ROOTS_PAYMENTS, '/dashboard'),
    permissions: [_MEMBER_ADMIN, _MEMBER_MANAGER, _MEMBER_EMPLOYEE],
    title: 'Payments | Dashboard',
    pageName: 'dashboard',
    category: 'payments',
  },
  ONBOARDING: {
    path: '/onboarding',
    fullPath: path(ROOTS_PAYMENTS, '/onboarding'),
    permissions: [_MEMBER_ROLES],
    title: 'Payments | Onboarding',
    pageName: 'onboarding',
    category: 'payments',
  },
  STRIPE_PAYMENT_FAILURE: {
    path: '/onboarding-failed',
    fullPath: path(ROOTS_PAYMENTS, '/onboarding-failed'),
    permissions: [_MEMBER_ADMIN, _MEMBER_MANAGER],
    title: 'Payments | Onboarding Failed',
    pageName: 'onboarding-failed',
    category: 'payments',
  },
  QUICK_CHARGE: {
    path: '/create-charge/quick-charge',
    fullPath: path(ROOTS_PAYMENTS, '/create-charge/quick-charge'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Create Quick Charge',
    pageName: 'create-quick-charge',
    category: 'payments',
  },
  CHECKOUT: {
    path: '/create-charge/checkout',
    fullPath: path(ROOTS_PAYMENTS, '/create-charge/checkout'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Create Checkout',
    pageName: 'create-checkout',
    category: 'payments',
  },
  EDIT_CHECKOUT: {
    path: '/edit-checkout/:checkoutId',
    fullPath: path(ROOTS_PAYMENTS, '/edit-checkout/:checkoutId'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Edit Checkout',
    pageName: 'edit-checkout',
    category: 'payments',
  },
  INVOICING: {
    path: '/create-charge/invoicing',
    fullPath: path(ROOTS_PAYMENTS, '/create-charge/invoicing'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Create Invoicing',
    pageName: 'create-invoicing',
    category: 'payments',
  },
  EDIT_INVOICING: {
    path: '/create-charge/invoicing/:id',
    fullPath: path(ROOTS_PAYMENTS, '/create-charge/invoicing/:id'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Edit Invoicing',
    pageName: 'edit-invoicing',
    category: 'payments',
  },
  SUBSCRIPTIONS: {
    path: '/create-charge/subscriptions',
    fullPath: path(ROOTS_PAYMENTS, '/create-charge/subscriptions'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Create Subscriptions',
    pageName: 'create-subscription',
    category: 'payments',
  },
  EDIT_SUBSCRIPTION: {
    path: '/create-charge/edit-subscription',
    fullPath: path(ROOTS_PAYMENTS, '/create-charge/edit-subscription'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Edit Subscription',
    pageName: 'edit-subscription',
    category: 'payments',
  },
  CHECKOUTS_ORDERS: {
    path: '/checkout-orders',
    fullPath: path(ROOTS_PAYMENTS, '/checkout-orders'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Checkouts Orders',
    pageName: 'checkout-orders',
    category: 'payments',
  },
  CHECKOUT_ORDER_DETAILS: {
    path: '/checkout-order-detail/:checkoutId',
    fullPath: path(ROOTS_PAYMENTS, '/checkout-order-detail/:checkoutId'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Checkouts Order Detail Page',
    pageName: 'checkouts-order-details',
    category: 'payments',
  },
  CUSTOMERS: {
    path: '/customers',
    fullPath: path(ROOTS_PAYMENTS, '/customers'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Customers',
    pageName: 'customers',
    category: 'payments',
  },
  ADD_CUSTOMER: {
    path: '/add-customer',
    fullPath: path(ROOTS_PAYMENTS, '/customers/add-customer'),
    pageName: 'add-customer',
    permissions: _MEMBER_ROLES,
    title: 'Payments | Add Customer',
    category: 'payments',
  },
  EDIT_CUSTOMER: {
    path: '/edit-customer/:id',
    fullPath: path(ROOTS_PAYMENTS, '/customers/edit-customer'),
    pageName: 'edit-customer',
    permissions: _MEMBER_ROLES,
    category: 'payments',
    title: 'Payments | Edit Customer',
  },
  CUSTOMER_DETAILS: {
    path: '/customer-detail/:id',
    fullPath: path(ROOTS_PAYMENTS, '/customers/customer-detail'),
    pageName: 'customer-detail',
    permissions: _MEMBER_ROLES,
    category: 'payments',
    title: 'Payments | Customer Detail',
  },
  CHECKOUTS: {
    path: '/checkouts',
    fullPath: path(ROOTS_PAYMENTS, '/checkouts'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Checkouts',
    pageName: 'checkouts',
    category: 'payments',
  },
  GENERATE_LINK: {
    path: '/checkouts/generate-link/:checkoutId',
    fullPath: path(ROOTS_PAYMENTS, '/checkouts/generate-link/:checkoutId'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Generate Checkout Link',
    pageName: 'generate-link',
    category: 'payments',
  },
  CHECKOUT_PREVIEW: {
    path: '/checkout-page/:checkoutId',
    fullPath: path(ROOTS_PAYMENTS, '/checkout-page/:checkoutId'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Checkouts Page',
    pageName: 'checkouts',
    category: 'payments',
  },
  INVOICE_PREVIEW: {
    path: '/pay-invoice/:token?',
    fullPath: path(ROOTS_PAYMENTS, '/pay-invoice/:token?'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Invoice Page',
    pageName: 'invoice',
    category: 'payments',
  },
  PRODUCTS: {
    path: '/products',
    fullPath: path(ROOTS_PAYMENTS, '/products'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Products',
    pageName: 'products',
    category: 'payments',
  },
  EDIT_PRODUCT: {
    path: '/edit-product/:id',
    fullPath: path(ROOTS_PAYMENTS, '/products/edit-product/:id'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Edit Product',
    pageName: 'edit-product',
    category: 'payments',
  },
  ADD_PRODUCT: {
    path: '/add-product',
    fullPath: path(ROOTS_PAYMENTS, '/products/add-product'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Add Product',
    pageName: 'add-product',
    category: 'payments',
  },
  LOCATIONS: {
    path: '/readers-locations',
    fullPath: path(ROOTS_PAYMENTS, '/readers-locations'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Readers Locations',
    pageName: 'readers-locations',
    category: 'payments',
  },
  LOCATION_INFO: {
    path: '/readers-locations/:id',
    fullPath: path(ROOTS_PAYMENTS, '/readers-locations'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Location Info',
    pageName: 'location-info',
    category: 'payments',
  },
  ORDERS: {
    path: '/hardware-orders',
    fullPath: path(ROOTS_PAYMENTS, '/hardware-orders'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Hardware Orders',
    pageName: 'hardware-orders',
    category: 'payments',
  },
  HARDWARE_SHOP: {
    path: '/hardware-shop',
    fullPath: path(ROOTS_PAYMENTS, '/hardware-shop'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Hardware Shop',
    pageName: 'hardware-shop',
    category: 'payments',
  },
  HARDWARE_DETAILS: {
    path: '/hardware-shop/:id',
    permissions: _MEMBER_ROLES,
    title: 'Payments | Hardware Details',
    pageName: 'hardware-details',
    category: 'payments',
  },
  HARDWARE_CHECKOUT: {
    path: '/hardware-checkout',
    fullPath: path(ROOTS_PAYMENTS, '/hardware-checkout'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Hardware Checkout',
    pageName: 'hardware-checkout',
    category: 'payments',
  },
  ORDER_INFO: {
    path: '/order-info/:id',
    fullPath: path(ROOTS_PAYMENTS, '/order-info/:id'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Order Info',
    pageName: 'order-info',
    category: 'payments',
  },
  INTEGRATION: {
    path: '/accounting/integration',
    fullPath: path(ROOTS_PAYMENTS, '/accounting/integration'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Integration',
    pageName: 'integration',
    category: 'payments',
  },
  RAILINTEGRATION: {
    path: '/accounting/connect',
    fullPath: path(ROOTS_PAYMENTS, '/accounting/connect'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Integration',
    pageName: 'integration',
    category: 'payments',
  },
  BANK_ACCOUNTS: {
    path: '/bank-accounts',
    fullPath: path(ROOTS_PAYMENTS, '/bank-accounts'),
    title: 'Payments | Bank Accounts',
    permissions: _MEMBER_ROLES,
    pageName: 'bank-accounts',
    category: 'payments',
  },
  PAYOUTS: {
    path: '/payouts',
    fullPath: path(ROOTS_PAYMENTS, '/payouts'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Payouts',
    pageName: 'payouts',
    category: 'payments',
  },
  PAYOUT_DETAIL: {
    path: '/payouts/payout-detail/:payoutId',
    fullPath: path(ROOTS_PAYMENTS, '/payouts/payout-detail'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Payout Detail',
    pageName: 'payout-detail',
    category: 'payments',
  },
  TRANSACTIONS: {
    root: path(ROOTS_PAYMENTS, '/transactions'),
    fullPath: path(ROOTS_PAYMENTS, '/transactions'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Transactions',
    pageName: 'transactions',
    category: 'payments',
  },
  SUBMIT_EVIDENCE: {
    path: '/transactions/submit-evidence/:id',
    fullPath: path(ROOTS_PAYMENTS, '/transactions/submit-evidence'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Submit-Evidence',
    pageName: 'submit-evidence',
    category: 'payments',
  },
  SUBSCRIPTIONS_LIST: {
    path: '/all-transactions/subscriptions',
    fullPath: path(ROOTS_PAYMENTS, '/all-transactions/subscriptions'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Subscriptions',
    pageName: 'subscriptions',
    category: 'payments',
  },
  INVOICES: {
    path: '/all-transactions/invoices',
    fullPath: path(ROOTS_PAYMENTS, '/all-transactions/invoices'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Invoices',
    pageName: 'invoices',
    category: 'payments',
  },
  INVOICE_INFO: {
    path: '/all-transactions/transactions/invoice-info/:id',
    fullPath: path(
      ROOTS_PAYMENTS,
      '/all-transactions/transactions/invoice-info'
    ),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Invoice Info',
    pageName: 'invoice-info',
    category: 'payments',
  },
  TRANSACTIONS_INFO: {
    path: '/transaction-info',
    fullPath: path(ROOTS_PAYMENTS, '/transactions/transaction-info'),
    pageName: 'transaction-info',
    permissions: _MEMBER_ROLES,
    title: 'Payments | Transaction Info ',
    category: 'payments',
  },
  STORE_ORDERS: {
    path: '/store-orders',
    fullPath: path(ROOTS_PAYMENTS, '/store-orders'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Store-Orders',
    pageName: 'store-orders',
    category: 'payments',
  },
  DISPUTES: {
    path: '/all-transactions/disputes',
    fullPath: path(ROOTS_PAYMENTS, '/all-transactions/disputes'),
    title: 'Payments | Disputes',
    permissions: _MEMBER_ROLES,
    pageName: 'disputes',
    category: 'payments',
  },
  DISPUTE_INFO: {
    path: '/dispute-info/:id',
    fullPath: path(ROOTS_PAYMENTS, '/all-transactions/disputes/dispute-info'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Dispute Info',
    pageName: 'dispute-info',
    category: 'payments',
  },
  REPORTS_PAYMENTS: {
    path: '/reports',
    fullPath: path(ROOTS_PAYMENTS, '/reports'),
    title: 'Payments | Reports',
    permissions: _MEMBER_ROLES,
    pageName: 'reports',
    category: 'payments',
  },
  TAXES: {
    path: '/taxes',
    fullPath: path(ROOTS_PAYMENTS, '/taxes'),
    permissions: _MEMBER_ROLES,
    title: 'Payments | Taxes & Service Fees',
    pageName: 'taxes&serviceFees',
    category: 'payments',
  },
  PROFILE: {
    path: '/profile',
    fullPath: path(ROOTS_PAYMENTS, '/profile'),
    title: 'Payments | Profile',
    permissions: _MEMBER_ROLES,
    pageName: 'profile',
    category: 'payments',
  },
  STORE_ORDER_DETAIL: {
    path: '/store-orders/:orderId',
    fullPath: path(ROOTS_PAYMENTS, '/store-orders/:orderId'),
    permissions: [_MEMBER_ROLES],
    title: 'Payments | StoreOrder Detail',
    pageName: 'store-orders',
    category: 'payments',
  },
};

export const ADMIN = {
  root: ROOTS_ADMIN,
  permissions: [_SYS_ADMIN, _SYS_EMPLOYEE, _SYS_MANAGER],
  HOME: {
    path: ROOTS_ADMIN,
    fullPath: ROOTS_ADMIN,
    permissions: [_SYS_ADMIN, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Home',
    pageName: 'Home',
    category: 'admin',
  },
  COMPANIES: {
    path: '/companies',
    fullPath: path(ROOTS_ADMIN, '/companies'),
    permissions: [_SYS_ADMIN, _SYS_AGENT, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Companies',
    pageName: 'companies',
    category: 'admin',
  },
  COMPANY_INFO: {
    path: '/companies/:id',
    fullPath: path(ROOTS_ADMIN, '/companies/:id'),
    permissions: [_SYS_ADMIN, _SYS_AGENT, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Company Information',
    pageName: 'company-information',
    category: 'admin',
  },
  LINKS: {
    path: '/links',
    fullPath: path(ROOTS_ADMIN, '/links'),
    permissions: [_SYS_ADMIN, _SYS_AGENT, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Links',
    pageName: 'links',
    category: 'admin',
  },

  USERS: {
    path: '/clients',
    fullPath: path(ROOTS_ADMIN, '/clients'),
    permissions: [_SYS_ADMIN, _SYS_AGENT, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Users',
    pageName: 'clients',
    category: 'admin',
  },
  CLIENT_DETAILS: {
    path: '/client-detail',
    fullPath: path(ROOTS_ADMIN, '/clients/client-detail'),
    pageName: 'client-detail',
    permissions: [_SYS_ADMIN, _SYS_AGENT, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Client Detail',
    category: 'admin',
  },
  PARTNERS: {
    path: '/partners',
    fullPath: path(ROOTS_ADMIN, '/partners'),
    permissions: [_SYS_ADMIN, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Partners',
    pageName: 'partners',
    category: 'admin',
    ADD: {
      path: '/partners/partner-form',
      fullPath: path(ROOTS_ADMIN, '/partners/partner-form'),
      permissions: [_SYS_ADMIN],
      title: 'Add Partner',
      pageName: 'add-partner',
      category: 'admin',
    },
    EDIT: {
      path: '/partners/partner-form/edit/:id',
      fullPath: path(ROOTS_ADMIN, '/partners/partner-form/edit'),
      permissions: [_SYS_ADMIN],
      title: 'Edit Partner',
      pageName: 'edit-partner',
      category: 'admin',
    },
  },
  DASHBOARD: {
    path: '/dashboard',
    fullPath: path(ROOTS_ADMIN, '/dashboard'),
    permissions: [_SYS_ADMIN],
    title: 'Admin',
    pageName: 'dashboard',
    category: 'admin',
  },
  ADD_PLAN: {
    path: '/addPlan',
    fullPath: path(ROOTS_ADMIN, '/addPlan'),
    permissions: [_SYS_ADMIN],
    title: 'Add Plan',
    pageName: 'add-plan',
    category: 'admin',
  },
  EDIT_PLAN: {
    path: '/editPlan/:planId',
    fullPath: path(ROOTS_ADMIN, '/editPlan/:planId'),
    permissions: [_SYS_ADMIN],
    title: 'Edit Plan',
    pageName: 'edit-plan',
    category: 'admin',
  },
  REPORTS: {
    path: '/reports-ecwid',
    fullPath: path(ROOTS_ADMIN, '/reports-ecwid'),
    ECWID: {
      path: '/reports-ecwid',
      fullPath: path(ROOTS_ADMIN, '/reports-ecwid'),
      permissions: [_SYS_ADMIN],
      title: 'Reports Ecwid',
      pageName: 'reports-ecwid',
      category: 'admin',
    },
    permissions: [_SYS_ADMIN],
    title: 'Reports',
    pageName: 'reports',
    category: 'admin',
  },
  SUPPORT: {
    path: '/support-feedback',
    fullPath: path(ROOTS_ADMIN, '/support-feedback'),
    SUPPORT_FEEDBACK: {
      path: '/support-feedback',
      fullPath: path(ROOTS_ADMIN, '/support-feedback'),
      permissions: [_SYS_ADMIN, _SYS_EMPLOYEE, _SYS_MANAGER],
      title: 'Support & Feedback',
      pageName: 'support-feedback',
      category: 'admin',
    },
    permissions: [_SYS_ADMIN, _SYS_EMPLOYEE, _SYS_MANAGER],
    title: 'Support & Feedback',
    pageName: 'support-feedback',
    category: 'admin',
  },
  PERKS: {
    path: '/perks',
    fullPath: path(ROOTS_ADMIN, '/perks'),
    permissions: [_SYS_ADMIN],
    title: 'Perks',
    pageName: 'perks',
    category: 'admin',
  },
  ADD_PERK: {
    path: '/add-perk',
    fullPath: path(ROOTS_ADMIN, '/perks/add-perk'),
    permissions: [_SYS_ADMIN],
    title: 'Add Perk',
    pageName: 'add-perk',
    category: 'admin',
  },
  EDIT_PERK: {
    path: '/edit-perk/:perkId',
    fullPath: path(ROOTS_ADMIN, '/perks/edit-perk/:perkId'),
    permissions: [_SYS_ADMIN],
    title: 'Edit Perk',
    pageName: 'edit-perk',
    category: 'admin',
  },
};

export const WEB = {
  root: ROOTS_WEB,
  webSites: {
    path: '/sites',
    fullPath: path(ROOTS_WEB, '/sites'),
    permissions: [_MEMBER_ADMIN, _MEMBER_MANAGER, _MEMBER_EMPLOYEE],
    title: 'Web | Sites',
    pageName: 'sites',
    category: 'web',
  },
  webTemplates: {
    path: '/templates',
    fullPath: path(ROOTS_WEB, '/templates'),
    permissions: [_MEMBER_ADMIN, _MEMBER_MANAGER, _MEMBER_EMPLOYEE],
    title: 'Web | Templates',
    pageName: 'templates',
    category: 'web',
  },
};

export const SHIPPING = {
  dashboard: {
    path: '/shipping/admin',
    permissions: [_MEMBER_ADMIN, _MEMBER_MANAGER, _MEMBER_EMPLOYEE],
    title: 'Shipping | Dashboard',
    pageName: 'shipping-dashboard',
    category: 'shipping',
  },
};

export const REDIRECTING = {
  path: '/redirecting',
  permissions: [],
  title: 'Paid | Redirecting',
  pageName: 'redirecting',
};

export const GET_ECWID_CHECKOUT = {
  path: '/store-checkout/payment',
  permissions: [],
  title: 'Paid | Checkout By Paid',
  pageName: 'pay-ecwid-checkout',
};
export const ECWID_UNAUTHORIZED = {
  path: '/store-checkout/payment-fail',
  permissions: [],
  title: 'Paid | Unauthorized Request',
  pageName: 'ecwid-unauthorized',
};
