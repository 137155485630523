import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../../components/RouteLazyLoading';
import { PAYMENTS } from '../../../../routes/paths';

const DisputePage = Loadable(lazy(() => import('./DisputePage')));
const DisputeInfo = Loadable(lazy(() => import('./sections/DisputeInfo')));

export default function DisputesRoute() {
  return useRoutes([
    {
      index: true,
      element: <DisputePage />,
    },
    {
      path: PAYMENTS.DISPUTE_INFO.path,
      element: <DisputeInfo />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
