import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';
import { updateCacheData } from '../utils/common';

api.enhanceEndpoints({
  addTagTypes: ['getPaymentMethods'],
});

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    addStripePaymentMethod: build.mutation({
      query: ({ obj, result }) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.stripePaymentMethod.url,
        method: apiRoutes.PAYMENTS.endpoints.stripePaymentMethod.method,
        body: obj,
      }),
      invalidatesTags: (res) =>
        res ? ['allCustomers', 'getSingleCustomer', 'getPaymentMethods'] : [],
      transformResponse: (res) => {
        return res;
      },
    }),

    updateStripePaymentMethod: build.mutation({
      query: (data) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.stripeUpdatePaymentMethod.url,
        method: apiRoutes.PAYMENTS.endpoints.stripeUpdatePaymentMethod.method,
        body: data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        const { data } = await queryFulfilled;
        updateCacheData(
          {
            state: getState(),
            method: 'getStripeSubscription',
            dispatch,
            extendedApi,
          },
          (draft) => {
            const updateData = { ...data, stripeSubscription: data?.result };
            delete updateData?.result;
            draft = updateData;
            return draft;
          }
        );
      },
    }),

    stripeOnBoarding: build.mutation({
      query: (data) => ({
        url:
          apiRoutes?.PAYMENTS?.basePath +
          apiRoutes?.PAYMENTS?.endpoints?.stripeOnBoarding?.url,
        method: apiRoutes?.PAYMENTS?.endpoints?.stripeOnBoarding?.method,
        body: data,
      }),
    }),

    dismissNotification: build.mutation({
      query: (notificationId) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.dismissNotification.url,
        method: apiRoutes.PAYMENTS.endpoints.dismissNotification.method,
        params: { notificationId },
      }),
    }),
    stripePaymentsVerification: build.mutation({
      query: (data) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.stripeAccountVerification.url,
        method: apiRoutes.PAYMENTS.endpoints.stripeAccountVerification.method,
        body: data,
      }),
    }),
  }),
});

export const {
  useAddStripePaymentMethodMutation,
  useUpdateStripePaymentMethodMutation,
  useDismissNotificationMutation,
  useStripeOnBoardingMutation,
  useStripePaymentsVerificationMutation,
} = extendedApi;

export const {
  endpoints: { payments },
} = extendedApi;
