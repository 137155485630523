import { socket } from '../../components/socket/Socket';
import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';
import { updateCacheData } from '../utils/common';

api.enhanceEndpoints({
  addTagTypes: [
    'customer',
    'allCustomers',
    'getPaymentMethods',
    'getCustomers',
    'getSingleCustomer',
    'getAllCategories',
  ],
});

export const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCustomers: build.query({
      query: (filter) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getAllCustomers.url,
        method: apiRoutes.PAYMENTS.endpoints.getAllCustomers.method,
        params: filter,
      }),
      providesTags: ['allCustomers'],
      transformResponse: (res) => {
        return res?.customers;
      },
    }),
    getSingleCustomer: build.query({
      query: (filter) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getAllCustomers.url,
        method: apiRoutes.PAYMENTS.endpoints.getAllCustomers.method,
        params: filter,
      }),
      providesTags: ['getSingleCustomer'],
      transformResponse: (res) => {
        return res?.customers;
      },
    }),
    getCustomers: build.query({
      query: (filter) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.customers.url,
        method: apiRoutes.PAYMENTS.endpoints.customers.method,
        params: filter,
      }),
      providesTags: ['customer', 'getCustomers'],
      transformResponse: (res) => {
        return {
          customers: {
            allCustomers: res?.customers?.data,
            totalCustomers: res?.totalCustomers,
          },
        };
      },
      // Added socket for update customer cache
      async onCacheEntryAdded(
        args,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          await cacheDataLoaded;
          socket.on('STRIPE_CUSTOMER_UPDATE', (data) => {
            updateCachedData((draft) => {
              const index = draft?.customers?.allCustomers?.findIndex(
                (item) => {
                  return item?.customerId === data?.customerId;
                }
              );
              if (index !== -1) {
                draft?.customers?.allCustomers?.splice(index, 1, data);
              }
            });
          });
        } catch (err) {
          return err;
        }
        await cacheEntryRemoved;
      },
    }),
    addCustomers: build.mutation({
      query: (body) => {
        return {
          url:
            apiRoutes.PAYMENTS.basePath +
            apiRoutes.PAYMENTS.endpoints.AddCustomers.url,
          method: apiRoutes.PAYMENTS.endpoints.AddCustomers.method,
          body,
        };
      },
      invalidatesTags: (res) => (res ? ['customer', 'allCustomers'] : []),
      transformResponse: (res) => {
        return res;
      },
    }),
    importCustomers: build.mutation({
      query: (body) => {
        return {
          url:
            apiRoutes.PAYMENTS.basePath +
            apiRoutes.PAYMENTS.endpoints.importCustomers.url,
          method: apiRoutes.PAYMENTS.endpoints.importCustomers.method,
          body,
        };
      },
      invalidatesTags: (res) =>
        res ? ['customer', 'allCustomers', 'getAllCategories'] : [],
      transformResponse: (res) => {
        return res;
      },
    }),
    editCustomers: build.mutation({
      query: (body) => {
        return {
          url:
            apiRoutes.PAYMENTS.basePath +
            apiRoutes.PAYMENTS.endpoints.EditCustomers.url,
          method: apiRoutes.PAYMENTS.endpoints.EditCustomers.method,
          body,
        };
      },
      invalidatesTags: (res, err) =>
        res
          ? ['customer', 'allCustomers', 'getCustomers', 'getSingleCustomer']
          : [],

      transformResponse: (res) => {
        return res;
      },
    }),
    deleteCustomer: build.mutation({
      query: ({ accountId, customer }) => {
        return {
          url:
            apiRoutes.PAYMENTS.basePath +
            apiRoutes.PAYMENTS.endpoints.deleteCustomer.url,
          method: apiRoutes.PAYMENTS.endpoints.deleteCustomer.method,
          params: { accountId, customer },
        };
      },
      invalidatesTags: (result) => (result ? ['customer', 'allCustomers'] : []),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getCustomers',
              dispatch,
              extendedApi,
            },

            (draft) => {
              const index = draft.customers.allCustomers.findIndex((item) => {
                return arg?.customer !== item._id;
              });
              draft?.customers?.allCustomers?.splice(index, 1);
              draft.customers.totalCount -= arg.length;
            }
          );
        } catch (error) {
          return;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    getRecentTransactions: build.query({
      query: (params) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getCustomersRecentTransactions.url,
        method:
          apiRoutes.PAYMENTS.endpoints.getCustomersRecentTransactions.method,
        params,
      }),
      transformResponse: (res) => {
        return res;
      },
    }),
    getPaymentMethods: build.query({
      query: (params) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getCustomersPaymentMethod.url,
        method: apiRoutes.PAYMENTS.endpoints.getCustomersPaymentMethod.method,
        params,
      }),
      providesTags: ['getPaymentMethods'],
      transformResponse: (res) => {
        return { customersPaymentMethods: res?.paymentMethodsData?.data };
      },
    }),
    editDefaultPaymentMethods: build.mutation({
      query: (payload) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.changeDefaultPaymentMethod.url,
        method: apiRoutes.PAYMENTS.endpoints.changeDefaultPaymentMethod.method,
        body: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        // update payment method of customer's cache
        updateCacheData(
          {
            state: getState(),
            method: 'getCustomers',
            dispatch,
            extendedApi,
          },
          (draft) => {
            const index = draft?.customers?.allCustomers?.findIndex((item) => {
              return item.customerId === args.customer;
            });
            const customer = draft?.customers?.allCustomers[index];
            const paymentMethod = {
              ...draft?.customers?.allCustomers[index]?.paymentMethod,
              id: args?.paymentMethodId,
            };
            if (index !== -1) {
              draft?.customers?.allCustomers?.splice(index, 1, {
                ...customer,
                paymentMethod,
              });
            }
          }
        );

        // update paymentMethod cache
        updateCacheData(
          {
            state: getState(),
            method: 'getPaymentMethods',
            dispatch,
            extendedApi,
          },
          (draft) => {
            const index = draft?.customersPaymentMethods?.findIndex((item) => {
              delete item.default;
              return item.id === args.paymentMethodId;
            });
            const paymentMethod = {
              ...draft?.customersPaymentMethods[index],
              id: args?.paymentMethodId,
              default: true,
            };
            if (index !== -1) {
              draft?.customersPaymentMethods?.splice(index, 1, paymentMethod);
            }
          }
        );
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    deletePaymentMethod: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.deletePaymentMethod.url,
        method: apiRoutes.PAYMENTS.endpoints.deletePaymentMethod.method,
        params,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        updateCacheData(
          {
            state: getState(),
            method: 'getPaymentMethods',
            dispatch,
            extendedApi,
          },
          (draft) => {
            const index = draft?.customersPaymentMethods?.findIndex((item) => {
              return item.id === args.paymentMethodId;
            });
            draft?.customersPaymentMethods?.splice(index, 1);
          }
        );
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    addPaymentMethod: build.mutation({
      query: (body) => {
        return {
          url:
            apiRoutes.PAYMENTS.basePath +
            apiRoutes.PAYMENTS.endpoints.addPaymentMethod.url,
          method: apiRoutes.PAYMENTS.endpoints.addPaymentMethod.method,
          body,
        };
      },
    }),
    editPaymentMethod: build.mutation({
      query: (body) => {
        return {
          url:
            apiRoutes.PAYMENTS.basePath +
            apiRoutes.PAYMENTS.endpoints.updateCustomerPaymentMethod.url,
          method:
            apiRoutes.PAYMENTS.endpoints.updateCustomerPaymentMethod.method,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getPaymentMethods',
              dispatch,
              extendedApi,
            },
            (draft) => {
              const index = draft?.customersPaymentMethods?.findIndex(
                (item) => {
                  return item.id === args.paymentMethod;
                }
              );
              draft?.customersPaymentMethods?.splice(index, 1, data?.data);
            }
          );
        } catch (err) {
          return err;
        }
      },
      transformResponse: (res) => {
        return res;
      },
    }),
    getAllPOSTransactions: build.query({
      query: (params) => ({
        url:
          apiRoutes.PAYMENTS.basePath +
          apiRoutes.PAYMENTS.endpoints.getAllPOSTransactions.url,
        method: apiRoutes.PAYMENTS.endpoints.getAllPOSTransactions.method,
        params: params,
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useLazyGetAllCustomersQuery,
  useAddCustomersMutation,
  useEditCustomersMutation,
  useDeleteCustomerMutation,
  useGetRecentTransactionsQuery,
  useGetPaymentMethodsQuery,
  useImportCustomersMutation,
  useEditDefaultPaymentMethodsMutation,
  useDeletePaymentMethodMutation,
  useAddPaymentMethodMutation,
  useEditPaymentMethodMutation,
  useGetAllCustomersQuery,
  useGetSingleCustomerQuery,
  useGetAllPOSTransactionsQuery,
} = extendedApi;
