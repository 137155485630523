import { api } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';

export const setupApi = api.injectEndpoints({
  endpoints: (build) => ({
    setup: build.mutation({
      query: () => ({
        url: apiRoutes.PAID.basePath + apiRoutes.PAID.endpoints.setup.url,
        method: apiRoutes.PAID.endpoints.setup.method,
      }),
    }),
  }),
});

export const { useSetupMutation } = setupApi;

export const {
  endpoints: { setup },
} = setupApi;
