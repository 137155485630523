import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../components/RouteLazyLoading';
import SettingsBasedGuard from '../../../routes/guards/SettingsBasedGuard';
import { PAYMENTS, ROOTS_DASHBOARD } from '../../../routes/paths';
import PayoutDetail from './banking/payout/PayoutDetail';
import CustomersRoute from './customers/customersRoutes';
import DisputesRoute from './dispute/disputeRoute';
import ProductServicesRouter from './products-services/productServicesRoutes';
import Integration from './railz-connect/Integration';
import RailzConnect from './railz-connect/RailzConnect';
import TransactionRoute from './transactions/transactionRoute';

const HardwareOrders = Loadable(
  lazy(() => import('./readers/hardware-orders/HardwareOrders'))
);
const HardwareShop = Loadable(
  lazy(() => import('./readers/hardware-orders/HardwareShop'))
);
const HardwareDetails = Loadable(
  lazy(() => import('./readers/hardware-orders/HardwareDetails'))
);
const HardwareCheckout = Loadable(
  lazy(() => import('./readers/hardware-orders/HardwareCheckout'))
);
const HardwareOrderInfo = Loadable(
  lazy(() => import('./readers/hardware-orders/HardwareOrderInfo'))
);
const Locations = Loadable(lazy(() => import('./readers/locations/Locations')));
const LocationInfo = Loadable(
  lazy(() => import('./readers/locations/LocationInfo'))
);
const Dashboard = Loadable(lazy(() => import('./dashboard/index')));
const Onboarding = Loadable(lazy(() => import('./onboarding/index')));
const QuickCharge = Loadable(lazy(() => import('./quick-charge/quickCharge')));
const CheckoutList = Loadable(lazy(() => import('./checkout/CheckoutList')));
const CheckoutForm = Loadable(
  lazy(() => import('./checkout/add-edit-checkouts/CheckoutForm'))
);
const GenerateCheckoutLink = Loadable(
  lazy(() => import('./checkout/GenerateCheckoutLink'))
);
const CheckoutOrdersList = Loadable(
  lazy(() =>
    import('../payments/all-transactions/checkout-orders/CheckoutOrdersList')
  )
);
const CheckoutOrderDetails = Loadable(
  lazy(() =>
    import('../payments/all-transactions/checkout-orders/CheckoutOrderDetails')
  )
);
const Transactions = Loadable(
  lazy(() => import('./transactions/TransactionsList'))
);
const Invoicing = Loadable(lazy(() => import('./invoicing/invoicing')));
const Subscriptions = Loadable(
  lazy(() => import('./subscriptions/subscriptions'))
);
const Taxes = Loadable(lazy(() => import('./taxes-services/TaxesAndFees')));
const Profile = Loadable(lazy(() => import('./profile/profile')));

const ReportPage = Loadable(
  lazy(() => import('../payments/reports/ReportList'))
);
const Payouts = Loadable(lazy(() => import('./banking/payout/Payout')));
const BankAccounts = Loadable(
  lazy(() => import('./banking/bank-accounts/BankAccounts'))
);
const SubmitEvidences = Loadable(
  lazy(() => import('../payments/all-transactions/DisputeEvidence'))
);
const SubscriptionsList = Loadable(
  lazy(() => import('./all-transactions/subscriptions/SubscriptionsList'))
);
const Invoices = Loadable(
  lazy(() => import('./all-transactions/invoices/InvoiceList'))
);
const StoreOrdersList = Loadable(
  lazy(() => import('./store-orders/StoreOrdersList'))
);
const StoreOrderDetail = Loadable(
  lazy(() => import('./store-orders/StoreOrderDetail'))
);
const InvoiceInfo = Loadable(
  lazy(() => import('./all-transactions/invoices/InvoiceInfo'))
);

export default function Router() {
  return useRoutes([
    {
      index: true,
      path: ROOTS_DASHBOARD,
      element: <Dashboard />,
    },
    {
      path: PAYMENTS.ONBOARDING.path,
      element: <Onboarding />,
    },
    {
      path: PAYMENTS.INVOICING.path,
      element: <Invoicing />,
    },
    {
      path: PAYMENTS.EDIT_INVOICING.path,
      element: <Invoicing />,
    },
    {
      path: PAYMENTS.QUICK_CHARGE.path,
      element: <QuickCharge />,
    },
    {
      path: PAYMENTS.CHECKOUT.path,
      element: <CheckoutForm />,
    },
    {
      path: PAYMENTS.EDIT_CHECKOUT.path,
      element: <CheckoutForm />,
    },
    {
      path: PAYMENTS.GENERATE_LINK.path,
      element: <GenerateCheckoutLink />,
    },
    {
      path: PAYMENTS.CHECKOUTS.path,
      element: <CheckoutList />,
    },
    {
      path: PAYMENTS.INVOICING.path,
      element: <Invoicing />,
    },
    {
      path: PAYMENTS.SUBSCRIPTIONS.path,
      element: <Subscriptions />,
    },
    {
      path: PAYMENTS.EDIT_SUBSCRIPTION.path,
      element: <Subscriptions />,
    },
    {
      path: PAYMENTS.PAYOUTS.path,
      element: <Payouts />,
    },
    {
      path: PAYMENTS.PAYOUT_DETAIL.path,
      element: <PayoutDetail />,
    },
    {
      path: PAYMENTS.BANK_ACCOUNTS.path,
      element: <BankAccounts />,
    },
    {
      path: PAYMENTS.INTEGRATION.path,
      element: (
        <SettingsBasedGuard serviceType="hideIntegration">
          <Integration />
        </SettingsBasedGuard>
      ),
    },
    {
      path: PAYMENTS.RAILINTEGRATION.path,
      element: <RailzConnect />,
    },
    {
      path: PAYMENTS.SUBSCRIPTIONS_LIST.path,
      element: <SubscriptionsList />,
    },
    {
      path: PAYMENTS.TAXES.path,
      element: <Taxes />,
    },
    {
      path: PAYMENTS.PROFILE.path,
      element: <Profile />,
    },
    {
      path: '/products/*',
      element: <ProductServicesRouter />,
    },
    {
      path: '/transactions/*',
      element: <TransactionRoute />,
    },
    {
      path: PAYMENTS.CUSTOMERS.path + '/*',
      element: <CustomersRoute />,
    },
    {
      path: PAYMENTS.LOCATIONS.path,
      element: (
        <SettingsBasedGuard serviceType="hideReaderOption">
          <Locations />
        </SettingsBasedGuard>
      ),
    },
    {
      path: PAYMENTS.LOCATION_INFO.path,
      element: <LocationInfo />,
    },
    {
      path: PAYMENTS.ORDERS.path,
      element: (
        <SettingsBasedGuard serviceType="hideReaderOption">
          <HardwareOrders />
        </SettingsBasedGuard>
      ),
    },
    {
      path: PAYMENTS.HARDWARE_SHOP.path,
      element: <HardwareShop />,
    },
    {
      path: PAYMENTS.HARDWARE_DETAILS.path,
      element: <HardwareDetails />,
    },
    {
      path: PAYMENTS.HARDWARE_CHECKOUT.path,
      element: <HardwareCheckout />,
    },
    {
      path: PAYMENTS.ORDER_INFO.path,
      element: <HardwareOrderInfo />,
    },
    {
      path: PAYMENTS.CHECKOUTS_ORDERS.path,
      element: <CheckoutOrdersList />,
    },
    {
      path: PAYMENTS.CHECKOUT_ORDER_DETAILS.path,
      element: <CheckoutOrderDetails />,
    },
    {
      path: PAYMENTS.TRANSACTIONS.path,
      element: <Transactions />,
    },
    {
      path: PAYMENTS.INVOICES.path,
      element: <Invoices />,
    },
    {
      path: PAYMENTS.INVOICE_INFO.path,
      element: <InvoiceInfo />,
    },

    {
      path: PAYMENTS.DISPUTES.path + '/*',
      element: <DisputesRoute />,
    },
    {
      path: PAYMENTS.REPORTS_PAYMENTS.path,
      element: <ReportPage />,
    },
    {
      path: PAYMENTS.SUBMIT_EVIDENCE.path,
      element: <SubmitEvidences />,
    },
    {
      path: PAYMENTS.STORE_ORDERS.path,
      element: <StoreOrdersList />,
    },
    {
      path: PAYMENTS.STORE_ORDER_DETAIL.path,
      element: <StoreOrderDetail />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
