import i18n from '../locales/i18n';

export const currencyList = [
  { title: 'USD', value: 'usd' },
  { title: 'EUR', value: 'eur' },
  { title: 'CAD', value: 'cad' },
];

export const requiredList = [
  { title: 'One Time', value: 'one_time' },
  { title: 'Recurring', value: 'recurring' },
];

export const statusList = [
  {
    title: 'Active',
    value: 'active',
  },
  {
    title: 'Inactive',
    value: 'inactive',
  },
];

export const cartTypeList = [
  {
    title: 'Cart',
    value: 'cart',
  },
  {
    title: 'Ecwid',
    value: 'ecwid',
  },
];

export const paymentPreference = [
  {
    title: 'Stripe',
    value: 'stripe',
  },
  {
    title: 'First Data',
    value: 'firstData',
  },
];

export const frequencyList = [
  { title: 'Daily', value: 'day' },
  { title: 'Weekly', value: 'week' },
  { title: 'Monthly', value: 'month', defaultSelected: true },
  { title: 'Yearly ', value: 'year' },
];

export const frequencyListTrans = [
  { title: 'daily', value: 'day' },
  { title: 'weekly', value: 'week' },
  { title: 'monthly', value: 'month', defaultSelected: true },
  { title: 'yearly', value: 'year' },
];

export const rowsPerPageOptions = [20, 40, 60, 80];

export const minRowsPerPageOptions = [10, 20, 30, 40];

export const subscribeStatuses = new Map([
  ['CANCELED', 'NOT SUBSCRIBED'],
  ['INACTIVE', 'NOT SUBSCRIBED'],
  ['ACTIVE', 'SUBSCRIBED'],
]);

export const maxQuantityAllowed = 51;

export const terms = [
  { title: 'Due upon receipt', value: 'due' },
  { title: 'Net 15', value: 'net15' },
  { title: 'Net 30', value: 'net30' },
  { title: 'Custom', value: 'custom', hide: true },
];

export const planStatus = ['Active', 'Inactive'];

export const products = [
  {
    title: 'Web + Cart',
    value: 'web+cart',
    img: 'PaidWeb',
  },
  {
    title: 'Payments',
    value: 'payments',
    img: 'PaidPayments',
  },
  {
    title: 'Shipping',
    value: 'shipping',
    img: 'PaidShipping',
  },
  {
    title: 'webCart',
    value: 'webCart',
    img: 'web',
  },
];

export const disabledDomainsArray = ['dev.paidcart.com', 'paidcart.com'];

export const invoiceDuration = [
  { title: 'dueUponReceipt', value: 'due' },
  { title: 'net15', value: 'net15' },
  { title: 'net30', value: 'net30' },
  { title: 'custom', value: 'custom', hide: true },
];

export const dashboardFilterList = [
  { title: 'today', value: 'today' },
  { title: 'last7Days', value: '7d' },
  { title: 'last4Weeks', value: '4w' },
  { title: 'last3Months', value: '3m' },
  { title: 'last12Months', value: '12m' },
  {
    title: 'monthToDate',
    value: 'month-to-date',
  },
  {
    title: 'quarterToDate',
    value: 'quarter-to-date',
  },
  {
    title: 'yearToDate',
    value: 'year-to-date',
  },
  { title: 'allTime', value: 'all-time' },
];

export const formatObj = {
  today: 'hh:mm a',
  '7d': 'ddd DD MMM',
  '4w': 'DD MMM',
  '3m': 'DD MMM YYYY',
  '12m': 'MMM YYYY',
  'month-to-date': 'DD MMM YYYY',
  'quarter-to-date': 'DD MMM YYYY',
  'year-to-date': 'DD MMM YYYY',
  'all-time': 'DD MMM YYYY',
};
export const customFormatObj = {
  today: 'DD MMM YYYY',
  '7d': 'ddd DD MMM',
  '4w': 'DD MMM YYYY',
  '3m': 'MMM YYYY',
  '12m': 'MMM YYYY',
  'month-to-date': 'DD MMM YYYY',
  'quarter-to-date': 'MMM YYYY',
  'year-to-date': 'MMM YYYY',
  'all-time': 'DD MMM YYYY',
};

export const AdminDashboardFilter = [
  { title: 'Today', value: 'today' },
  { title: 'This Week', value: 'this-week' },
  { title: 'This Month', value: 'this-month' },
  { title: 'This Quarter', value: 'this-quarter' },
  { title: 'This Year', value: 'this-year' },
  { title: 'Last Week', value: 'last-week' },
  { title: 'Last Month', value: 'last-month' },
  { title: 'Last Quarter', value: 'last-quarter' },
  { title: 'Last Year', value: 'last-year' },
];

export const newFeatures = ['banking', 'readers', 'integration'];

export const cartTypes = ['ecwid', 'cart'];

//use only for import customer functionality
export const headerFields = [
  'first_name',
  'last_name',
  'email',
  'business_name',
  'display_name',
  'phone',
  'notes',
  'address1',
  'address2',
  'city',
  'province',
  'country',
  'postalCode',
  'sameAsBillingAddress',
  'ship_name',
  'ship_phone',
  'ship_address1',
  'ship_address2',
  'ship_city',
  'ship_province',
  'ship_country',
  'ship_postalCode',
  'category',
];

//use only for import customer functionality
export const createCustomerObj = (customer) => {
  return {
    address: {
      line1: customer?.address1,
      line2: customer?.address2,
      city: customer?.city,
      postal_code: customer?.postalCode,
      state: customer?.province,
      country: customer?.country,
    },
    displayName: customer?.display_name,
    metadata: {
      first_name: customer?.first_name,
      last_name: customer?.last_name,
      business_name: customer?.business_name,
    },
    name: customer?.business_name,
    notes: customer?.notes,
    email: customer?.email,
    phone: customer?.phone,
    category: customer?.category,
    sameAsBillingAddress: ['', 'null', 'yes']?.includes(
      customer?.sameAsBillingAddress?.toLowerCase()
    ),
    ...(customer?.sameAsBillingAddress?.toLowerCase() === 'no' && {
      shipping: {
        name: customer?.ship_name,
        phone: customer?.ship_phone,
        address: {
          line1: customer?.ship_address1,
          line2: customer?.ship_address2,
          city: customer?.ship_city,
          postal_code: customer?.ship_postalCode,
          state: customer?.ship_province,
          country: customer?.ship_country,
        },
      },
    }),
  };
};

//use only for import customer functionality
export const createErrorObj = ({ err }) => {
  return {
    errors: err?.inner?.reduce((acc, e) => {
      let path =
        typeof e.path === 'string'
          ? e.path[0].toUpperCase() + e.path.slice(1)
          : e.path;
      path = path?.split(/(?=[A-Z])/).join(' ');
      if (typeof path === 'string' && path.includes('_')) {
        let splitArr = [];
        path = path.split('_').reduce((acc, word) => {
          splitArr.push(word[0].toUpperCase() + word.slice(1));
          acc = splitArr.join(' ');
          return acc;
        }, '');
      }
      if (!(path in acc)) {
        acc[path] = e.message;
      }
      return acc;
    }, {}),
  };
};

//use only for import customer functionality
export const transFunc = i18n.cloneInstance({
  ns: ['common', 'payments/customers', 'validation-msg'],
  defaultNS: 'common',
}).t;

//use only for import customer functionality
export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

//use only for import customer functionality
export const createFileRejectionError = ({ message, type, file }) => {
  return [
    {
      errors: [
        {
          code: type || '',
          message,
        },
      ],
      file,
    },
  ];
};

export const transactionsFilterTabs = [
  {
    value: '',
    label: 'all',
  },
  {
    value: 'charge',
    label: 'charges',
  },
  {
    value: 'refund',
    label: 'refunds',
  },
  {
    value: 'payout',
    label: 'payouts',
  },
  {
    value: 'adjustment',
    label: 'disputes',
  },
];
