import { Box, Skeleton, Stack, TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { rowsPerPageOptions } from '../../constants';

const CustomTablePagination = ({
  isLoading,
  customRowsPerPageOptions = rowsPerPageOptions,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  hideRowsPerPage,
  sx = {},
  customStyle,
  ...others
}) => {
  const [t] = useTranslation(['common']);
  return (
    <Box>
      {isLoading ? (
        <Stack p={3} direction={'row'} sx={{ ...sx }}>
          <Skeleton variant="rectangular" width={300} height={50} />
        </Stack>
      ) : (
        <TablePagination
          style={customStyle}
          labelRowsPerPage={t('rowsPerPage')}
          rowsPerPageOptions={hideRowsPerPage ? [] : customRowsPerPageOptions}
          component="div"
          count={Number(count) || 0}
          rowsPerPage={Number(rowsPerPage)}
          page={Number(page)}
          onPageChange={(event, newPage) => {
            onPageChange(event, newPage);
          }}
          onRowsPerPageChange={(event) => {
            onRowsPerPageChange(event);
          }}
          sx={{ ...sx }}
          {...others}
        />
      )}
    </Box>
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number,
  customRowsPerPageOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  hideRowsPerPage: PropTypes.bool,
  sx: PropTypes.any,
  customStyle: PropTypes.number,
};

export default CustomTablePagination;
