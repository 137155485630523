import Images from './images';

export const SOCIAL_MEDIA = [
  {
    link: 'https://www.facebook.com/paidinc',
    icon: Images.FacebookIcon,
  },
  {
    link: 'https://twitter.com/Paid_inc',
    icon: Images.TwitterIcon,
  },
  {
    link: 'https://www.linkedin.com/company/paid-inc',
    icon: Images.LinkedInIcon,
  },
];

export const PAID_WEB =
  'https://support.paid.com/en/support/solutions/articles/42000084092-how-to-embed-checkouts-in-paidweb-';

export const WORDPRESS =
  'https://support.paid.com/en/support/solutions/articles/42000084029-how-to-embed-checkouts-in-your-wordpress-site';

export const WIX =
  'https://support.paid.com/en/support/solutions/articles/42000084031-how-to-embed-checkouts-in-your-wix-site';

export const SOCIAL =
  'https://support.paid.com/en/support/solutions/articles/42000084033-how-to-use-checkouts-with-your-social-media-accounts';

export const PARTNER_LINK = `${document.location.protocol}//${document.location.host}/sign-up/?partner=`;

export const CHECKOUT_LINK = `${document.location.protocol}//${document.location.host}/checkout-page/`;
export const PAID = 'https://paid.com';

export const PERKS_LINK = 'https://app.paid.com/perks';
