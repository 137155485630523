import { useState } from 'react';

import { useTheme } from '@emotion/react';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';

import { useDisableStyles } from '../../pages/client/payments/checkout/PaymentRequestForm';
import NoTableData from '../table/NoTableData';
import TableHeadCustom from '../table/TableHeadCustom';
import TableSkeleton from './../table/TableSkeleton';
const TableWrapper = ({
  numSelected,
  onSelectAllRows,
  isLoading,
  columns,
  rowsData,
  rowKey,
  t,
  order,
  orderBy,
  onSort,
  onRowClick,
  disableRowsId,
  borderBottom,
  borderColor,
  noDataDescription,
  noDataTitle,
  isProductList,
}) => {
  const classes = useDisableStyles();
  const activeColumn = columns?.filter((item) => !item?.hide);
  const theme = useTheme();
  const [hoveredRow, setHoveredRow] = useState(null);

  return (
    <TableContainer sx={{ position: 'relative' }}>
      <Table>
        <TableHeadCustom
          t={t}
          onSelectAllRows={onSelectAllRows}
          headLabel={activeColumn}
          numSelected={numSelected}
          rowCount={rowsData?.length}
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        />
        <TableBody>
          {isLoading
            ? activeColumn?.map((field, index) => (
                <TableSkeleton
                  key={field + index}
                  tableCell={activeColumn?.length}
                />
              ))
            : rowsData?.map((row, index) => {
                return (
                  <TableRow
                    hover={
                      isProductList &&
                      (!row?.manageInventory ||
                        (row?.manageInventory && row?.quantity))
                    }
                    key={row[rowKey]}
                    onClick={(event) => onRowClick && onRowClick(row)}
                    onMouseEnter={() => setHoveredRow(row[rowKey])}
                    onMouseLeave={() => setHoveredRow(null)}
                    sx={{
                      cursor:
                        typeof onRowClick === 'function'
                          ? 'pointer'
                          : 'default',
                      borderBottom:
                        borderBottom && index < rowsData.length - 1
                          ? borderBottom
                          : 0,
                      borderColor: borderColor,
                      position: 'relative',
                    }}
                    className={
                      disableRowsId?.includes(row?._id)
                        ? classes.disabled
                        : null
                    }
                  >
                    {activeColumn?.map((column) => {
                      return (
                        <TableCell
                          key={column?.headerName}
                          align={column?.align}
                          sx={column?.sx}
                          onClick={(e) =>
                            column?.headerName === 'action' &&
                            e.stopPropagation()
                          }
                        >
                          {column.render(row)}
                        </TableCell>
                      );
                    })}
                    {isProductList &&
                    row?.manageInventory &&
                    !row?.quantity &&
                    hoveredRow === row[rowKey] ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: '15%',
                          bottom: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: theme.palette.shadow.shadowColor,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            color: theme.palette.error.main,
                          }}
                        >
                          {t('common:outOfStock')}
                        </Typography>
                      </Box>
                    ) : null}
                  </TableRow>
                );
              })}
          {!isLoading && !rowsData?.length ? (
            <NoTableData
              noDataDescription={noDataDescription}
              noDataTitle={noDataTitle}
            />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableWrapper.propTypes = {
  disableRowsId: PropTypes.array,
  columns: PropTypes.array,
  headLabel: PropTypes.any,
  isLoading: PropTypes.any,
  numSelected: PropTypes.any,
  onRowClick: PropTypes.func,
  onSelectAllRows: PropTypes.any,
  isProductList: PropTypes.bool,
  onSort: PropTypes.any,
  order: PropTypes.any,
  orderBy: PropTypes.any,
  rowKey: PropTypes.any,
  rowsData: PropTypes.array,
  t: PropTypes.any,
  borderBottom: PropTypes.any,
  borderColor: PropTypes.any,
  noDataDescription: PropTypes.any,
  noDataTitle: PropTypes.any,
};

export default TableWrapper;
