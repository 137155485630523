import { useEffect } from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import customDayjs from '../customDayjs';

const RHFDatePicker = ({
  name,
  label,
  onChange,
  minDate,
  maxDate,
  ...other
}) => {
  const { control, watch, trigger } = useFormContext();
  const selectedDate = watch(name);

  useEffect(() => {
    if (selectedDate) {
      trigger(name);
    }
  }, [trigger, selectedDate, name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <DatePicker
            format="DD/MM/YYYY"
            label={label}
            value={customDayjs(field?.value)}
            onChange={(newValue) => {
              if (onChange) {
                onChange(newValue?.format('YYYY-MM-DD'));
              }
              field.onChange(newValue?.format('YYYY-MM-DD'));
            }}
            minDate={minDate}
            maxDate={maxDate}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '48px',
              },
            }}
            slotProps={{
              textField: {
                helperText: error?.type || error?.message,
                error: Boolean(error),
              },
            }}
            {...other}
          />
        );
      }}
    />
  );
};

RHFDatePicker.propTypes = {
  label: PropTypes.any,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func,
};

export default RHFDatePicker;
