import { useEffect } from 'react';

import {
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const RHFAutoComplete = (props) => {
  const { control } = useFormContext();
  const filter = createFilterOptions();
  const {
    options,
    label,
    defaultSelected,
    id,
    name,
    onChange,
    value,
    sx,
    loading,
    addItemFunction,
    isOptionIcon,
    setOpenConfirmModal,
    setSelectedValue,
    hideAddOption,
    renderOption,
    addNewTitle,
    getLabel,
    getValue,
    notChangeInForm,
    addNewLabel,
    isAutoFill,
    currentValue,
    ...others
  } = props;

  const { watch, trigger } = useFormContext();

  useEffect(() => {
    if (watch(name) || currentValue) {
      trigger(name);
    }
  }, [currentValue, name, trigger, watch]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            name={name}
            value={value && value}
            disableClearable={value || currentValue ? false : true}
            onChange={(e, newValue) => {
              if (onChange && !newValue?.isAdd) {
                onChange(newValue);
              }
              if (!notChangeInForm) {
                field.onChange(newValue && newValue[getValue || 'title']);
              }

              if (addItemFunction && newValue?.isAdd) {
                addItemFunction(newValue?.inputValue);
              }
            }}
            onBlur={(e) => !e.target.value && trigger(name)}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option?.inputValue) {
                return option?.inputValue;
              }
              return (option && option[getLabel || 'title']) || '';
            }}
            id={id}
            sx={sx}
            options={options}
            loading={loading}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              if (!hideAddOption) {
                filtered.unshift({
                  inputValue: inputValue || '',
                  [addNewTitle || 'title']:
                    addNewLabel || `Add "${inputValue}"`,
                  isAdd: true,
                  _id: Math.random(16),
                });
              }
              return filtered;
            }}
            defaultValue={defaultSelected && defaultSelected}
            renderOption={
              renderOption
                ? renderOption
                : (props, option) => {
                    const newProps = { ...props, key: props.id };

                    return (
                      <Grid
                        key={newProps.key}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          style={{
                            flexGrow: 1,
                          }}
                          {...newProps}
                        >
                          {option[getLabel || 'title']}
                        </Box>
                      </Grid>
                    );
                  }
            }
            {...others}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  FormHelperTextProps={{
                    sx: {
                      marginX: 0,
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: others?.size === 'small' ? 'auto' : '48px',
                      py: '12px',
                    },
                    '& .MuiOutlinedInput-input': {
                      py: others?.size === 'small' ? 'auto' : '0px !important',
                    },
                    '& .MuiInputLabel-root': {
                      top: '-2px',
                    },
                    '& .MuiInputLabel-shrink': {
                      top: '0px',
                    },
                  }}
                  label={label}
                  type={'text'}
                  error={!!error}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: isAutoFill }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              );
            }}
          />
        )}
      />
    </>
  );
};

RHFAutoComplete.propTypes = {
  addItemFunction: PropTypes.func,
  addNewLabel: PropTypes.string,
  addNewTitle: PropTypes.string,
  currentValue: PropTypes.any,
  defaultSelected: PropTypes.any,
  getLabel: PropTypes.string,
  getValue: PropTypes.string,
  hideAddOption: PropTypes.any,
  id: PropTypes.any,
  isAutoFill: PropTypes.bool,
  isOptionIcon: PropTypes.any,
  label: PropTypes.any,
  loading: PropTypes.any,
  name: PropTypes.any,
  notChangeInForm: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.any,
  renderOption: PropTypes.any,
  setOpenConfirmModal: PropTypes.func,
  setSelectedValue: PropTypes.func,
  sx: PropTypes.any,
  value: PropTypes.any,
};
export default RHFAutoComplete;
