export const fnKeyPressNumber = (e, allowDot = true) => {
  if (
    e.key === ',' ||
    (!allowDot && e.key === '.') ||
    (e.target.value.length === 0 && e.key === '.') ||
    ((e.charCode !== 46 || e.target.value.includes('.')) &&
      e.charCode !== 44 &&
      e.charCode !== 13 &&
      !(e.charCode >= 48 && e.charCode <= 57))
  ) {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const fnFormatValueAfterDecimal = (event, item, setValue, val) => {
  if (event && setValue) {
    event.preventDefault();
    const value = val ? Number(val) : Number(event.target.value);
    setValue(`${item}`, value.toFixed(2));
  }
};
