export const getStatusBadge = (status) => {
  let bgColor = '';
  let statusText = status;
  switch (status) {
    case 'COMPLETED':
    case 'ACTIVE':
      statusText = status;
      bgColor = 'primary';
      break;
    case 'INCOMPLETE':
      statusText = status;
      bgColor = 'warning';
      break;
    case 'INPROGRESS':
    case 'NOT_VERIFIED':
      statusText = status === 'NOT_VERIFIED' ? 'NOT VERIFIED' : status;
      bgColor = 'secondary';
      break;

    case 'CANCELED':
    case 'INACTIVE':
      statusText = status;
      bgColor = 'error';
      break;
    case 'succeeded':
      statusText = status;
      bgColor = 'primary';
      break;
    case 'requires_confirmation':
      statusText = status;
      bgColor = 'secondary';
      break;
    case '-':
      statusText = '-';
      bgColor = 'transparant';
      break;
    default:
      statusText = 'NOT VERIFIED';
      bgColor = 'secondary';
      break;
  }
  return { statusText, bgColor };
};
