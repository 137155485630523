import { useCallback } from 'react';

import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import addCommonProps from '../utils/addCommonProps';
import { CustomTooltip } from './CustomTooltip';

const ElementsTooltip = ({
  upperElement,
  lowerElement,
  maxLengthOfUpperElement,
  maxLengthOfLowerElement,
  placement,
  variant,
  maxWidth,
  upperElementColor,
  lowerElementColor,
}) => {
  const customerDataStyling = useCallback(
    (bool, data) => {
      switch (data) {
        case 'upperElement':
        case 'lowerElement':
          return {
            maxWidth: maxWidth ? maxWidth : '16rem',
            ...addCommonProps({ bool }),
          };

        default:
          return;
      }
    },
    [maxWidth]
  );

  return (
    <>
      <CustomTooltip
        disableHoverListener={upperElement?.length <= maxLengthOfUpperElement}
        title={upperElement}
        arrow
        placement={placement ? placement : 'top'}
      >
        <Typography
          sx={{
            ...customerDataStyling(
              upperElement?.length > maxLengthOfUpperElement,
              'upperElement'
            ),
          }}
          variant={variant ? variant : 'body2'}
          color={
            upperElementColor
              ? upperElementColor
              : (theme) => theme.palette.common.black
          }
        >
          {upperElement || 'N/A'}
        </Typography>
      </CustomTooltip>

      {lowerElement ? (
        <CustomTooltip
          disableHoverListener={lowerElement?.length <= maxLengthOfLowerElement}
          title={lowerElement}
          arrow
          placement="bottom"
        >
          <Typography
            variant="body2"
            sx={{
              ...customerDataStyling(
                lowerElement?.length > maxLengthOfLowerElement,
                'lowerElement'
              ),
            }}
            color={
              lowerElementColor
                ? lowerElementColor
                : (theme) => theme.palette.grey[500]
            }
          >
            {lowerElement || 'N/A'}
          </Typography>
        </CustomTooltip>
      ) : null}
    </>
  );
};

ElementsTooltip.propTypes = {
  lowerElement: PropTypes.string,
  upperElement: PropTypes.string,
  maxLengthOfUpperElement: PropTypes.number,
  maxLengthOfLowerElement: PropTypes.number,
  placement: PropTypes.string,
  variant: PropTypes.string,
  maxWidth: PropTypes.string,
  upperElementColor: PropTypes.string,
  lowerElementColor: PropTypes.string,
};

export default ElementsTooltip;
