import { lazy, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../components/RouteLazyLoading';
import { useLazyGetPlanListQuery } from '../../../redux/plan/planAction';

const Sites = Loadable(lazy(() => import('./sites/Sites.jsx')));
const Templates = Loadable(lazy(() => import('./templates/Templates')));

export default function Router() {
  // Global state
  const setup = useSelector((state) => state?.setup);
  const plans = useSelector((state) => state.plan?.plans?.activePlans);

  // Constant
  const loginId = useMemo(
    () => setup?.profile?.loginId,
    [setup?.profile?.loginId]
  );

  //API call
  const [getPlanList] = useLazyGetPlanListQuery();

  //Fetching plans if plans not available
  useEffect(() => {
    if (!plans?.length) {
      getPlanList({ loginId });
    }
  }, [getPlanList, loginId, plans?.length]);

  return useRoutes([
    {
      path: '/sites',
      element: <Sites />,
    },
    {
      path: '/templates',
      element: <Templates />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
