const ApiRoutes = {
  SHIPTIME: {
    basePath: '/shiptime',
    endpoints: {
      signIn: {
        url: '/login',
        method: 'POST',
      },
    },
  },
  ACCOUNTS: {
    basePath: '/accounts',
    endpoints: {
      signIn: {
        url: '/authenticate',
        method: 'POST',
      },
      loginWithGoogle: {
        url: 'accounts/paid-google-authenticate',
        method: 'POST',
      },
      loginWithFaceBook: {
        url: 'accounts/paid-facebook-authenticate',
        method: 'POST',
      },
      signUp: {
        url: '/register',
        method: 'POST',
      },
      resendEmail: {
        url: '/resend-email',
        method: 'POST',
      },
      generateSuperUserToken: {
        url: '/generate-super-user-token',
        method: 'POST',
      },
      verifyCode: {
        url: '/verify/:code',
        method: 'POST',
      },
      forgotPassword: {
        url: '/forgot-password',
        method: 'POST',
      },
      passwordRecovery: {
        url: '/password-recovery',
        method: 'POST',
      },
      setPassword: {
        url: '/set-password',
        method: 'POST',
      },
      verifyToken: {
        url: '/verify-token',
        method: 'POST',
      },
      profileUpdate: {
        url: '/profile-update',
        method: 'PUT',
      },
      updateEmail: {
        url: '/update-email',
        method: 'POST',
      },
      updatePassword: {
        url: '/update-password',
        method: 'POST',
      },
      redirectUser: {
        url: '/redirect-user',
        method: 'POST',
      },
      loginAsUser: {
        url: '/login-as-user',
        method: 'POST',
      },
      partnerLogo: {
        url: '/partner/partner-logo',
        method: 'GET',
      },
      getMemberUsers: {
        url: '/get-users',
        method: 'GET',
      },
      getClients: {
        url: '/profiles/:loginId?/:profileId?',
        method: 'GET',
      },
      deleteClient: {
        url: '/profiles/delete-user/:profileId',
        method: 'DELETE',
      },
      getClientsByPartner: {
        url: '/partner-user-profiles/:loginId?/:partnerId?',
        method: 'GET',
      },
      getSingleClient: {
        url: '/get-single-profile/:profileId?',
        method: 'GET',
      },
      getSessionHistory: {
        url: '/sessions',
        method: 'GET',
      },
    },
  },
  API: {
    basePath: '/api',
    endpoints: {
      getPrimaryServiceKey: {
        url: '/services/getPrimaryServiceKey',
        method: 'GET',
      },
    },
  },

  PAID: {
    basePath: '/paid',
    endpoints: {
      setup: {
        url: '/user/setup',
        method: 'GET',
      },
      getRoles: { url: '/roles/role/:role', method: 'GET' },
      getPaidWebRoles: { url: '/roles/web-roles', method: 'GET' },
      getTeamUser: {
        url: '/team/get-team-user/:userId?',
        method: 'GET',
      },
      AddTeamMemberUser: {
        url: '/team/team-user',
        method: 'POST',
      },
      editTeamMember: {
        url: '/team/team-user',
        method: 'PUT',
      },
      deleteTeamMember: {
        url: '/team/team-user',
        method: 'DELETE',
      },
    },
  },
  STRIPE: {
    basePath: '/stripe/payments',
    endpoints: {
      stripeSubscription: {
        url: '/stripe-subscription',
        method: 'GET',
      },
      stripeBillingHistory: {
        url: '/stripe-invoices',
        method: 'GET',
      },
      updateStripeSubscription: {
        url: '/stripe-subscription',
        method: 'PUT',
      },
      stripeCustomer: {
        url: '/stripe-customer',
        method: 'POST',
      },
      addStripeSubscription: {
        url: '/stripe-subscription',
        method: 'POST',
      },
      updateAccountSetting: {
        url: '/update-account-settings',
        method: 'PUT',
      },
      addServiceFee: { url: '/stripe-service-fees', method: 'POST' },
      getAllCheckouts: {
        url: '/checkout',
        method: 'GET',
      },
      getSingleCheckout: {
        url: '/get-single-checkout-transaction',
        method: 'GET',
      },
      editCheckout: {
        url: '/checkout',
        method: 'PUT',
      },
      deleteCheckout: {
        url: '/checkout',
        method: 'DELETE',
      },
      addCheckout: {
        url: '/checkout',
        method: 'POST',
      },
      getAllCheckoutsTransaction: {
        url: '/get-all-checkout-transactions',
        method: 'GET',
      },
      getSingleCheckoutData: {
        url: '/get-single-checkout',
        method: 'GET',
      },
      payCheckout: {
        url: '/pay-checkout',
        method: 'POST',
      },
      viewReceipt: {
        url: '/view-stripe-receipt',
        method: 'GET',
      },
      sendReceipt: {
        url: '/send-stripe-receipt',
        method: 'POST',
      },
      addBank: {
        url: '/bank-account',
        method: 'POST',
      },
      deleteBank: {
        url: '/bank-account',
        method: 'DELETE',
      },
    },
  },

  PLANS: {
    basePath: '/billing/subscription',
    endpoints: {
      getPlansList: {
        url: '/plan',
        method: 'GET',
      },
      updatePlan: {
        url: '/plan',
        method: 'PUT',
      },
      addPlan: {
        url: '/plan',
        method: 'POST',
      },
      changePlanStatus: {
        url: '/plan-status',
        method: 'PUT',
      },
      getPlansFeaturesList: {
        url: '/get-all-plan-features',
        method: 'GET',
      },
      changePlan: {
        url: '/change-plan',
        method: 'PUT',
      },
      updatePlanStatus: {
        url: '/plan-status',
        method: 'PUT',
      },
      getSinglePlanData: {
        url: '/get-plan',
        method: 'GET',
      },
    },
  },

  PAYMENTS: {
    basePath: '/stripe/payments',
    endpoints: {
      getDashboardStats: {
        url: '/get-stripe-dashboard-stats',
        method: 'GET',
      },
      getGrossVolume: {
        url: '/get-gross-volume',
        method: 'GET',
      },
      getSuccessfulAndRecentPayments: {
        url: '/get-successful-and-recent-payments',
        method: 'GET',
      },
      getInvoiceAmount: {
        url: '/get-invoice-amount',
        method: 'GET',
      },
      getStripePaymentMethods: {
        url: '/get-stripe-payment-methods',
        method: 'GET',
      },
      getNetVolumeChartData: {
        url: '/get-net-volume',
        method: 'GET',
      },
      getNewCustomersChartData: {
        url: '/get-new-customers',
        method: 'GET',
      },
      createStripePayouts: {
        url: '/create-stripe-payout',
        method: 'POST',
      },
      stripeOnBoarding: {
        url: '/stripe-connect-onboarding',
        method: 'POST',
      },
      dismissNotification: {
        url: '/notification',
        method: 'DELETE',
      },
      stripeAccountVerification: {
        url: '/stripe-account-verify',
        method: 'POST',
      },
      stripePaymentMethod: {
        url: '/stripe-customer-payment-method',
        method: 'POST',
      },
      stripeUpdatePaymentMethod: {
        url: '/payment-method',
        method: 'PUT',
      },
      getTaxesFeesData: {
        url: '/stripe-tax-rates',
        method: 'GET',
      },
      getServiceFees: {
        url: '/stripe-service-fees',
        method: 'GET',
      },
      addTaxesFeesData: {
        url: '/stripe-tax-rates',
        method: 'POST',
      },
      updateTaxes: {
        url: '/stripe-tax-rates',
        method: 'PUT',
      },
      addServiceFee: {
        url: '/stripe-service-fees',
        method: 'POST',
      },
      deleteServiceFees: {
        url: '/stripe-service-fees',
        method: 'DELETE',
      },
      getGatewayProducts: {
        url: '/stripe-product',
        method: 'GET',
      },
      getGatewayProductById: {
        url: '/stripe-single-product',
        method: 'GET',
      },
      getAllStripeProducts: {
        url: '/get-stripe-product',
        method: 'GET',
      },
      addProduct: {
        url: '/stripe-product',
        method: 'POST',
      },
      editProduct: {
        url: '/stripe-product',
        method: 'PUT',
      },
      deleteProduct: {
        url: '/stripe-product',
        method: 'DELETE',
      },
      updateServiceFees: {
        url: '/stripe-service-fees',
        method: 'PUT',
      },
      customers: {
        url: '/stripe-customer-by-account',
        method: 'GET',
      },
      getAllCustomers: {
        url: '/get-all-customers',
        method: 'GET',
      },
      AddCustomers: {
        url: '/stripe-customer-by-account',
        method: 'POST',
      },
      getAllCategories: {
        url: '/categories',
        method: 'GET',
      },
      AddCategories: {
        url: '/add-category',
        method: 'POST',
      },
      DeleteCategory: {
        url: '/delete-category',
        method: 'DELETE',
      },
      importCustomers: {
        url: '/stripe-import-multiple-accounts',
        method: 'POST',
      },
      payNow: {
        url: '/pay-now',
        method: 'POST',
      },
      EditCustomers: {
        url: '/stripe-customer-by-account',
        method: 'PUT',
      },
      deleteCustomer: {
        url: '/stripe-customer',
        method: 'DELETE',
      },
      getAllLocations: {
        url: '/terminal/locations',
        method: 'GET',
      },
      addLocations: {
        url: '/terminal/locations',
        method: 'POST',
      },
      editLocations: {
        url: '/terminal/locations',
        method: 'PUT',
      },
      deleteLocation: {
        url: '/terminal/locations',
        method: 'DELETE',
      },
      addEditDevice: {
        url: '/terminal/reader',
        method: 'POST',
      },
      deleteDevice: {
        url: '/terminal/reader',
        method: 'DELETE',
      },
      getAllOrders: {
        url: '/terminal/hardware-orders',
        method: 'GET',
      },
      getAllDevices: {
        url: '/terminal/hardware-devices',
        method: 'GET',
      },
      getAllCartItems: {
        url: '/terminal/hardware-cart-items',
        method: 'GET',
      },
      addUpdateCardItems: {
        url: '/terminal/hardware-cart-items',
        method: 'PUT',
      },
      getDisputes: {
        url: '/disputes',
        method: 'GET',
      },
      getTotalDisputes: {
        url: '/get-total-dispute',
        method: 'GET',
      },
      getDisputeById: {
        url: '/disputes/disputeId',
        method: 'GET',
      },
      clearDisputes: {
        url: '/clear-total-dispute',
        method: 'PATCH',
      },
      acceptDispute: {
        url: '/accept-dispute/accountId/disputeId',
        method: 'POST',
      },
      createTransactionRefund: {
        url: '/create-transaction-refund',
        method: 'POST',
      },
      getReports: {
        url: '/reports',
        method: 'GET',
      },
      downloadReport: {
        url: '/download-report',
        method: 'POST',
      },
      deleteReport: {
        url: '/reports',
        method: 'DELETE',
      },
      getCustomersRecentTransactions: {
        url: '/get-stripe-payment-intents-by-customer',
        method: 'GET',
      },
      getCustomersPaymentMethod: {
        url: '/stripe-customer-payment-method',
        method: 'GET',
      },
      // method should be PUT
      changeDefaultPaymentMethod: {
        url: '/stripe-customer-payment-method',
        method: 'POST',
      },
      deletePaymentMethod: {
        url: '/stripe-customer-payment-method',
        method: 'DELETE',
      },
      updateCustomerPaymentMethod: {
        url: '/update-stripe-payment-method',
        method: 'PUT',
      },
      getStripePayout: {
        url: '/get-stripe-payouts',
        method: 'GET',
      },
      getStripeSinglePayout: {
        url: '/get-stripe-single-payout',
        method: 'GET',
      },
      getStripePayoutTransactions: {
        url: '/get-stripe-transactions',
        method: 'GET',
      },
      getLoginUrlForPOS: {
        url: '/terminal/get-login-URL-for-POS',
        method: 'POST',
      },
      addEvidenceFile: {
        url: '/file-upload',
        method: 'POST',
      },
      submitEvidence: {
        url: '/update-dispute',
        method: 'POST',
      },
      getSubscriptionData: {
        url: '/stripe-account-subscription',
        method: 'GET',
      },
      cancelSubscription: {
        url: '/stripe-account-subscription',
        method: 'DELETE',
      },
      deleteSubscription: {
        url: '/delete-scheduled-subscription',
        method: 'DELETE',
      },
      getShippingCharge: {
        url: '/terminal/get-shipping-rate',
        method: 'GET',
      },
      hardwareOrderCheckout: {
        url: '/terminal/order-hardware-device',
        method: 'POST',
      },
      updateProfileSettings: {
        url: '/update-account-settings',
        method: 'PUT',
      },
      getStripeTransactions: {
        url: '/get-stripe-transactions',
        method: 'GET',
      },
      getAllStripeTransactions: {
        url: '/get-all-stripe-transactions',
        method: 'GET',
      },
      getTransactionById: {
        url: '/get-transaction',
        method: 'GET',
      },
      viewTransactionReceipt: {
        url: '/view-stripe-receipt',
        method: 'GET',
      },
      sendStripeReceipt: {
        url: '/send-stripe-receipt',
        method: 'POST',
      },
      getInvoiceData: {
        url: '/get-stripe-invoices',
        method: 'GET',
      },
      getSingleInvoice: {
        url: '/get-single-stripe-invoice',
        method: 'GET',
      },
      voidInvoiceStatus: {
        url: '/set-void-stripe-invoice-status',
        method: 'POST',
      },
      downloadPdf: {
        url: '/download-invoice-pdf',
        method: 'POST',
      },
      markInvoiceAsPaid: {
        url: '/mark-paid-stripe-invoice-status',
        method: 'POST',
      },
      createStripeInvoiceSubscription: {
        url: '/create-stripe-invoice-subscription',
        method: 'POST',
      },
      createInvoice: {
        url: '/send-invoice',
        method: 'POST',
      },
      updateInvoice: {
        url: '/update-invoice',
        method: 'PUT',
      },
      saveInvoice: {
        url: '/save-invoice',
        method: 'POST',
      },
      payInvoiceAmount: {
        url: '/pay-invoice',
        method: 'POST',
      },
      payInvoice: {
        url: '/pay-invoice',
        method: 'GET',
      },
      scheduleStripeInvoiceSubscription: {
        url: '/schedule-stripe-invoice-subscription',
        method: 'POST',
      },
      updateStripeScheduledSubscription: {
        url: '/update-schedule-subscription',
        method: 'PUT',
      },
      getSingleStripeAccountSubscription: {
        url: '/get-scheduled-subscriptions',
        method: 'GET',
      },
      cancelStripeSubscription: {
        url: '/cancel-stripe-subscription',
        method: 'POST',
      },
      getAccountHistory: {
        url: '/cancel-stripe-subscription',
        method: 'GET',
      },
      sendSavedInvoices: {
        url: '/send-saved-invoice',
        method: 'POST',
      },
      getAllBankAccounts: {
        url: '/get-all-bank-accounts',
        method: 'GET',
      },
      getAllPOSTransactions: {
        url: '/terminal/get-stripe-POS-transactions',
        method: 'GET',
      },
      syncTransactionsToRailz: {
        url: '/sync-transactions-with-railz',
        method: 'POST',
      },
    },
  },
  INTEGRATION: {
    basePath: '/accounting',
    endpoints: {
      getSingleBusiness: {
        url: '/get-business',
        method: 'GET',
      },
      disconnectRailzService: {
        url: '/connections-disconnect',
        method: 'PUT',
      },
    },
  },
  STORE_ORDERS: {
    basePath: 'ecom',
    endpoints: {
      getStoreOrdersData: {
        url: '/payments/orders',
        method: 'GET',
      },
      getStoreOrder: {
        url: '/payments/order',
        method: 'GET',
      },
      sendRefund: {
        url: '/payments/order-refund',
        method: 'POST',
      },
      payECWIDCheckout: {
        url: '/payments',
        method: 'POST',
      },
      getECWIDCheckout: {
        url: '/payments/checkout',
        method: 'GET',
      },
    },
  },

  WEB: {
    basePath: '/web',
    endpoints: {
      templates: {
        url: '/multi-screen/templates',
        method: 'GET',
      },
      addSites: {
        url: '/multi-screen/create',
        method: 'POST',
      },
      getCategories: {
        url: '/multi-screen/templates/categories',
        method: 'GET',
      },
      sites: {
        url: '/sites',
        method: 'GET',
      },
      editSites: {
        url: '/site-sso',
        method: 'POST',
      },
      deleteSites: {
        url: '/sites',
        method: 'DELETE',
      },
      publishSites: {
        url: '/publish-site',
        method: 'POST',
      },
      unpublishSites: {
        url: '/unpublish-site',
        method: 'POST',
      },
      getPublishSites: {
        url: '/published-sites',
        method: 'GET',
      },
      getSyncDudaProducts: {
        url: '/sync-duda-products',
        method: 'POST',
      },
    },
  },
  SHIPPING: {
    basePath: '/shipping',
    endpoints: {
      shiptimeRedirection: {
        url: '/login',
        method: 'POST',
      },
      redirectingToShipping: {
        url: '/token-redirect',
        method: 'GET',
      },
    },
  },

  COMPANY: {
    basePath: '/company',
    endpoints: {
      companyDetails: {
        url: '/company-details/:companyId?/:loginId?',
        method: 'PUT',
      },
      companyDetailsByPartnerId: {
        url: '/company-details/:loginId?/:partnerId?',
        method: 'PUT',
      },
      getCompanyProfileById: {
        url: '/single-company-detail/:companyId',
        method: 'PUT',
      },
    },
  },

  ADMIN: {
    basePath: 'paid',
    endpoints: {
      dashboardStats: {
        url: '/dashboard/get-admin-dashboard-stats',
        method: 'GET',
      },
      signUpChartDetails: {
        url: '/dashboard/signup-details',
        method: 'GET',
      },
      mostUsedPlan: {
        url: '/dashboard/get-most-used-plan',
        method: 'GET',
      },
      signUpPartnerDetails: {
        url: '/dashboard/get-signup-partner-details',
        method: 'GET',
      },
      topCancellationReason: {
        url: '/dashboard/top-cancellation-reason',
        method: 'GET',
      },
      partners: {
        url: '/accounts/partner',
        method: 'GET',
      },
      getAgents: {
        url: '/accounts/partner/get-partner-agents',
        method: 'GET',
      },
      addPartner: {
        url: '/accounts/partner',
        method: 'POST',
      },
      updatePartner: {
        url: '/accounts/partner',
        method: 'PUT',
      },
      getPaymentOrders: {
        url: '/payments/orders',
        method: 'GET',
      },
      revenueReport: {
        url: '/payments/revenue-report',
        method: 'GET',
      },
      companies: {
        url: '/company/company-details',
        method: 'GET',
      },
      companiesByPartnerId: {
        url: '/company/company-details/:loginId?/:partnerId?',
        method: 'GET',
      },
      updateCompanyDetail: {
        url: '/company/company-details',
        method: 'PUT',
      },
      company: {
        url: '/company/single-company-detail',
        method: 'GET',
      },
      getPaymentsProfile: {
        url: '/intercept-request/payment-intercept-request',
        method: 'GET',
      },
      toggleGateway: {
        url: '/payments/toggle-gateway-activation',
        method: 'PUT',
      },
      getWebSites: {
        url: '/web/sites',
        method: 'GET',
      },
      supportLink: {
        url: '/support/links',
        method: 'GET',
      },
      updateSupportLink: {
        url: '/support/links',
        method: 'PUT',
      },
      addSupportLink: {
        url: '/support/links',
        method: 'POST',
      },
      deleteSupportLink: {
        url: '/support/links',
        method: 'DELETE',
      },
      getCompanyUsers: {
        url: '/accounts/get-users',
        method: 'GET',
      },
      editCompanyUser: {
        url: '/team/team-user',
        method: 'PUT',
      },
      companyPaymentDetail: {
        url: '/company/company-payment-detail',
        method: 'GET',
      },
      getUtm: {
        url: '/company/utm',
        method: 'GET',
      },
      signUpReferrals: {
        url: '/dashboard/get-signup-referral-details',
        method: 'GET',
      },
    },
  },

  ADMIN_PAID: {
    basePath: 'admin/paid',
    endpoints: {
      saveSettings: {
        url: '/save-settings',
        method: 'PATCH',
      },
      addDomain: {
        url: '/domains',
        method: 'POST',
      },
    },
  },
  SUPPORT: {
    basePath: 'paid/support',
    sendFeedBack: {
      url: '/send-feedback',
      method: 'POST',
    },
  },
  IP_DETAILS: {
    basePath: 'https://get.geojs.io',
    ipDetails: {
      url: '/v1/ip.json',
      method: 'GET',
    },
  },
  PERK: {
    basePath: '/',
    endpoints: {
      addPerk: {
        url: 'perks/add-perk',
        method: 'POST',
      },
      editPerk: {
        url: 'perks/update-perk/:perkId',
        method: 'PUT',
      },
      getAllPerks: {
        url: 'perks/get-all-perks',
        method: 'GET',
      },
      getSinglePerk: {
        url: 'perks/get-single-perk/:perkId',
        method: 'GET',
      },
      deletePerk: {
        url: 'perks/delete-perk/:perkId',
        method: 'DELETE',
      },
      downloadPerk: {
        url: 'perks/download-perks',
        method: 'GET',
      },
    },
  },
  CART: {
    basePath: '/cart',
    endpoints: {
      getAllDomains: {
        url: '/domains/cart',
        method: 'GET',
      },
      updateDomain: {
        url: '/domains/cart',
        method: 'PUT',
      },
      deleteDomain: {
        url: '/domains/cart',
        method: 'DELETE',
      },
      changeDomainStatus: {
        url: '/domains/cart-status',
        method: 'PUT',
      },
    },
  },
};
export default ApiRoutes;
