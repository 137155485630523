import { Container } from '@mui/material';

import PartnersList from './PartnersList';

const Partners = () => {
  return (
    <Container maxWidth={'xxl'}>
      <PartnersList />
    </Container>
  );
};

export default Partners;
