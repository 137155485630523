import {
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

const RHFRadioGroup = ({
  name,
  options,
  getOptionLabel,
  valueKey,
  directionStyle = {},
  disabled,
  labelStyle = {},
  radioGroupStyle = {},
  ...other
}) => {
  const { control } = useFormContext();

  const { direction, alignItems } = radioGroupStyle;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} {...other}>
            <Stack {...directionStyle}>
              {options.map((option, index) => (
                <Stack
                  key={valueKey ? option[valueKey] : option}
                  direction={direction}
                  alignItems={alignItems}
                  columnGap={1}
                >
                  {option?.icon}
                  <FormControlLabel
                    key={valueKey ? option[valueKey] : option}
                    value={valueKey ? option[valueKey] : option}
                    disabled={option?.disabled || disabled}
                    control={<Radio />}
                    sx={option?.sx ? option.sx : labelStyle}
                    label={
                      typeof getOptionLabel === 'function'
                        ? getOptionLabel(option)
                        : option
                    }
                  />
                  {option?.badgeComponent && option?.badgeComponent}
                  {option?.subTitle ? (
                    <Stack direction="row" alignItems="center" pl={4}>
                      <Typography
                        variant="caption"
                        color={option?.subTitleColor || 'primary'}
                      >
                        {option?.icon}
                      </Typography>
                      <Typography
                        variant="caption"
                        color={option?.subTitleColor || 'primary'}
                      >
                        {option?.subTitle}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
              ))}
            </Stack>
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
};
RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.any,
  getOptionLabel: PropTypes.any,
  directionStyle: PropTypes.object,
  radioGroupStyle: PropTypes.any,
  labelStyle: PropTypes.any,
  disabled: PropTypes.bool,
};

export default RHFRadioGroup;
