import { api, getAPIHeaders } from '../apiInterceptor';
import apiRoutes from '../apiRoutesConstants';

api.enhanceEndpoints({ addTagTypes: ['getSingleClient', 'getClients'] });

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation({
      query: (credentials) => ({
        url:
          apiRoutes.ACCOUNTS.basePath + apiRoutes.ACCOUNTS.endpoints.signIn.url,
        method: apiRoutes.ACCOUNTS.endpoints.signIn.method,
        body: credentials,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    shiptimeAuthenticate: build.mutation({
      query: (credentials) => ({
        url:
          apiRoutes.SHIPPING.basePath +
          apiRoutes.SHIPPING.endpoints.shiptimeRedirection.url,
        method: apiRoutes.SHIPPING.endpoints.shiptimeRedirection.method,
        body: credentials,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    signInWithToken: build.mutation({
      query: ({ token, isLoginAsUser }) => {
        let url = '';
        let method = '';
        if (isLoginAsUser) {
          url = apiRoutes.ACCOUNTS.endpoints.loginAsUser.url;
          method = apiRoutes.ACCOUNTS.endpoints.loginAsUser.method;
        } else {
          url = apiRoutes.ACCOUNTS.endpoints.redirectUser.url;
          method = apiRoutes.ACCOUNTS.endpoints.redirectUser.method;
        }
        return {
          url: `${apiRoutes.ACCOUNTS.basePath + url}/${token}`,
          method: method,
        };
      },
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders({ superUserToken: authHeader?.superUserToken });
        return response;
      },
    }),
    getPrimaryServiceKey: build.mutation({
      query: () => ({
        url:
          apiRoutes.API.basePath +
          apiRoutes.API.endpoints.getPrimaryServiceKey.url,
        method: apiRoutes.API.endpoints.getPrimaryServiceKey.method,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.APIServiceRequest;
        getAPIHeaders(authHeader);
        return authHeader;
      },
    }),
    partnerLogoByUrl: build.query({
      query: (data) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.partnerLogo.url,
        method: apiRoutes.ACCOUNTS.endpoints.partnerLogo.method,
        params: data,
      }),
    }),
    verify: build.mutation({
      query: (data) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.verifyCode.url.replace(
            /:code/gi,
            data.code
          ),
        method: apiRoutes.ACCOUNTS.endpoints.verifyCode.method,
        body: data,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    signUp: build.mutation({
      query: (data) => ({
        url:
          apiRoutes.ACCOUNTS.basePath + apiRoutes.ACCOUNTS.endpoints.signUp.url,
        method: apiRoutes.ACCOUNTS.endpoints.signUp.method,
        body: data,
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    resendVerificationEmail: build.mutation({
      query: (data) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.resendEmail.url,
        method: apiRoutes.ACCOUNTS.endpoints.resendEmail.method,
        body: data,
      }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    forgotPassword: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.forgotPassword.url,
        method: apiRoutes.ACCOUNTS.endpoints.forgotPassword.method,
        body: params,
      }),
    }),
    resetPassword: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.passwordRecovery.url,
        method: apiRoutes.ACCOUNTS.endpoints.passwordRecovery.method,
        body: params,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    setPassword: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.setPassword.url,
        method: apiRoutes.ACCOUNTS.endpoints.setPassword.method,
        body: params,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    updateProfile: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.profileUpdate.url,
        method: apiRoutes.ACCOUNTS.endpoints.profileUpdate.method,
        body: params,
      }),
      invalidatesTags: (res) => (res ? ['getSingleClient', 'getClients'] : []),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    updateEmail: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.updateEmail.url,
        method: apiRoutes.ACCOUNTS.endpoints.updateEmail.method,
        body: params,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    updatePassword: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.ACCOUNTS.basePath +
          apiRoutes.ACCOUNTS.endpoints.updatePassword.url,
        method: apiRoutes.ACCOUNTS.endpoints.updatePassword.method,
        body: params,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    updateAccountSettings: build.mutation({
      query: (params) => ({
        url:
          apiRoutes.STRIPE.basePath +
          apiRoutes.STRIPE.endpoints.updateAccountSetting.url,
        method: apiRoutes.STRIPE.endpoints.updateAccountSetting.method,
        body: params,
      }),
      transformResponse: (response, meta, arg) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    loginWithGoogle: build.mutation({
      query: (body) => {
        return {
          url: apiRoutes.ACCOUNTS.endpoints.loginWithGoogle.url,
          method: apiRoutes.ACCOUNTS.endpoints.loginWithGoogle.method,
          body: body,
        };
      },
      transformResponse: (response) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    loginWithFacebook: build.mutation({
      query: (body) => {
        return {
          url: apiRoutes.ACCOUNTS.endpoints.loginWithFaceBook.url,
          method: apiRoutes.ACCOUNTS.endpoints.loginWithFaceBook.method,
          body: body,
        };
      },
      transformResponse: (response) => {
        const authHeader = response?.AuthenticationResponse;
        getAPIHeaders(authHeader);
        return response;
      },
    }),
    getIpInfo: build.query({
      query: () => ({
        url: apiRoutes.IP_DETAILS.basePath + apiRoutes.IP_DETAILS.ipDetails.url,
        method: apiRoutes.IP_DETAILS.ipDetails.method,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const {
  useSignInMutation,
  useShiptimeAuthenticateMutation,
  useSignInWithTokenMutation,
  useGetPrimaryServiceKeyMutation,
  usePartnerLogoByUrlQuery,
  useForgotPasswordMutation,
  useSignUpMutation,
  useResendVerificationEmailMutation,
  useVerifyMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useUpdateProfileMutation,
  useUpdateEmailMutation,
  useUpdatePasswordMutation,
  useUpdateAccountSettingsMutation,
  useLoginWithFacebookMutation,
  useLoginWithGoogleMutation,
  useGetIpInfoQuery,
} = authApi;

export const {
  endpoints: { login, setup },
} = authApi;
