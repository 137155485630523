import { configureStore } from '@reduxjs/toolkit';

import { api } from './apiInterceptor';
import { rootReducer } from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});

const RootState = store.getState();
export { store, RootState };
