import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';

export const useStyles = makeStyles({
  bgColor: {
    backgroundColor: '#F8F9FC',
    height: '100%',
    minHeight: 1,
    overflow: 'auto',
  },
});

const PublicPageWrapper = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bgColor}>
      <Outlet />
    </Box>
  );
};

export default PublicPageWrapper;
