import { FormControlLabel, FormHelperText, Radio } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const RHFRadio = ({ name, value, disabled = false, ...other }) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Radio
                disabled={disabled}
                name={name}
                onChange={(event) => field.onChange(event.target.value)}
                value={value}
                checked={field?.value === value}
              />
              {!!error && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )}
            </div>
          )}
        />
      }
      disabled={disabled}
      {...other}
    />
  );
};

RHFRadio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RHFRadio;
