import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Card, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import RHFButton from '../../../components/button/RHFButton';
import { RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import { RHFUploadSingleFile } from '../../../components/hook-form/RHFUpload';
import Image from '../../../components/Image';
import RejectionFiles from '../../../components/upload/RejectionFiles';
import { cartTypes } from '../../../constants';
import Images from '../../../constants/images';
import { _SYS_EMPLOYEE, _SYS_MANAGER } from '../../../constants/roles';
import PartnerDetailSkeleton from './PartnerDetailSkeleton';

const PartnerDetails = ({
  notAllowed = false,
  products,
  loader,
  setIsImgError,
}) => {
  const currentRole = useSelector((state) => state.setup?.profile?.role?.role);
  // Local state
  const [imageErrorMsg, setImageErrorMsg] = useState([]);

  // Form setup
  const methods = useFormContext({});

  const { setValue, watch } = methods;

  const values = watch();
  const productsAvailable = useMemo(() => {
    let products = values?.productsAvailable;
    return products?.map((product) => {
      if (product?.value === 'web') product.value = 'web+cart';
      return product;
    });
  }, [values?.productsAvailable]);

  // Function
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'partnerLogo',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (imageErrorMsg) {
      setIsImgError(imageErrorMsg?.length);
    }
  });

  return (
    <>
      {loader ? (
        <PartnerDetailSkeleton />
      ) : (
        <>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {imageErrorMsg.length > 0 && (
                <RejectionFiles
                  fileRejections={imageErrorMsg}
                  onRemove={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setImageErrorMsg([]);
                  }}
                />
              )}

              <RHFUploadSingleFile
                disabled={notAllowed}
                name="partnerLogo"
                maxSize={3145728}
                onDrop={handleDrop}
                errorMsg={(val) => setImageErrorMsg(val)}
              />
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card sx={{ p: 3 }}>
              <Stack gap={2}>
                <RHFTextField
                  disabled={notAllowed}
                  name="partnerName"
                  label="Partner Name*"
                  inputProps={{ maxLength: 50 }}
                />
                <Stack gap={1}>
                  <Typography variant="subtitle1">
                    Products Available
                  </Typography>
                  <Grid container gap={2}>
                    {products
                      ?.filter((d) => {
                        return cartTypes?.includes(values?.cartType)
                          ? !['web', 'cart'].includes(d?.value)
                          : !['webCart'].includes(d?.title);
                      })
                      .map((product, i) => {
                        const isInList = productsAvailable?.some(
                          (obj) => obj?.value === product?.value
                        );

                        return (
                          <Grid item key={i}>
                            {product.title === 'webCart' ? (
                              <RHFButton
                                sx={{
                                  boxShadow:
                                    '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                                  backgroundColor: (theme) =>
                                    isInList
                                      ? theme.palette.primary.lighter
                                      : '',
                                  color: (theme) =>
                                    isInList ? theme.palette.primary.main : '',
                                  '&:hover': {
                                    backgroundColor: (theme) =>
                                      isInList
                                        ? theme.palette.primary.lighter
                                        : '',
                                    color: (theme) =>
                                      isInList
                                        ? theme.palette.primary.main
                                        : '',
                                  },
                                }}
                                disabled={notAllowed}
                                variant={isInList ? 'contained' : ''}
                                onClick={() => {
                                  if (!isInList) {
                                    setValue('productsAvailable', [
                                      ...values.productsAvailable,
                                      product,
                                    ]);
                                  } else {
                                    const filteredList =
                                      productsAvailable?.filter(
                                        (obj) => obj.value !== product.value
                                      );
                                    setValue('productsAvailable', filteredList);
                                  }
                                }}
                                title={
                                  <>
                                    <Image
                                      src={Images['PaidWeb'].filename}
                                      sx={{
                                        width: 22,
                                        height: 22,
                                      }}
                                    />
                                    <Typography ml={1.5}>Web</Typography>
                                    <AddIcon
                                      sx={{
                                        mx: 1,
                                      }}
                                    />
                                    <Image
                                      src={Images['PaidCart'].filename}
                                      sx={{
                                        width: 22,
                                        height: 22,
                                      }}
                                    />
                                    <Typography ml={1.5}>Cart</Typography>
                                  </>
                                }
                              />
                            ) : (
                              <RHFButton
                                title={product?.title}
                                startIcon={
                                  <Image
                                    src={Images[`${product.img}`].filename}
                                    sx={{
                                      width: 22,
                                      height: 22,
                                    }}
                                  />
                                }
                                disabled={notAllowed}
                                variant={isInList ? 'contained' : ''}
                                sx={{
                                  boxShadow:
                                    '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                                  backgroundColor: (theme) =>
                                    isInList
                                      ? theme.palette.primary.lighter
                                      : '',
                                  color: (theme) =>
                                    isInList ? theme.palette.primary.main : '',
                                  '&:hover': {
                                    backgroundColor: (theme) =>
                                      isInList
                                        ? theme.palette.primary.lighter
                                        : '',
                                    color: (theme) =>
                                      isInList
                                        ? theme.palette.primary.main
                                        : '',
                                  },
                                }}
                                onClick={() => {
                                  if (!isInList) {
                                    setValue('productsAvailable', [
                                      ...values.productsAvailable,
                                      product,
                                    ]);
                                  } else {
                                    const filteredList =
                                      productsAvailable?.filter(
                                        (obj) => obj.value !== product.value
                                      );
                                    setValue('productsAvailable', filteredList);
                                  }
                                }}
                              />
                            )}
                          </Grid>
                        );
                      })}
                  </Grid>
                </Stack>
                <RHFCheckbox
                  name={'hidePartner'}
                  label={
                    <Typography variant="body1">Hidden Partner</Typography>
                  }
                  disabled={
                    currentRole === _SYS_EMPLOYEE ||
                    currentRole === _SYS_MANAGER
                  }
                />
              </Stack>
            </Card>
          </Grid>
        </>
      )}
    </>
  );
};

PartnerDetails.propTypes = {
  setIsImgError: PropTypes.bool,
  notAllowed: PropTypes.any,
  products: PropTypes.any,
  loader: PropTypes.bool,
};

export default memo(PartnerDetails);
