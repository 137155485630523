import numeral from 'numeral';

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number, toFixed = 2) {
  return numeral(number).format(
    Number.isInteger(number) || !toFixed ? '0,0' : '0,0.00'
  );
}

export function fShortenNumber(number, customFormat = false) {
  return numeral(number).format(customFormat ? '0,0.00' : '0.00');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
