import { lazy } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { Loadable } from '../../../components/RouteLazyLoading';

const TeamPage = Loadable(lazy(() => import('../teams/TeamPage')));

export default function TeamRouter() {
  return useRoutes([
    {
      index: true,
      element: <TeamPage />,
    },
    {
      path: '*',
      element: <Navigate to={'/404'} replace />,
      index: true,
    },
  ]);
}
