import { IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { PropTypes } from 'prop-types';

import Iconify from '../Iconify';

const StyledTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    top: '-2px',
  },
  '& .MuiInputLabel-shrink': {
    top: '0px',
  },
});

function SearchField({
  value,
  size,
  placeholder,
  removeIcon = false,
  onRemove = {},
  handleSearch,
  maxLength,
  ...others
}) {
  return (
    <StyledTextField
      value={value}
      size={size}
      placeholder={placeholder}
      autoComplete="off"
      InputProps={{
        sx: {
          height: '48px',
        },
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon={'material-symbols:search-rounded'}
              color={(theme) => theme.palette.grey[500]}
              width={24}
              height={24}
            />
          </InputAdornment>
        ),
        endAdornment: removeIcon && (
          <InputAdornment position="end">
            <IconButton sx={{ cursor: 'pointer' }} onClick={onRemove}>
              <Iconify icon={'basil:cross-outline'} width={28} height={28} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        onChange: (e) => handleSearch && handleSearch(e),
        maxLength: maxLength,
      }}
      fullWidth
      {...others}
    />
  );
}

export default SearchField;

SearchField.propTypes = {
  customStyle: PropTypes.object,
  handleSearch: PropTypes.func,
  onRemove: PropTypes.func,
  placeholder: PropTypes.string,
  removeIcon: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
};
